@font-face {
  font-family: "Poppins-Regular";
  src: url("/assets/Poppins-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Mulish-ExtraBold";
  src: url("/assets/Mulish-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Mulish-Medium";
  src: url("/assets/Mulish-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Mulish-Bold";
  src: url("/assets/Mulish-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Mulish-Regular";
  src: url("/assets/Mulish-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("/assets/Poppins-Light.ttf");
  font-display: swap;
}

body,
html,
#root {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Noto Sans",
    Helvetica,
    Roboto,
    Oxygen,
    Arial,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

section,
div,
nav,
header,
footer,
article,
aside {
  display: flex;
  position: static;
  box-sizing: border-box;
  contain: paint;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100px;
  min-height: 100px;
  width: 300px;
  min-width: 300px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
ol,
ul {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Roboto",
    sans-serif;
  font-size: 18px;
  background: #000000;
  height: fit-content;
  width: fit-content;
  background-clip: text;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

img {
  box-sizing: border-box;
  object-fit: contain;
  height: 90px;
  min-height: 90px;
  width: 115px;
  min-width: 115px;
}

span {
  box-sizing: border-box;
  font-family: "Material Icons";
  background: #000000;
  background-clip: text;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  height: fit-content;
  width: fit-content;
  font-size: 30px;
}

:root {
  --FontExtraBold: Mulish-ExtraBold;
  --FontBold: Mulish-Bold;
  --FontMedium: Mulish-Medium;
  --FontRegular: Mulish-Regular;
  --FontLight: Poppins-Light;
  --HeaderLogo: /assets/bavieraLogo.png;
  --HeaderText1: Nosotros;
  --HeaderText2: Equipo;
  --HeaderText3: Áreas de Práctica;
  --HeaderText4: Actualidad;
  --HeadertText5: Contacto;
  --HeaderText6: Agenda tu Capacitación;
  --HeaderIconMobile: ;
  --FooterText1: Política de tratamiento de datos;
  --FooterText2: Copyrights 2023 © Baviera Legal;
  --SMediaLinkedIngAzul: /assets/linkedin-Blue.png;
  --SMediaLinkedIngBlack: /assets/linkedin-Black.png;
  --SMediaMail: /assets/email.png;
  --SMediaPhone: /assets/llamada-telefonica.png;
  --SMediaInstagram: /assets/instagram.png;
  --min-ImageSize1: 175px;
  --ImageSize1: 175px;
  --min-ImageSize2: 150px;
  --ImageSize2: 150px;
  --min-ImageSize3: 125px;
  --ImageSize3: 125px;
  --min-ImageSize4: 100px;
  --ImageSize4: 100px;
  --min-ItemMargin1H: 12vw;
  --ItemMargin1H: 12vw;
  --min-ItemMargin2H: 6vw;
  --ItemMargin2H: 6vw;
  --min-ItemMargin3H: 3vw;
  --ItemMargin3H: 3vw;
  --min-SMediaSize1: 25px;
  --SMediaSize1: 25px;
  --min-SMediaSize2: 20px;
  --SMediaSize2: 20px;
  --min-SMediaSize3: 15px;
  --SMediaSize3: 15px;
  --min-ItemMargin4H: 1vw;
  --ItemMargin4H: 1vw;
}

@media (max-width: 480px) {
  :root {
    --min-HeaderPaddingH: 6vw;
    --HeaderPaddingH: 6vw;
    --min-HeaderPaddingV: 0vh;
    --HeaderPaddingV: 0vh;
    --min-HeaderLogoSize: 200px;
    --HeaderLogoSize: 200px;
    --min-HeaderTextSize: 16px;
    --HeaderTextSize: 16px;
    --min-HeaderTextMarginH: 4vw;
    --HeaderTextMarginH: 4vw;
    --min-ButtonPaddingV: 6px;
    --ButtonPaddingV: 6px;
    --min-ButtonPaddingH: 16px;
    --ButtonPaddingH: 16px;
    --min-ButtonRadius: 18px;
    --ButtonRadius: 18px;
    --min-FooterSMediaSize: 28px;
    --FooterSMediaSize: 28px;
    --min-FooterTextSize: 14px;
    --FooterTextSize: 14px;
    --min-TittleTextSize1: 35px;
    --TittleTextSize1: 35px;
    --min-TittleTextSize2: 30px;
    --TittleTextSize2: 30px;
    --min-TittleTextSize3: 20px;
    --TittleTextSize3: 20px;
    --min-TittleTextSize4: 18px;
    --TittleTextSize4: 18px;
    --min-ParagraphTextSize1: 16px;
    --ParagraphTextSize1: 16px;
    --min-ParagraphTextSize2: 14px;
    --ParagraphTextSize2: 14px;
    --min-ParagraphTextSize3: 12px;
    --ParagraphTextSize3: 12px;
    --min-ParagraphTextSize4: 10px;
    --ParagraphTextSize4: 10px;
    --min-ItemMargin1V: 5vh;
    --ItemMargin1V: 5vh;
    --min-ItemMargin2V: 3vh;
    --ItemMargin2V: 3vh;
    --min-ItemMargin3V: 1vh;
    --ItemMargin3V: 1vh;
    --MarcaPrimaryColor: #beac84;
    --MarcaSecondaryColor: #26374d;
    --MarcaTertiaryColor: #eeeff0;
    --MarcaAcentoColor: #528cb0;
    --PrimaryColor: #ffffff;
    --SecondaryColor: #000000;
    --TertiaryColor: #6a6a6a;
    --min-FooterSizeV: 21vh;
    --FooterSizeV: 21vh;
    --min-FooterItemMarginH: 10vw;
    --FooterItemMarginH: 10vw;
    --min-FooterPaddingMobileV: 6vh;
    --FooterPaddingMobileV: 6vh;
    --min-HeaderSizeV: 10vh;
    --HeaderSizeV: 10vh;
  }
}
@media (min-width: 1020px) {
  :root {
    --MarcaPrimaryColor: #beac84;
    --MarcaSecondaryColor: #26374d;
    --MarcaTertiaryColor: #eeeff0;
    --MarcaAcentoColor: #528cb0;
    --PrimaryColor: #ffffff;
    --SecondaryColor: #000000;
    --TertiaryColor: #e0e0e0;
    --min-HeaderSizeV: 10vh;
    --HeaderSizeV: 10vh;
    --min-HeaderPaddingH: 6vw;
    --HeaderPaddingH: 6vw;
    --min-HeaderPaddingV: 0vh;
    --HeaderPaddingV: 0vh;
    --min-HeaderLogoSize: 200px;
    --HeaderLogoSize: 200px;
    --min-HeaderTextSize: 16px;
    --HeaderTextSize: 16px;
    --min-HeaderTextMarginH: 4vw;
    --HeaderTextMarginH: 4vw;
    --min-ButtonPaddingV: 6px;
    --ButtonPaddingV: 6px;
    --min-ButtonPaddingH: 16px;
    --ButtonPaddingH: 16px;
    --min-ButtonRadius: 18px;
    --ButtonRadius: 18px;
    --min-FooterSMediaSize: 20px;
    --FooterSMediaSize: 20px;
    --min-FooterTextSize: 14px;
    --FooterTextSize: 14px;
    --min-TittleTextSize1: 45px;
    --TittleTextSize1: 45px;
    --min-TittleTextSize2: 30px;
    --TittleTextSize2: 30px;
    --min-TittleTextSize3: 25px;
    --TittleTextSize3: 25px;
    --min-TittleTextSize4: 20px;
    --TittleTextSize4: 20px;
    --min-ParagraphTextSize1: 18px;
    --ParagraphTextSize1: 18px;
    --min-ParagraphTextSize2: 16px;
    --ParagraphTextSize2: 16px;
    --min-ParagraphTextSize3: 14px;
    --ParagraphTextSize3: 14px;
    --min-ParagraphTextSize4: 12px;
    --ParagraphTextSize4: 12px;
    --min-ItemMargin1V: 6vh;
    --ItemMargin1V: 6vh;
    --min-ItemMargin2V: 3vh;
    --ItemMargin2V: 3vh;
    --min-ItemMargin3V: 1vh;
    --ItemMargin3V: 1vh;
    --min-FooterSizeV: 12vh;
    --FooterSizeV: 12vh;
    --min-FooterItemMarginH: 2vw;
    --FooterItemMarginH: 2vw;
    --min-FooterPaddingMobileV: 0vh;
    --FooterPaddingMobileV: 0vh;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  :root {
    --MarcaSecondaryColor: #26374d;
    --MarcaAcentoColor: #528cb0;
    --PrimaryColor: #ffffff;
    --TertiaryColor: #6a6a6a;
    --min-FooterItemMarginH: 4vw;
    --FooterItemMarginH: 4vw;
    --min-FooterSMediaSize: 24px;
    --FooterSMediaSize: 24px;
    --MarcaPrimaryColor: #beac84;
    --MarcaTertiaryColor: #eeeff0;
    --SecondaryColor: #000000;
    --min-HeaderSizeV: 10vh;
    --HeaderSizeV: 10vh;
    --min-HeaderPaddingH: 6vw;
    --HeaderPaddingH: 6vw;
    --min-HeaderPaddingV: 0vh;
    --HeaderPaddingV: 0vh;
    --min-HeaderLogoSize: 180px;
    --HeaderLogoSize: 180px;
    --min-HeaderTextSize: 16px;
    --HeaderTextSize: 16px;
    --min-HeaderTextMarginH: 4vw;
    --HeaderTextMarginH: 4vw;
    --min-ButtonPaddingV: 6px;
    --ButtonPaddingV: 6px;
    --min-ButtonPaddingH: 16px;
    --ButtonPaddingH: 16px;
    --min-ButtonRadius: 18px;
    --ButtonRadius: 18px;
    --min-TittleTextSize1: 45px;
    --TittleTextSize1: 45px;
    --min-FooterTextSize: 14px;
    --FooterTextSize: 14px;
    --min-TittleTextSize2: 30px;
    --TittleTextSize2: 30px;
    --min-TittleTextSize3: 25px;
    --TittleTextSize3: 25px;
    --min-TittleTextSize4: 20px;
    --TittleTextSize4: 20px;
    --min-ParagraphTextSize1: 18px;
    --ParagraphTextSize1: 18px;
    --min-ParagraphTextSize2: 16px;
    --ParagraphTextSize2: 16px;
    --min-ParagraphTextSize3: 14px;
    --ParagraphTextSize3: 14px;
    --min-ParagraphTextSize4: 12px;
    --ParagraphTextSize4: 12px;
    --min-ItemMargin2V: 3vh;
    --ItemMargin2V: 3vh;
    --min-ItemMargin1V: 6vh;
    --ItemMargin1V: 6vh;
    --min-ItemMargin3V: 1vh;
    --ItemMargin3V: 1vh;
    --min-FooterSizeV: 12vh;
    --FooterSizeV: 12vh;
    --min-FooterPaddingMobileV: 0vh;
    --FooterPaddingMobileV: 0vh;
  }
}
