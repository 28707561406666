.Agendatucapactitacion {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.agendatucapactitacionheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.layer-919-daed3b06d62d49dfa297a2c4eeecd147 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-326-4c682f5e980244d69d2e15a81de3f36c {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-508-827a1197fbae4d74a2f9fef610753de1 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--827a1197fbae4d74a2f9fef610753de1_visible);
}
.text-484-509e3014cedf45459e08922c7d291f4d {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-485-a6e8a5984ec1426d9ef7b55760b55035 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-486-32d1505412fd4f018b4463cd478f4339 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-487-170edb9fbb6b4feba54955eb4f63c372 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-509-89cb91bb7a7f4bfdac29dba848d39ddb {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaPrimaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--89cb91bb7a7f4bfdac29dba848d39ddb_visible);
}
.text-483-cd400f7174aa44c1ad298c8af1b83104 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-510-5db3b1c0286b4f87a9359c85ded3821f {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--5db3b1c0286b4f87a9359c85ded3821f_visible);
}
.icon-31-154e77a6693a4e9badd37b4f445f0583 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.agendatucapactitacionsection1 {
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.layer-669-a55c66e32f8541a5809b2b5b80db4c02 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-1528-dfbc466134cf418e99563fb059625d8b {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-478-a78eb289a8594c9b962b70dd7fcf18c6 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-157-61e282bbf5e846e295ee6eda8cc1ea92 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-158-56a6b6a9714945548a0259091d164c92 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-159-f84848aea0a44e339f75ef07d38e0e83 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-160-015d49406a944c5f9b83d609555935aa {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-479-4d995c65a67743a8b3127a77cd223fc6 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-431-6cd5f92e0a474fe7913347225e3f4505 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-432-95b0af126e9442279ea5bdbec1b07fa9 {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .Agendatucapactitacion {
    --5db3b1c0286b4f87a9359c85ded3821f_visible: flex;
    --89cb91bb7a7f4bfdac29dba848d39ddb_visible: none;
    --827a1197fbae4d74a2f9fef610753de1_visible: none;
  }
}
@media (min-width: 1020px) {
  .Agendatucapactitacion {
    --5db3b1c0286b4f87a9359c85ded3821f_visible: none;
    --89cb91bb7a7f4bfdac29dba848d39ddb_visible: flex;
    --827a1197fbae4d74a2f9fef610753de1_visible: flex;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Agendatucapactitacion {
    --5db3b1c0286b4f87a9359c85ded3821f_visible: flex;
    --89cb91bb7a7f4bfdac29dba848d39ddb_visible: none;
    --827a1197fbae4d74a2f9fef610753de1_visible: none;
  }
}
