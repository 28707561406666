.Mariapaulasuarez {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.mariapaulasuarezheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.layer-358-ec09b29029be4a7fb9b9da4a4ea044a9 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-424-3c45bb3efd7248239ddff07c7d29ee83 {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-359-2046ccbe99434a76ad5e4e18588922ee {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--2046ccbe99434a76ad5e4e18588922ee_visible);
}
.text-430-784dda8b34a44974b629e04c8938cf5b {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-431-8a3e8a147f774a1eb31a21752c55da6b {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-432-0064ca0802ba475fbba15f0f8de73331 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-433-f2e7ea5c37d44eb88cbe5857e1654dec {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-360-243fd8cce0ca48bcab300875b44cbba1 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--243fd8cce0ca48bcab300875b44cbba1_visible);
}
.text-434-648c11cf40674e9fba17540560008c50 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-361-39585b87e5b741e588985d47a03b5cb4 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--39585b87e5b741e588985d47a03b5cb4_visible);
}
.icon-23-741d58c4c4ef4202831b864da15a99a6 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.mariapaulasuarezsection1 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.layer-356-34e22c53a54b453fb130bb8ba7b58783 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-424-c7a242995bfc48eca15d027fb56890b2 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.layer-718-a6716922ac72435e979f6769f8b11bce {
  border-radius: 15px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-293-3d81e259e2ee44cea9f61e321ac5674c {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.text-425-468b383990854b9ea13aec5c4f4c3e51 {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.text-426-93764309ecc74eba882ba3f3a676afde {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-195-8678ea65eaac4f7096ced3112e152c58 {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.mariapaulasuarezsection2 {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-mariapaulasuarezsection2_valueheight);
  height: var(--mariapaulasuarezsection2_valueheight);
}
.layer-357-d0c48b07d8744e4098f76507ebafe684 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.text-427-4afb2db17c07458d82590022ac7dbb08 {
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.text-428-d09f80b28bdb4ff0b14e53f8b5d8e4d7 {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.text-429-01a549cb3a3c4f66965eb2d762dcde61 {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-950-f634eb808053420b83b6f924c08c4bc7 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-1278-ab3954c8a5f94b7cab5fc9ec277d80f1 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-1279-26fd37d2452444a7a00496ba074650e8 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-431-290925f44e2d4533acae26e688bf0f15 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-432-3aa247821d0042a4ad033f5bd9659896 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-433-14048161c4a9439987226b99aa20467a {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-434-6087d8bc63174fefad8d7c708b70da55 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-521-a4d94179e5874329afb3ddbb650091d4 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-505-775ef2dfd7a04e14b1d592afe56f0669 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-506-34f4e1b174154d629a94e6a0f71a6efc {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .Mariapaulasuarez {
    --39585b87e5b741e588985d47a03b5cb4_visible: flex;
    --243fd8cce0ca48bcab300875b44cbba1_visible: none;
    --2046ccbe99434a76ad5e4e18588922ee_visible: none;
    --min-mariapaulasuarezsection2_valueheight: unset;
    --mariapaulasuarezsection2_valueheight: fit-content;
  }
}
@media (min-width: 1020px) {
  .Mariapaulasuarez {
    --39585b87e5b741e588985d47a03b5cb4_visible: none;
    --243fd8cce0ca48bcab300875b44cbba1_visible: flex;
    --2046ccbe99434a76ad5e4e18588922ee_visible: flex;
    --min-mariapaulasuarezsection2_valueheight: unset;
    --mariapaulasuarezsection2_valueheight: 100%;
  }

  .mariapaulasuarezsection2 {
    height: var(--mariapaulasuarezsection2_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Mariapaulasuarez {
    --39585b87e5b741e588985d47a03b5cb4_visible: flex;
    --243fd8cce0ca48bcab300875b44cbba1_visible: none;
    --2046ccbe99434a76ad5e4e18588922ee_visible: none;
    --min-mariapaulasuarezsection2_valueheight: unset;
    --mariapaulasuarezsection2_valueheight: fit-content;
  }
}
