.Derechotributario {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.derechotributarioheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.layer-358-22d99567c0224953b1accc6574454d6c {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-377-efc9957a431c4a1697318d807526807a {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-359-1c478a790ff54e60bcf6e21950414afd {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--1c478a790ff54e60bcf6e21950414afd_visible);
}
.text-342-e50be2f158544d82a9f482ac70fd5904 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-343-3f7aca6f9d2049cd953f9debc6192eb2 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-344-059bc4cdcbca4dbfbed88c082626ed94 {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-345-6ca8962a625949c2b4f36ce992b4f753 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-360-be07bf4245d945288fd56b0c894e3945 {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--be07bf4245d945288fd56b0c894e3945_visible);
}
.text-346-880e6cf2c2b64f6098a8810ddacd8522 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-361-74a2af96b7054e4dbbfc2d37a86e451b {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--74a2af96b7054e4dbbfc2d37a86e451b_visible);
}
.icon-15-349301617e1c437680dbbc32d37296e6 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.derechotributariosection1 {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Derecho Tributario_square.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.text-278-dda0d0f19dae4512af636cfdb2ae251b {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.derechotributariosection2 {
  --verticalAxis-derechotributariosection2: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  --horizontalAxis-derechotributariosection2: var(
    --derechotributariosection2_horizontalAlignment
  );
  flex-direction: var(--derechotributariosection2_orientation);
  min-height: var(--min-derechotributariosection2_valueheight);
  height: var(--derechotributariosection2_valueheight);
}
.layer-1639-de743383905f49ac8e50df20596755b1 {
  --verticalAxis-de743383905f49ac8e50df20596755b1: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--de743383905f49ac8e50df20596755b1_orientation);
  --horizontalAxis-de743383905f49ac8e50df20596755b1: var(
    --de743383905f49ac8e50df20596755b1_horizontalAlignment
  );
}
.layer-234-ef76f0f62ce84b9cb60be5c4a312363d {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-243-01d425d5f90c47b59310c0504d950ed4 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-260-3a05dc3165f340759c3a8ee278f30e7a {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-244-f9bd0cbdbc73429ca3253e39d15527f8 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-261-36e5e99a2dd6452a8955131a1d985416 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-235-e7101809502042468d882ea4153ebd98 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-245-179e1e2a827d47d28a86dcd507fc8523 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-262-7104603bc2054cbcae9b0124c828a0dd {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-246-4b933a4595c04b59977cdf76b6d05c35 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-263-589701cdbce44f449358272580f21280 {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.layer-236-93193a715b1c424f87b8ff2df462e8cb {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-247-92cf8d757fe64d288bd6d871f1972ab2 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-264-325184179e5a445c9fc523f82ea945ef {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-248-69ea89c892e749f6abc616181d909bd6 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-265-c01ea86459474dfaba18548ba7ab601e {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-1640-88807b8185bf405a9c62722c705436ed {
  --verticalAxis-88807b8185bf405a9c62722c705436ed: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--88807b8185bf405a9c62722c705436ed_orientation);
  --horizontalAxis-88807b8185bf405a9c62722c705436ed: var(
    --88807b8185bf405a9c62722c705436ed_horizontalAlignment
  );
}
.layer-237-beb94b67b81b45539e6ab651e864165e {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-249-264f6e68eb6a497284e1a038d36146af {
  justify-content: center;
  align-items: flex-end;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-266-4b0b274665434cf8a52537432e9404a4 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-250-978cb54dfe7d473ca3a433f300ae0102 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-267-52c548199c86481894339f6b1bc436eb {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-238-fb6524e8e9d649ae99b54d4e5ba1e6b3 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-251-1094227617e44b0ea9d48c326bf730d2 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-268-d87ae52002e644568003210381a47305 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-252-77e69c5672194a1bb01e9384bed9c8f1 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-269-eb02358f015d44ff8a05322edf9cceef {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-680-ead4ce7dabc54aab862a335578d1b8ad {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-253-8111e1d188cd4b519a69e1414c043e50 {
  justify-content: center;
  align-items: flex-end;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-270-b3a320eb1e024d948d1c6453295e4d20 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-254-f53076f8452a4ab3992ba1a09437a675 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-271-f658ddf823f248349fb0fe9babc22817 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-2009-d0a023972f5648ca8e98805e414e1718 {
  --verticalAxis-d0a023972f5648ca8e98805e414e1718: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  --horizontalAxis-d0a023972f5648ca8e98805e414e1718: var(
    --d0a023972f5648ca8e98805e414e1718_horizontalAlignment
  );
  flex-direction: var(--d0a023972f5648ca8e98805e414e1718_orientation);
}
.layer-240-c9b58987915d4b56b9c3a46cf353cb70 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-255-814bbcd440f848808ff65f0ac893cc5d {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-272-81346a2c52b446b59b763d3fcd55af8a {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-256-fc458dd4b19e4455b93a03e3b7cd9d81 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-273-301a1c821a8f40fd866c51dc776110a0 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-241-48600d28cd9144b8b7038d18917c1197 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-257-cc910f729bd142cca478dc9b60c5de49 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-274-3de573b8d2df478e81d9d414f66038b2 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-258-945d078f8f854f8da77219d6f08de642 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-275-a6929c09a12745978e95ad2049a2a9c2 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-242-4527f9a378354bb98abaa7185e5de0d2 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-259-b85f830ad979477090ebd1194d9bc7e2 {
  justify-content: center;
  align-items: flex-end;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-276-1c13091b1e324128952c9646023f2b36 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-260-ca7f5b8fcca74a08aedafcad02a779ba {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-277-01613a2e4e374d11a5df29e389de313d {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-898-4154cb0fc6c34ac7ade29ded504fa962 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-1253-0dcd3bb17c82490690c6097aa3dc9756 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-976-4b6c62e47c3b41dcae5961110aa0e54a {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-296-42ae8ecd628141d397a78c433bdfa46f {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-297-71f617992ee44bf5aee67db2506b27a8 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-298-6be85d5e66a842849cb587508d3969b7 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-299-e06083202ea34f0d95228ba56e9d13a9 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-977-5abac821107e4b348add921d78a48887 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-884-68297c5b27ea4653ad8d1ff3568cf6e0 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-885-8638b72fc71742e09b434907a183377f {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.derechotributariosection2-orientation {
  align-items: var(--horizontalAxis-derechotributariosection2);
  justify-content: var(--verticalAxis-derechotributariosection2);
}
.layer-1639-de743383905f49ac8e50df20596755b1-orientation {
  align-items: var(--verticalAxis-de743383905f49ac8e50df20596755b1);
  justify-content: var(--horizontalAxis-de743383905f49ac8e50df20596755b1);
}
.layer-1640-88807b8185bf405a9c62722c705436ed-orientation {
  align-items: var(--verticalAxis-88807b8185bf405a9c62722c705436ed);
  justify-content: var(--horizontalAxis-88807b8185bf405a9c62722c705436ed);
}
.layer-2009-d0a023972f5648ca8e98805e414e1718-orientation {
  align-items: var(--verticalAxis-d0a023972f5648ca8e98805e414e1718);
  justify-content: var(--horizontalAxis-d0a023972f5648ca8e98805e414e1718);
}
@media (max-width: 480px) {
  .Derechotributario {
    --74a2af96b7054e4dbbfc2d37a86e451b_visible: flex;
    --be07bf4245d945288fd56b0c894e3945_visible: none;
    --1c478a790ff54e60bcf6e21950414afd_visible: none;
    --derechotributariosection2_horizontalAlignment: center;
    --derechotributariosection2_orientation: column;
    --de743383905f49ac8e50df20596755b1_orientation: column;
    --de743383905f49ac8e50df20596755b1_horizontalAlignment: center;
    --88807b8185bf405a9c62722c705436ed_orientation: column;
    --88807b8185bf405a9c62722c705436ed_horizontalAlignment: center;
    --d0a023972f5648ca8e98805e414e1718_horizontalAlignment: center;
    --d0a023972f5648ca8e98805e414e1718_orientation: column;
    --min-derechotributariosection2_valueheight: unset;
    --derechotributariosection2_valueheight: fit-content;
  }

  .derechotributariosection2-orientation {
    align-items: var(--horizontalAxis-derechotributariosection2);
    justify-content: var(--verticalAxis-derechotributariosection2);
  }
  .layer-1639-de743383905f49ac8e50df20596755b1-orientation {
    align-items: var(--horizontalAxis-de743383905f49ac8e50df20596755b1);
    justify-content: var(--verticalAxis-de743383905f49ac8e50df20596755b1);
  }
  .layer-1640-88807b8185bf405a9c62722c705436ed-orientation {
    align-items: var(--horizontalAxis-88807b8185bf405a9c62722c705436ed);
    justify-content: var(--verticalAxis-88807b8185bf405a9c62722c705436ed);
  }
  .layer-2009-d0a023972f5648ca8e98805e414e1718-orientation {
    align-items: var(--horizontalAxis-d0a023972f5648ca8e98805e414e1718);
    justify-content: var(--verticalAxis-d0a023972f5648ca8e98805e414e1718);
  }
}
@media (min-width: 1020px) {
  .Derechotributario {
    --74a2af96b7054e4dbbfc2d37a86e451b_visible: none;
    --be07bf4245d945288fd56b0c894e3945_visible: flex;
    --1c478a790ff54e60bcf6e21950414afd_visible: flex;
    --derechotributariosection2_horizontalAlignment: center;
    --derechotributariosection2_orientation: column;
    --de743383905f49ac8e50df20596755b1_orientation: row;
    --de743383905f49ac8e50df20596755b1_horizontalAlignment: space-between;
    --88807b8185bf405a9c62722c705436ed_orientation: row;
    --88807b8185bf405a9c62722c705436ed_horizontalAlignment: space-between;
    --d0a023972f5648ca8e98805e414e1718_horizontalAlignment: space-between;
    --d0a023972f5648ca8e98805e414e1718_orientation: row;
    --min-derechotributariosection2_valueheight: 60vh;
    --derechotributariosection2_valueheight: 60vh;
  }

  .derechotributariosection2-orientation {
    align-items: var(--horizontalAxis-derechotributariosection2);
    justify-content: var(--verticalAxis-derechotributariosection2);
  }
  .layer-1639-de743383905f49ac8e50df20596755b1-orientation {
    align-items: var(--verticalAxis-de743383905f49ac8e50df20596755b1);
    justify-content: var(--horizontalAxis-de743383905f49ac8e50df20596755b1);
  }
  .layer-1640-88807b8185bf405a9c62722c705436ed-orientation {
    align-items: var(--verticalAxis-88807b8185bf405a9c62722c705436ed);
    justify-content: var(--horizontalAxis-88807b8185bf405a9c62722c705436ed);
  }
  .layer-2009-d0a023972f5648ca8e98805e414e1718-orientation {
    align-items: var(--verticalAxis-d0a023972f5648ca8e98805e414e1718);
    justify-content: var(--horizontalAxis-d0a023972f5648ca8e98805e414e1718);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Derechotributario {
    --74a2af96b7054e4dbbfc2d37a86e451b_visible: flex;
    --be07bf4245d945288fd56b0c894e3945_visible: none;
    --1c478a790ff54e60bcf6e21950414afd_visible: none;
    --derechotributariosection2_horizontalAlignment: flex-start;
    --derechotributariosection2_orientation: column;
    --de743383905f49ac8e50df20596755b1_orientation: column;
    --de743383905f49ac8e50df20596755b1_horizontalAlignment: center;
    --88807b8185bf405a9c62722c705436ed_orientation: column;
    --88807b8185bf405a9c62722c705436ed_horizontalAlignment: center;
    --d0a023972f5648ca8e98805e414e1718_horizontalAlignment: center;
    --d0a023972f5648ca8e98805e414e1718_orientation: column;
    --min-derechotributariosection2_valueheight: unset;
    --derechotributariosection2_valueheight: fit-content;
  }

  .derechotributariosection2-orientation {
    align-items: var(--horizontalAxis-derechotributariosection2);
    justify-content: var(--verticalAxis-derechotributariosection2);
  }
  .layer-1639-de743383905f49ac8e50df20596755b1-orientation {
    align-items: var(--horizontalAxis-de743383905f49ac8e50df20596755b1);
    justify-content: var(--verticalAxis-de743383905f49ac8e50df20596755b1);
  }
  .layer-1640-88807b8185bf405a9c62722c705436ed-orientation {
    align-items: var(--horizontalAxis-88807b8185bf405a9c62722c705436ed);
    justify-content: var(--verticalAxis-88807b8185bf405a9c62722c705436ed);
  }
  .layer-2009-d0a023972f5648ca8e98805e414e1718-orientation {
    align-items: var(--horizontalAxis-d0a023972f5648ca8e98805e414e1718);
    justify-content: var(--verticalAxis-d0a023972f5648ca8e98805e414e1718);
  }
}
