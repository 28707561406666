.Derecholaboral {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.derecholaboralheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.layer-541-15a2b5261a0d451d8b59b605d8612f5c {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-359-78ec7fec2467409fb42973ad6037345a {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-542-f9e00d72224c4ee481a17c53a4b68dcf {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--f9e00d72224c4ee481a17c53a4b68dcf_visible);
}
.text-486-4277b7e658554f4a9889cdc300e2c53b {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-487-c04a1a49b34643aa80dd0add731dbe43 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-447-79787ecc48eb41798eb1aae3ecc4401c {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-489-384623175c3249fba602cecb64af2147 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-543-ce1c0f49f35f43799a6d4abfdef02665 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--ce1c0f49f35f43799a6d4abfdef02665_visible);
}
.text-490-40ac392a026942d693029ef5b2ebaaf8 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-544-3487d7cd5edd45fd8204a1a499fc4af8 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--3487d7cd5edd45fd8204a1a499fc4af8_visible);
}
.icon-19-c6304fc0e2ff41ab9a4983bf062ee9b5 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.derecholaboralsection1 {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Derecho laboral_square.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-right: var(--ItemMargin1H);
  padding-left: var(--ItemMargin1H);
}
.text-368-0d86958f27e3481dab8df63e1eddbcf2 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboralsection2 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.layer-728-3174e0a1485f4d09809f1ff14003b383 {
  --verticalAxis-3174e0a1485f4d09809f1ff14003b383: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--3174e0a1485f4d09809f1ff14003b383_orientation);
  --horizontalAxis-3174e0a1485f4d09809f1ff14003b383: var(
    --3174e0a1485f4d09809f1ff14003b383_horizontalAlignment
  );
}
.layer-324-394d6fdfa2ae4db893f478d97089fe7f {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-345-2b125b5a3f0942abb9ea5ea50836c365 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-391-478a1ca2d7394698a2cfe45ad123163a {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-346-26f119dea52247f1834260cccfcef3e7 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-392-df6ef76710bf43c0ba048723b0bc0efa {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-325-e1e8d248cb4b42fca1c9bdd6e51e509b {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-347-fffeb8891f5649ceb226f1aab9403ed7 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-393-35b7149a4bf648b4838001534c68e093 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-348-5fd15322d55641bf9c6d3c03306057b6 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-394-bc4b9c70468d4f48a6a54adebe4bd296 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-326-9f066b9be7ac4e49b27778c3dff08254 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-349-d87e5fb9fe2548b48672e04ea36a8377 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-395-3012551ba64447e8aa930e8f7384e0fa {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-350-6d4436d65d6740788de86909395f7dbd {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-396-d6ece14d854c4adda52b279b9c3c727a {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.layer-729-9ca064655cef41fbbf27dffb442d132a {
  --verticalAxis-9ca064655cef41fbbf27dffb442d132a: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--9ca064655cef41fbbf27dffb442d132a_orientation);
  --horizontalAxis-9ca064655cef41fbbf27dffb442d132a: var(
    --9ca064655cef41fbbf27dffb442d132a_horizontalAlignment
  );
}
.layer-327-e661002f00054328ab4daebbee984cc2 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-351-11bdafdd2bd5435bb5997f86fe7ae4ba {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-397-525489846c5f4d52a3d26a5730a36a01 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-352-c93f94abf67d4789b4f99b1005a6d8c9 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-398-24b90db859a342fa96cc324b45a84c3f {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-328-8b5bb76742034ded8f32b7d63ae09854 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-353-17c4729b9c0e4d2085718c2760c7404f {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-399-2f54de83a245432cb028570e13dba9ee {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-354-6a0744114643414b8034cd4b95ba2a5b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-400-bab999ebcb99475e8adbcbe6c8914884 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-329-c9a90b1f2e99419d856fcf6f4dfa15d2 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-355-e73b2d1302f84b7991c08f1dc309f13b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-404-03f5a92637a745a88fb9cb4b16550594 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-356-c0a6f85c507842449dad5d4790b11276 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-405-483f21e555ba43039f7e60271d82f655 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-732-40cd36593d214e4c8985f8862714d11c {
  --verticalAxis-40cd36593d214e4c8985f8862714d11c: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--40cd36593d214e4c8985f8862714d11c_orientation);
  --horizontalAxis-40cd36593d214e4c8985f8862714d11c: var(
    --40cd36593d214e4c8985f8862714d11c_horizontalAlignment
  );
}
.layer-330-bac6105a810c4bc6995345b407f5fb83 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-357-5519b3015c0a4021a1be74bad404e4d6 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-403-339bb4ad662a4a05b167385c83cf9c72 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-358-89b12de8d68e4288a428702fcd1d8fc2 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-406-8aff0fb14a9d489a8896ee2046a71264 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-331-9ea51973a6a245f59060b17ec48b34f9 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-359-9c607b6cf92641dc93178e06b5f47ea6 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-407-72dd9635dd844df09ee6607d9fe2b9bc {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-360-7608e6de181c4f1390ac61456c9e932c {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-408-f09b768c1ccb40a3ab49ea84c39c0aae {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-332-d20db1769c8a4829b1a8b93ffc78c9dd {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-361-b12477f9f96849cd88a8502fa433e806 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-409-a9483ad671ec42b1a15d1edcfd74fe48 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-362-a5ddfeae41da4df4a3a1f820c10004c8 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-410-90c084561d1440a089a050b8f186b9fd {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-323-58f1ae8049a447b0a28a03c7b8c97826 {
  --verticalAxis-58f1ae8049a447b0a28a03c7b8c97826: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--58f1ae8049a447b0a28a03c7b8c97826_orientation);
  --horizontalAxis-58f1ae8049a447b0a28a03c7b8c97826: var(
    --58f1ae8049a447b0a28a03c7b8c97826_horizontalAlignment
  );
}
.layer-333-95e37c3dc2324906a1337469225fb833 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-363-81c7fbc4c42d44878dd49ae90e2acd42 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-401-caaea98296294b418f992140886f21d4 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-364-6dda8ef5f6ef49ac8898d2c272767eeb {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-402-e55ece3960b94ea7b3b5e5b5f3a2f1d9 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-334-17bc6aaa79ce4e4ab9dcde96b35cb5e6 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-365-e3a3cf695ee242f78a4b4e52620a6e4a {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-390-d637af9ad06e4362bbbf45c790f93a25 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-366-28f40a5e17374c6db0d96e7c23a45090 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-389-5561b14ec64f4462bf77d54959a46b58 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-551-79e4ff4c227949d58301920cd94f2998 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-367-aabda1705cfb492aae7f0d87662ce363 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-388-ef8541973c3643ca8fb6e67d9cb7ecf0 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-368-5b28f4145856469595031d38d9d38a19 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-387-d6fa501e8eb244df835b251aad8c2e8f {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0 {
  --verticalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--5c8fd1d39d3949b7a4e1ece7bb0b1db0_orientation);
  --horizontalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0: var(
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_horizontalAlignment
  );
}
.layer-336-277515f5819d4e868aa5410102ef10d2 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-369-57ab494830454c5e8378d017239c552c {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-386-58f5db29869e47e68c0d70b03c0179eb {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-370-e1db84f8416a472eab2d8ea6432962e8 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-385-96d93fdb19784f9db1a6de42965923b5 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-337-dec8ac18262240439b3f9402d4fd55bf {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-371-244c72fa8bfc4f508f810acbd8be3b06 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-384-1ff7c8c3c0bb403a974232746d2c3f46 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-372-5f8729e4c43b4786b8054f6c83af1347 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-383-44f690d2b3b24e3bbcdc2e54863f7f93 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-338-c6ab728bd9be4dfe9b39930bb1af93c4 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-373-d32404e5708f47c098661b15537de7b8 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-382-9b931890734d44c791ca1edb0bf9e391 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-374-130a709b86f84a6cbdf72e5adaefb2bf {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-381-e93365f1037d4a7895a4e8f1dc3b5178 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-734-262f1cbfb0154413a569d893aa8e0440 {
  --verticalAxis-262f1cbfb0154413a569d893aa8e0440: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--262f1cbfb0154413a569d893aa8e0440_orientation);
  --horizontalAxis-262f1cbfb0154413a569d893aa8e0440: var(
    --262f1cbfb0154413a569d893aa8e0440_horizontalAlignment
  );
}
.layer-339-08a2940082ce42eeb4f40888a1fbeca6 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-375-a8d22a91c0f54f54b7b778d4488cd874 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-380-3813d7eaa28e4e4d8b80bdb087975b86 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-376-dee06bcecd3349a8b7eeb4c2283cd61a {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-379-e48061a5d05f43f7933319630b217962 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  margin-top: var(--ParagraphTextSize1);
  font-size: var(--ParagraphTextSize1);
}
.layer-340-181a5d0b6d0c4596a22947ba6595e1d9 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-377-e926b332375f4f1e8f14a3583114d9b8 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-378-a59ff831cb8e448da9541db0633da28a {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-378-7469ad3243074257babc078698b156cf {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-377-3f4657f1ef5141d287aade9721f438dc {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-341-b1f77dccb1bb474787700c1eaf1632e5 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-379-ecc2f8965a86481a82dc228f0e8fa820 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-376-30c5ce3db5594961bc67be2324b1a208 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-380-28b3a58f305e43aca56261bd9b66eb57 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-375-05c487261def4117bf511c8156609477 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-1791-ff640f2542f24c47b3901ecd700ec332 {
  --verticalAxis-ff640f2542f24c47b3901ecd700ec332: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--ff640f2542f24c47b3901ecd700ec332_orientation);
  --horizontalAxis-ff640f2542f24c47b3901ecd700ec332: var(
    --ff640f2542f24c47b3901ecd700ec332_horizontalAlignment
  );
}
.layer-342-d59c736b360f402d92c7bf0baa25339e {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-381-9e54a17f864c41daa47ff728090d335b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-374-9dfa0c0e91fb491585ffac5dcd861185 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-382-e264b91f9ac74e6284b006467841a47a {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-373-ffc2c4e0ce1e41778354ea8005acce10 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-343-5d9f83de04d349c7a6f6aaf301e38434 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-383-cd8fd03927994133b2a62387735ecca4 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-372-962cd3a8307f4a72b6894eb132772c23 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-384-608ae2db9c16451abfa7ebf82a4e0c8a {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-371-4c59576c77fe450e8167994fdd597c1b {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-344-0bf2c9171a654a78808759c36d4f5be6 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-385-48e7ca6f26d94afd83ee12db749174e3 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-370-9dfb712fde3049348085bcc83b9399fa {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-386-97af1600eb22480aaed50be671e912c4 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-369-e60db33ed4374cb982e2b45b136e8d45 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-735-bf039933831148ba9a81d5615b89ecf7 {
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  justify-content: var(--bf039933831148ba9a81d5615b89ecf7_verticalAlignment);
}
.layer-387-6ef1a81001d24ffabd63872ef70751b9 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-388-904e00f9a1b94b3db7d9c2d07cc28535 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-411-f6ee45a0272b4187bf51b135c3b7f9f5 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-389-7e91472d33cf4f39807247f89fbe3028 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-412-9d15a7f6125d4538a58b843a56d53efb {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-790-23c655743ef64e43a535e21dfb48f472 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-917-8c95c39b7c5e43ceb2583e335ffd212b {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-1544-aec6bc950c6d450ab11979a266711353 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-294-b568529c834347ad940b2dcbdf668c9d {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-295-a2c158939582425e891828b25738b833 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-296-54ecc07adef74a049d3336066352af70 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-297-d3b4bc6fd8b94b359b51c86bdb152911 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-1545-c2fdc9f651fa4ec197e50dccbd1f1342 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-1317-189865232b96486ea4f9dad16932b7cb {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-1318-af3913d5ad1e48ee871b14e314e4a965 {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.layer-728-3174e0a1485f4d09809f1ff14003b383-orientation {
  align-items: var(--verticalAxis-3174e0a1485f4d09809f1ff14003b383);
  justify-content: var(--horizontalAxis-3174e0a1485f4d09809f1ff14003b383);
}
.layer-729-9ca064655cef41fbbf27dffb442d132a-orientation {
  align-items: var(--verticalAxis-9ca064655cef41fbbf27dffb442d132a);
  justify-content: var(--horizontalAxis-9ca064655cef41fbbf27dffb442d132a);
}
.layer-732-40cd36593d214e4c8985f8862714d11c-orientation {
  align-items: var(--verticalAxis-40cd36593d214e4c8985f8862714d11c);
  justify-content: var(--horizontalAxis-40cd36593d214e4c8985f8862714d11c);
}
.layer-323-58f1ae8049a447b0a28a03c7b8c97826-orientation {
  align-items: var(--verticalAxis-58f1ae8049a447b0a28a03c7b8c97826);
  justify-content: var(--horizontalAxis-58f1ae8049a447b0a28a03c7b8c97826);
}
.layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0-orientation {
  align-items: var(--verticalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
  justify-content: var(--horizontalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
}
.layer-734-262f1cbfb0154413a569d893aa8e0440-orientation {
  align-items: var(--verticalAxis-262f1cbfb0154413a569d893aa8e0440);
  justify-content: var(--horizontalAxis-262f1cbfb0154413a569d893aa8e0440);
}
.layer-1791-ff640f2542f24c47b3901ecd700ec332-orientation {
  align-items: var(--verticalAxis-ff640f2542f24c47b3901ecd700ec332);
  justify-content: var(--horizontalAxis-ff640f2542f24c47b3901ecd700ec332);
}
@media (max-width: 480px) {
  .Derecholaboral {
    --3487d7cd5edd45fd8204a1a499fc4af8_visible: flex;
    --ce1c0f49f35f43799a6d4abfdef02665_visible: none;
    --f9e00d72224c4ee481a17c53a4b68dcf_visible: none;
    --3174e0a1485f4d09809f1ff14003b383_orientation: column;
    --3174e0a1485f4d09809f1ff14003b383_horizontalAlignment: center;
    --9ca064655cef41fbbf27dffb442d132a_orientation: column;
    --9ca064655cef41fbbf27dffb442d132a_horizontalAlignment: center;
    --40cd36593d214e4c8985f8862714d11c_orientation: column;
    --40cd36593d214e4c8985f8862714d11c_horizontalAlignment: center;
    --58f1ae8049a447b0a28a03c7b8c97826_orientation: column;
    --58f1ae8049a447b0a28a03c7b8c97826_horizontalAlignment: center;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_orientation: column;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_horizontalAlignment: center;
    --262f1cbfb0154413a569d893aa8e0440_orientation: column;
    --262f1cbfb0154413a569d893aa8e0440_horizontalAlignment: center;
    --ff640f2542f24c47b3901ecd700ec332_orientation: column;
    --ff640f2542f24c47b3901ecd700ec332_horizontalAlignment: center;
    --bf039933831148ba9a81d5615b89ecf7_verticalAlignment: center;
  }

  .layer-728-3174e0a1485f4d09809f1ff14003b383-orientation {
    align-items: var(--horizontalAxis-3174e0a1485f4d09809f1ff14003b383);
    justify-content: var(--verticalAxis-3174e0a1485f4d09809f1ff14003b383);
  }
  .layer-729-9ca064655cef41fbbf27dffb442d132a-orientation {
    align-items: var(--horizontalAxis-9ca064655cef41fbbf27dffb442d132a);
    justify-content: var(--verticalAxis-9ca064655cef41fbbf27dffb442d132a);
  }
  .layer-732-40cd36593d214e4c8985f8862714d11c-orientation {
    align-items: var(--horizontalAxis-40cd36593d214e4c8985f8862714d11c);
    justify-content: var(--verticalAxis-40cd36593d214e4c8985f8862714d11c);
  }
  .layer-323-58f1ae8049a447b0a28a03c7b8c97826-orientation {
    align-items: var(--horizontalAxis-58f1ae8049a447b0a28a03c7b8c97826);
    justify-content: var(--verticalAxis-58f1ae8049a447b0a28a03c7b8c97826);
  }
  .layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0-orientation {
    align-items: var(--horizontalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
    justify-content: var(--verticalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
  }
  .layer-734-262f1cbfb0154413a569d893aa8e0440-orientation {
    align-items: var(--horizontalAxis-262f1cbfb0154413a569d893aa8e0440);
    justify-content: var(--verticalAxis-262f1cbfb0154413a569d893aa8e0440);
  }
  .layer-1791-ff640f2542f24c47b3901ecd700ec332-orientation {
    align-items: var(--horizontalAxis-ff640f2542f24c47b3901ecd700ec332);
    justify-content: var(--verticalAxis-ff640f2542f24c47b3901ecd700ec332);
  }
}
@media (min-width: 1020px) {
  .Derecholaboral {
    --3487d7cd5edd45fd8204a1a499fc4af8_visible: none;
    --ce1c0f49f35f43799a6d4abfdef02665_visible: flex;
    --f9e00d72224c4ee481a17c53a4b68dcf_visible: flex;
    --3174e0a1485f4d09809f1ff14003b383_orientation: row;
    --3174e0a1485f4d09809f1ff14003b383_horizontalAlignment: space-between;
    --9ca064655cef41fbbf27dffb442d132a_orientation: row;
    --9ca064655cef41fbbf27dffb442d132a_horizontalAlignment: space-between;
    --40cd36593d214e4c8985f8862714d11c_orientation: row;
    --40cd36593d214e4c8985f8862714d11c_horizontalAlignment: space-between;
    --58f1ae8049a447b0a28a03c7b8c97826_orientation: row;
    --58f1ae8049a447b0a28a03c7b8c97826_horizontalAlignment: space-between;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_orientation: row;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_horizontalAlignment: space-between;
    --262f1cbfb0154413a569d893aa8e0440_orientation: row;
    --262f1cbfb0154413a569d893aa8e0440_horizontalAlignment: space-between;
    --ff640f2542f24c47b3901ecd700ec332_orientation: row;
    --ff640f2542f24c47b3901ecd700ec332_horizontalAlignment: space-between;
    --bf039933831148ba9a81d5615b89ecf7_verticalAlignment: center;
  }

  .layer-728-3174e0a1485f4d09809f1ff14003b383-orientation {
    align-items: var(--verticalAxis-3174e0a1485f4d09809f1ff14003b383);
    justify-content: var(--horizontalAxis-3174e0a1485f4d09809f1ff14003b383);
  }
  .layer-729-9ca064655cef41fbbf27dffb442d132a-orientation {
    align-items: var(--verticalAxis-9ca064655cef41fbbf27dffb442d132a);
    justify-content: var(--horizontalAxis-9ca064655cef41fbbf27dffb442d132a);
  }
  .layer-732-40cd36593d214e4c8985f8862714d11c-orientation {
    align-items: var(--verticalAxis-40cd36593d214e4c8985f8862714d11c);
    justify-content: var(--horizontalAxis-40cd36593d214e4c8985f8862714d11c);
  }
  .layer-323-58f1ae8049a447b0a28a03c7b8c97826-orientation {
    align-items: var(--verticalAxis-58f1ae8049a447b0a28a03c7b8c97826);
    justify-content: var(--horizontalAxis-58f1ae8049a447b0a28a03c7b8c97826);
  }
  .layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0-orientation {
    align-items: var(--verticalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
    justify-content: var(--horizontalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
  }
  .layer-734-262f1cbfb0154413a569d893aa8e0440-orientation {
    align-items: var(--verticalAxis-262f1cbfb0154413a569d893aa8e0440);
    justify-content: var(--horizontalAxis-262f1cbfb0154413a569d893aa8e0440);
  }
  .layer-1791-ff640f2542f24c47b3901ecd700ec332-orientation {
    align-items: var(--verticalAxis-ff640f2542f24c47b3901ecd700ec332);
    justify-content: var(--horizontalAxis-ff640f2542f24c47b3901ecd700ec332);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Derecholaboral {
    --3487d7cd5edd45fd8204a1a499fc4af8_visible: flex;
    --ce1c0f49f35f43799a6d4abfdef02665_visible: none;
    --f9e00d72224c4ee481a17c53a4b68dcf_visible: none;
    --3174e0a1485f4d09809f1ff14003b383_orientation: column;
    --3174e0a1485f4d09809f1ff14003b383_horizontalAlignment: center;
    --9ca064655cef41fbbf27dffb442d132a_orientation: column;
    --9ca064655cef41fbbf27dffb442d132a_horizontalAlignment: center;
    --40cd36593d214e4c8985f8862714d11c_orientation: column;
    --40cd36593d214e4c8985f8862714d11c_horizontalAlignment: center;
    --58f1ae8049a447b0a28a03c7b8c97826_orientation: column;
    --58f1ae8049a447b0a28a03c7b8c97826_horizontalAlignment: center;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_orientation: column;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_horizontalAlignment: center;
    --262f1cbfb0154413a569d893aa8e0440_orientation: column;
    --262f1cbfb0154413a569d893aa8e0440_horizontalAlignment: center;
    --ff640f2542f24c47b3901ecd700ec332_orientation: column;
    --ff640f2542f24c47b3901ecd700ec332_horizontalAlignment: center;
    --bf039933831148ba9a81d5615b89ecf7_verticalAlignment: center;
  }

  .layer-728-3174e0a1485f4d09809f1ff14003b383-orientation {
    align-items: var(--horizontalAxis-3174e0a1485f4d09809f1ff14003b383);
    justify-content: var(--verticalAxis-3174e0a1485f4d09809f1ff14003b383);
  }
  .layer-729-9ca064655cef41fbbf27dffb442d132a-orientation {
    align-items: var(--horizontalAxis-9ca064655cef41fbbf27dffb442d132a);
    justify-content: var(--verticalAxis-9ca064655cef41fbbf27dffb442d132a);
  }
  .layer-732-40cd36593d214e4c8985f8862714d11c-orientation {
    align-items: var(--horizontalAxis-40cd36593d214e4c8985f8862714d11c);
    justify-content: var(--verticalAxis-40cd36593d214e4c8985f8862714d11c);
  }
  .layer-323-58f1ae8049a447b0a28a03c7b8c97826-orientation {
    align-items: var(--horizontalAxis-58f1ae8049a447b0a28a03c7b8c97826);
    justify-content: var(--verticalAxis-58f1ae8049a447b0a28a03c7b8c97826);
  }
  .layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0-orientation {
    align-items: var(--horizontalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
    justify-content: var(--verticalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
  }
  .layer-734-262f1cbfb0154413a569d893aa8e0440-orientation {
    align-items: var(--horizontalAxis-262f1cbfb0154413a569d893aa8e0440);
    justify-content: var(--verticalAxis-262f1cbfb0154413a569d893aa8e0440);
  }
  .layer-1791-ff640f2542f24c47b3901ecd700ec332-orientation {
    align-items: var(--horizontalAxis-ff640f2542f24c47b3901ecd700ec332);
    justify-content: var(--verticalAxis-ff640f2542f24c47b3901ecd700ec332);
  }
}
