.Diegoduran {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.diegoduranheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.layer-408-5ae1443e137a46d594d0958799c9be97 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-408-572e1caaa61c48da94e281ec0d6cb542 {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-409-78292fe88fdc48f1856335473767da21 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--78292fe88fdc48f1856335473767da21_visible);
}
.text-485-f12573a2f82e40e3940ea8477e2476e4 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-486-df482956286e4c3f8cb82d4728e3d4c7 {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-487-1874364637ed4ed0b7cd98386814ccd6 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-488-ea74bebe27fd41b38deee0c4b9cd3ea9 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-410-13358e3df75644fcba840a2ddbc3673b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--13358e3df75644fcba840a2ddbc3673b_visible);
}
.text-489-d510bd65515d401699acbe142f0a3684 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-411-69d985ac8907423195b7b3b251269064 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--69d985ac8907423195b7b3b251269064_visible);
}
.icon-27-8e4087d129c94584af0d01b42be5798b {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.diegoduransection1 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.layer-404-6c2c5df5ba364c2b8c61194658885176 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-476-be69d26650844c90972fc5e23245466a {
  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.layer-525-abcaf23a75344645add1b6b0b86fffef {
  border-radius: 15px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-179-8d2806a41b1a4d2999611897646942fa {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.text-477-dc0f3843792a41c892ccc986ca50b885 {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.text-478-4148cf31d519482183405fdb74ee8f81 {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-223-863db6b5630a4116b8cba29d047ca4b1 {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.diegoduransection2 {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-diegoduransection2_valueheight);
  height: var(--diegoduransection2_valueheight);
}
.layer-405-87007fae8488423baafe60f20a949db8 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.text-479-9b9b04b872724cb08043a1650107c03b {
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.text-480-5d5bf0b3c12a4fb5878e3025432628b6 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.text-481-3d56ebc07e5a4592804a5c48ae50bf55 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.text-482-cc4f030200084665b0f8c5c79c1581d4 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-924-0a0a75c6bb1c4dfea48f2c9f19eeaced {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-582-c134d5bfa6014a41a9ebc014dde94fa9 {
  flex-shrink: 1;

  --margin-bottom: var(--FooterPaddingMobileV);
  margin-bottom: var(--margin-bottom);
  align-items: var(--c134d5bfa6014a41a9ebc014dde94fa9_horizontalAlignment);
  justify-content: var(--c134d5bfa6014a41a9ebc014dde94fa9_verticalAlignment);
  min-height: var(--min-c134d5bfa6014a41a9ebc014dde94fa9_valueheight);
  height: var(--c134d5bfa6014a41a9ebc014dde94fa9_valueheight);
  min-width: var(--min-c134d5bfa6014a41a9ebc014dde94fa9_valuewidth);
  width: var(--c134d5bfa6014a41a9ebc014dde94fa9_valuewidth);
}
.layer-544-fe5e5912daa043f0ba86594b393be852 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-467-d4f78f37369042c6bdf2a82b93ddf306 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-468-86d1784a2b6f46e19b152fab36635d1d {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-469-d2d13a56b857463aa4455615b15d3994 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-470-e7588ce0d34b4412bd5946df7b8e9abe {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-545-014d1353b7034183a8a0607965fb448e {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-532-400ac49e52f0474a8719bcdc1b7e9a27 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-533-d702a5784ae44774a20f41463c6ac1e4 {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .Diegoduran {
    --69d985ac8907423195b7b3b251269064_visible: flex;
    --13358e3df75644fcba840a2ddbc3673b_visible: none;
    --78292fe88fdc48f1856335473767da21_visible: none;
    --min-diegoduransection2_valueheight: unset;
    --diegoduransection2_valueheight: fit-content;
    --c134d5bfa6014a41a9ebc014dde94fa9_horizontalAlignment: center;
    --c134d5bfa6014a41a9ebc014dde94fa9_verticalAlignment: center;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valueheight: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valueheight: fit-content;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: fit-content;
  }
}
@media (min-width: 1020px) {
  .Diegoduran {
    --69d985ac8907423195b7b3b251269064_visible: none;
    --13358e3df75644fcba840a2ddbc3673b_visible: flex;
    --78292fe88fdc48f1856335473767da21_visible: flex;
    --min-diegoduransection2_valueheight: unset;
    --diegoduransection2_valueheight: 100%;
    --c134d5bfa6014a41a9ebc014dde94fa9_horizontalAlignment: center;
    --c134d5bfa6014a41a9ebc014dde94fa9_verticalAlignment: center;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valueheight: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valueheight: fit-content;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: fit-content;
  }

  .diegoduransection2 {
    height: var(--diegoduransection2_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Diegoduran {
    --69d985ac8907423195b7b3b251269064_visible: flex;
    --13358e3df75644fcba840a2ddbc3673b_visible: none;
    --78292fe88fdc48f1856335473767da21_visible: none;
    --min-diegoduransection2_valueheight: unset;
    --diegoduransection2_valueheight: fit-content;
    --c134d5bfa6014a41a9ebc014dde94fa9_horizontalAlignment: center;
    --c134d5bfa6014a41a9ebc014dde94fa9_verticalAlignment: center;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valueheight: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valueheight: fit-content;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: fit-content;
  }
}
