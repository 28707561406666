.Politicadetratamientodedatos {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.politicadetratamientodedatosheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.layer-192-8c467f7b9f0440b29ac550959d51f043 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-332-85635280a723408c88edbea32ff24de9 {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-193-dcda1da104f1453cb34ffd9b57edf130 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--dcda1da104f1453cb34ffd9b57edf130_visible);
}
.text-182-298104f6ee594404a4189c85817a40f6 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-183-7b539479ed0340208ff0ed3d3a4e6a14 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-184-30156efb28084180bba7084cff0ddeb4 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-185-c9bf7ba79f88477486a26dd4c7601733 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-194-936c6be7b01a47aa946b3510c80b6b6e {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--936c6be7b01a47aa946b3510c80b6b6e_visible);
}
.text-186-cdd5afdacc0e4267ab9b67e61ca7e14c {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-195-1c13e243f0da4659b6aa14f8994ae82b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--1c13e243f0da4659b6aa14f8994ae82b_visible);
}
.icon-11-3eebdb6485c742199ec9097dbcfe6c8e {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
}
.politicadetratamientodedatossection1 {
  padding-left: 6vw;
  padding-right: 6vw;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
}
.text-189-2e0a115504ba427e8e0b4d9f2eaef968 {
  text-align: center;

  min-width: unset;
  width: 100%;
  font-family: var(--FontBold);
  font-size: var(--ParagraphTextSize1);
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin2V);
}
.text-190-f9eaa17f4cdd41129f0c9a73b0736cec {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-191-23946aa6fcf3406b8cf7a993648adc29 {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-192-cb65fd544dd94bd9888a52ffc5b2969b {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-193-154c0227f4e446429ed603e2a0d262b9 {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-194-a513b368f6e84711b1850c04a202fa7c {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-195-0eddde7da79f402aaa34bc34ff994988 {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-196-8788154d5738454484156f43f8660fb0 {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-577-4de4a47421d04f47b631c2b3b6cd2e56 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-198-0f6536f2dbda4702bc7b6d52357c8cfd {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-199-0a4a74e0a3694ffd90b910db17b465aa {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-200-33b5037e58134d53b6914075eca4cca7 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-201-d349dacbcffc41ec8dba4884eebaa02b {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-202-cafe4961762a4a82aa0a3aa018347edc {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-203-967bf5a487e04385a124dc38c8679500 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-204-a7950799264e4088a0dcf321334435e9 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-205-8e72491f27d04336b4c83c8f9b401065 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-206-c8d435c1b0884054b174267c969ba8ab {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-207-10e8043d53c74802b8d08906d69b3b01 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-208-558ef780e20c46eca6e5cf7d86c0fd7b {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-209-f3d5701bcb874bedb91e24f1271aea9c {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-210-a12ae97eb4fb4f9fb19056e9e1ed7e8c {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-578-94ab15f46db04a7fb9a4021104bcf895 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-212-bdc4822287bf418bb0a1d651a42afb95 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-213-51483cb09dc842838ccf2450efbbd1b1 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-214-6821c1d92b164ce094e0e17755e75c60 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-215-2bec1b7112234c29955c9d1c22ea901a {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-216-f0d970310332438899efbb8961aae082 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-217-957fc496c8de4692b0815d22b2c053e2 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-218-a95a72ef9037498382951c59bc91acde {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-219-81c16cb43e1b460e82758c31bbed26f4 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-220-5dae5f7de7734ca1a8ae48abfc676624 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin1V);
}
.layer-679-5d55a97b149e458e84838a9563df60a6 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-806-bc43b10916504b68b53d4b8dd0aaf785 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.layer-196-7f796a355d0a4457abc6e4449049a079 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-465-f653ea73c41e4de7826f01b1ed6ca3f4 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-466-fe6be3fddc164a2c8ce89a416d72ccd3 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-467-ac67055a20cc42ea8906c08600990e8a {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-468-c77cbeec117b48af91f33346f774a31e {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-197-0688448011ad480fba09976b0128a105 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-187-f2c4a3b8a3b44410988717f515e9743a {
  font-family: var(--FontBold);
  font-size: var(--FooterTextSize);
}
.text-188-afd4b51de30744f0813f2662c0a31ef4 {
  font-family: var(--FontRegular);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .Politicadetratamientodedatos {
    --1c13e243f0da4659b6aa14f8994ae82b_visible: flex;
    --936c6be7b01a47aa946b3510c80b6b6e_visible: none;
    --dcda1da104f1453cb34ffd9b57edf130_visible: none;
  }
}
@media (min-width: 1020px) {
  .Politicadetratamientodedatos {
    --1c13e243f0da4659b6aa14f8994ae82b_visible: none;
    --936c6be7b01a47aa946b3510c80b6b6e_visible: flex;
    --dcda1da104f1453cb34ffd9b57edf130_visible: flex;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Politicadetratamientodedatos {
    --1c13e243f0da4659b6aa14f8994ae82b_visible: flex;
    --936c6be7b01a47aa946b3510c80b6b6e_visible: none;
    --dcda1da104f1453cb34ffd9b57edf130_visible: none;
  }
}
