.Areasdepractica {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.areasdepracticaheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.layer-112-6c6ae025310f45bca25605d15fc721c0 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-252-bd4d1eb68f394ea2b338cad83c217efb {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-113-655def239ea44152bc9b532895aa9e3a {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--655def239ea44152bc9b532895aa9e3a_visible);
}
.text-104-5ccb1d7c3a2a4a8da3e39699076d5f0d {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-105-673cd3e949ff4134bebb5a71880a8684 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-106-8c35cfc506594f68b041127f6994cefa {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-107-6133556da82e45aba737202df37f1931 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-114-5395ab6e28cc4d51b4a0c716a2e94fac {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--5395ab6e28cc4d51b4a0c716a2e94fac_visible);
}
.text-103-c5c0c21aa2e642598e29af1408429d70 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-115-fbf4592cb836416c969f70f5e2a0e8ee {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--fbf4592cb836416c969f70f5e2a0e8ee_visible);
}
.icon-5-fc135017ad7b4694bc615f4849a45d69 {
  font-size: var(--fc135017ad7b4694bc615f4849a45d69_valueheight);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  min-height: var(--min-fc135017ad7b4694bc615f4849a45d69_valueheight);
  height: var(--fc135017ad7b4694bc615f4849a45d69_valueheight);
}
.areasdepracticasections {
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--areasdepracticasections_orientation);
}
.areasdepracticasection1 {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Shaking Hands.png");
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  min-height: var(--min-areasdepracticasection1_valueheight);
  height: var(--areasdepracticasection1_valueheight);
}
.layer-473-30c9bdeffbfc4c9586b9384a1b7ba9ee {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-424-46f53917ad464e179def4d6e387f832c {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.areasdepracticasection2 {
  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.layer-105-9437ab65e5b2417da06b14da6a7c330d {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.layer-724-45be52233c4a41bd9cfb70c10b840053 {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.text-98-81c38722a0724aaf96553a4d4b170cd8 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-723-fe4a85cb47c64fca87f5fb80713a3364 {
  justify-content: center;
  align-items: center;

  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-588-50293e9869904db38922ea0113daffb6 {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.layer-106-8794ad9f64e04b098e64681c5bb4a167 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
}
.layer-725-18e2a778bbb745baa9be7ac72b0b33a7 {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;

  --margin-bottom: var(--ItemMargin3V);
  margin-bottom: var(--margin-bottom);
  min-height: var(--min-18e2a778bbb745baa9be7ac72b0b33a7_valueheight);
  height: var(--18e2a778bbb745baa9be7ac72b0b33a7_valueheight);
  min-width: var(--min-18e2a778bbb745baa9be7ac72b0b33a7_valuewidth);
  width: var(--18e2a778bbb745baa9be7ac72b0b33a7_valuewidth);
}
.text-99-4d7261a5daa04d488fa7b4109e58e3ab {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-3108-d173fe693bd54c2abc7a793a88ebb6ff {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-589-edc9d5be10bd454e9cab2db0b9cea520 {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.layer-726-7eb28cd100a64ba3995eb684d625dd79 {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;

  --margin-bottom: var(--ItemMargin3V);
  margin-bottom: var(--margin-bottom);
  min-height: var(--min-7eb28cd100a64ba3995eb684d625dd79_valueheight);
  height: var(--7eb28cd100a64ba3995eb684d625dd79_valueheight);
  min-width: var(--min-7eb28cd100a64ba3995eb684d625dd79_valuewidth);
  width: var(--7eb28cd100a64ba3995eb684d625dd79_valuewidth);
}
.layer-107-2ae52e136d8a4b148e1e56d502b900bc {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.layer-727-a67364d52cb74ab8a6f066ebd871ae9e {
  flex-shrink: 1;

  --margin-bottom: var(--ItemMargin3V);
  margin-bottom: var(--margin-bottom);
  min-height: var(--min-a67364d52cb74ab8a6f066ebd871ae9e_valueheight);
  height: var(--a67364d52cb74ab8a6f066ebd871ae9e_valueheight);
  min-width: var(--min-a67364d52cb74ab8a6f066ebd871ae9e_valuewidth);
  width: var(--a67364d52cb74ab8a6f066ebd871ae9e_valuewidth);
}
.text-100-4db6eb61e75a4ffe8c14a921c0a761ab {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-730-a9053730d74740448e6f77b7e7e4127a {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;
  min-height: var(--min-a9053730d74740448e6f77b7e7e4127a_valueheight);
  height: var(--a9053730d74740448e6f77b7e7e4127a_valueheight);
  min-width: var(--min-a9053730d74740448e6f77b7e7e4127a_valuewidth);
  width: var(--a9053730d74740448e6f77b7e7e4127a_valuewidth);
}
.text-590-ca98d0b8646942f9becce8e2e20237c6 {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.layer-108-6634e7881dc344f7aa72ef86ddfc50d7 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
}
.layer-728-74c7e4a778af4e1fb60e29b44c9b29ce {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;

  --margin-bottom: var(--ItemMargin3V);
  margin-bottom: var(--margin-bottom);
  min-height: var(--min-74c7e4a778af4e1fb60e29b44c9b29ce_valueheight);
  height: var(--74c7e4a778af4e1fb60e29b44c9b29ce_valueheight);
  min-width: var(--min-74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth);
  width: var(--74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth);
}
.text-101-fd769ce6d15b47b2b6b787d94546bee9 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-731-a0a914708d1e4cc3a3aa645a32c88c0d {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;
  min-height: var(--min-a0a914708d1e4cc3a3aa645a32c88c0d_valueheight);
  height: var(--a0a914708d1e4cc3a3aa645a32c88c0d_valueheight);
  min-width: var(--min-a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth);
  width: var(--a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth);
}
.text-591-677f153832e5499597fed6553a66edae {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.layer-109-2de9a513daab46879388010ff5c8c7a5 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.layer-729-8762de753ddf403e8c4d14b7c5de4c31 {
  flex-shrink: 1;

  --margin-bottom: var(--ItemMargin3V);
  margin-bottom: var(--margin-bottom);
  min-height: var(--min-8762de753ddf403e8c4d14b7c5de4c31_valueheight);
  height: var(--8762de753ddf403e8c4d14b7c5de4c31_valueheight);
  min-width: var(--min-8762de753ddf403e8c4d14b7c5de4c31_valuewidth);
  width: var(--8762de753ddf403e8c4d14b7c5de4c31_valuewidth);
}
.text-102-4b00d730ab274051908b8e2ab60e32ea {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-732-7dfabd7616db4f96a37980c03b4cc549 {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;
  min-height: var(--min-7dfabd7616db4f96a37980c03b4cc549_valueheight);
  height: var(--7dfabd7616db4f96a37980c03b4cc549_valueheight);
  min-width: var(--min-7dfabd7616db4f96a37980c03b4cc549_valuewidth);
  width: var(--7dfabd7616db4f96a37980c03b4cc549_valuewidth);
}
.text-592-5c332a7e1073408d8c57abffd6c152d6 {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.layer-525-0432ec8d04e24927926df9c414d68f44 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-554-b7c8c85d39bd4d8e8f4d34d997e8aab8 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-1043-265b3e58eb6c4ff6a476e53af9a6bf98 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-296-9a7b5deb8dd043abad1b86a55372fd74 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-297-09ea20d1d14d4ef19573a11f50df786e {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-298-452738ffa50e45e183a79e600eb0bb26 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-299-b41a89827bca4fb0b15d98988090f4fb {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
}
.layer-111-ab0b6e55cfbf49a0afb5d9c0b0bb4411 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-96-04e9b3a7f5c549269a7250bb65c21a4f {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-97-25890b7d195f4122927181352ccb4e81 {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.areasdepracticasections-orientation {
  align-items: var(--verticalAxis-areasdepracticasections);
  justify-content: var(--horizontalAxis-areasdepracticasections);
}
@media (max-width: 480px) {
  .Areasdepractica {
    --fbf4592cb836416c969f70f5e2a0e8ee_visible: flex;
    --5395ab6e28cc4d51b4a0c716a2e94fac_visible: none;
    --655def239ea44152bc9b532895aa9e3a_visible: none;
    --min-fc135017ad7b4694bc615f4849a45d69_valueheight: 30px;
    --fc135017ad7b4694bc615f4849a45d69_valueheight: 30px;
    --areasdepracticasections_orientation: column;
    --min-areasdepracticasection1_valueheight: 30vh;
    --areasdepracticasection1_valueheight: 30vh;
    --50293e9869904db38922ea0113daffb6_text: Constitución y liquidación de
        sociedades nacionales,
      reformas estatutarias y acompañamiento legal en procesos de
        adquisiciones...;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valueheight: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valueheight: fit-content;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valueheight: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valueheight: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valuewidth: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valuewidth: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valueheight: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valueheight: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valueheight: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valueheight: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valuewidth: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valuewidth: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valueheight: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valueheight: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valuewidth: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valuewidth: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valueheight: unset;
    --a9053730d74740448e6f77b7e7e4127a_valueheight: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valuewidth: unset;
    --a9053730d74740448e6f77b7e7e4127a_valuewidth: fit-content;
  }

  .areasdepracticasections-orientation {
    align-items: var(--horizontalAxis-areasdepracticasections);
    justify-content: var(--verticalAxis-areasdepracticasections);
  }
}
@media (min-width: 1020px) {
  .Areasdepractica {
    --fbf4592cb836416c969f70f5e2a0e8ee_visible: none;
    --5395ab6e28cc4d51b4a0c716a2e94fac_visible: flex;
    --655def239ea44152bc9b532895aa9e3a_visible: flex;
    --min-fc135017ad7b4694bc615f4849a45d69_valueheight: 30px;
    --fc135017ad7b4694bc615f4849a45d69_valueheight: 30px;
    --areasdepracticasections_orientation: row;
    --min-areasdepracticasection1_valueheight: unset;
    --areasdepracticasection1_valueheight: 100%;
    --50293e9869904db38922ea0113daffb6_text: Constitución y liquidación de
        sociedades nacionales,
      reformas estatutarias y acompañamiento legal en procesos de
        adquisiciones...;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valueheight: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valueheight: fit-content;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valueheight: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valueheight: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valuewidth: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valuewidth: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valueheight: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valueheight: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valueheight: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valueheight: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valuewidth: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valuewidth: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valueheight: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valueheight: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valuewidth: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valuewidth: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valueheight: unset;
    --a9053730d74740448e6f77b7e7e4127a_valueheight: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valuewidth: unset;
    --a9053730d74740448e6f77b7e7e4127a_valuewidth: fit-content;
  }

  .areasdepracticasections-orientation {
    align-items: var(--verticalAxis-areasdepracticasections);
    justify-content: var(--horizontalAxis-areasdepracticasections);
  }
  .areasdepracticasection1 {
    height: var(--areasdepracticasection1_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Areasdepractica {
    --fbf4592cb836416c969f70f5e2a0e8ee_visible: flex;
    --5395ab6e28cc4d51b4a0c716a2e94fac_visible: none;
    --655def239ea44152bc9b532895aa9e3a_visible: none;
    --min-fc135017ad7b4694bc615f4849a45d69_valueheight: 24px;
    --fc135017ad7b4694bc615f4849a45d69_valueheight: 24px;
    --areasdepracticasections_orientation: column;
    --min-areasdepracticasection1_valueheight: 30vh;
    --areasdepracticasection1_valueheight: 30vh;
    --50293e9869904db38922ea0113daffb6_text: Constitución y liquidación de
        sociedades nacionales,
      reformas estatutarias y acompañamiento legal en procesos de
        adquisiciones...;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valueheight: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valueheight: fit-content;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valueheight: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valueheight: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valuewidth: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valuewidth: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valueheight: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valueheight: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valueheight: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valueheight: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valuewidth: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valuewidth: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valueheight: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valueheight: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valuewidth: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valuewidth: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valueheight: unset;
    --a9053730d74740448e6f77b7e7e4127a_valueheight: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valuewidth: unset;
    --a9053730d74740448e6f77b7e7e4127a_valuewidth: fit-content;
  }

  .areasdepracticasections-orientation {
    align-items: var(--horizontalAxis-areasdepracticasections);
    justify-content: var(--verticalAxis-areasdepracticasections);
  }
}
