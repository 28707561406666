.Actualidad {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.actualidadheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.layer-693-df5d043e51ee4706841b3e5abd03ac6c {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-265-4f3e9a9c329d4a6c8dbdfd26fd78cf5a {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-694-4903a3cab5f9457a87652648453d7f4e {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--4903a3cab5f9457a87652648453d7f4e_visible);
}
.text-582-773b0bd783d24c17a3a9a94da8c61172 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-583-3e8f6d914391468a9c7afa711cc1b380 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-584-068d99dbd72043bcb3b3c9b47ea63384 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-585-73a80bbf66274c3081e06ee6718d681d {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-695-3dd8ab102b0b4bdbbc98a4cc131e1a6b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--3dd8ab102b0b4bdbbc98a4cc131e1a6b_visible);
}
.text-586-1b017a2da6ed4f93add0081017f40d53 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-696-31fc538627a84185b260a7677c35681b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--31fc538627a84185b260a7677c35681b_visible);
}
.icon-25-9d210291c38f485ab6113f723a44ad57 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.actualidadsection1 {
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.layer-598-6b7de485df9c4212aa48d256f9fab225 {
  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.layer-640-203a36235c934805ad0d205079eeea71 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-564-d5538693a7724946b140868c56a51a74 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-476-1f0b2118dd1f42f7b4a4ca72b4ef6c64 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-161-cef94b72e10b41b5bfb5230a981d03d5 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-166-bd5054986ec94b469b7d354dcbb21edf {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-163-2f040373153140cab1acf5834d37d14a {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-164-ea85782686bd4898aacffa4a53937b62 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--ea85782686bd4898aacffa4a53937b62_margin-right);
  margin-right: var(--margin-right);
}
.layer-477-619a479db478480dbf1a568c3d312027 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-436-9c2f983ef0ad440a99217d10eb057c44 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-437-b7e14aeb75984b21920c49428a78ad87 {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .Actualidad {
    --31fc538627a84185b260a7677c35681b_visible: flex;
    --3dd8ab102b0b4bdbbc98a4cc131e1a6b_visible: none;
    --4903a3cab5f9457a87652648453d7f4e_visible: none;
    --min-ea85782686bd4898aacffa4a53937b62_margin-right: 0vw;
    --ea85782686bd4898aacffa4a53937b62_margin-right: 0vw;
  }
}
@media (min-width: 1020px) {
  .Actualidad {
    --31fc538627a84185b260a7677c35681b_visible: none;
    --3dd8ab102b0b4bdbbc98a4cc131e1a6b_visible: flex;
    --4903a3cab5f9457a87652648453d7f4e_visible: flex;
    --min-ea85782686bd4898aacffa4a53937b62_margin-right: 0px;
    --ea85782686bd4898aacffa4a53937b62_margin-right: 0px;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Actualidad {
    --31fc538627a84185b260a7677c35681b_visible: flex;
    --3dd8ab102b0b4bdbbc98a4cc131e1a6b_visible: none;
    --4903a3cab5f9457a87652648453d7f4e_visible: none;
    --min-ea85782686bd4898aacffa4a53937b62_margin-right: 0px;
    --ea85782686bd4898aacffa4a53937b62_margin-right: 0px;
  }
}
