.Derechodelosnegocios {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.derechodelosnegociosheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.layer-448-99725734ed1a4590b8b2fa466c2231dd {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-348-f8318da41233470abf2575305f42c71a {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-449-bcb4419adbe746d3a8c9536ff9158594 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--bcb4419adbe746d3a8c9536ff9158594_visible);
}
.text-392-98ed32b1bb7f410fb891d5c3730cfe1c {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-393-11a5e84432d44a8dac44e48094b7bf60 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-394-daf50a85e1744e88927473387a2c305e {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-395-9de0914284fe43c98d81b58f65e23049 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-450-a87b299feca54a9580754561145b86bd {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--a87b299feca54a9580754561145b86bd_visible);
}
.text-396-df2c1d6f37ed4c059281eb0bce5e6c37 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-451-73d2cc46a4c84b759ace7594fbdc4687 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--73d2cc46a4c84b759ace7594fbdc4687_visible);
}
.icon-16-0f05fb8a0c4c469785cbdf15a495b8a0 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.derechodelosnegociossection1 {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Derecho de los negocios_square.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.text-467-0441bc4856f44527baf75ce10f3594d6 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
  text-align: var(--0441bc4856f44527baf75ce10f3594d6_textAlign);
}
.derechodelosnegociossection2 {
  --verticalAxis-derechodelosnegociossection2: center;
  --horizontalAxis-derechodelosnegociossection2: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  flex-direction: var(--derechodelosnegociossection2_orientation);
  min-height: var(--min-derechodelosnegociossection2_valueheight);
  height: var(--derechodelosnegociossection2_valueheight);
}
.layer-447-073737606d2b4fecb79409531b789fdc {
  --verticalAxis-073737606d2b4fecb79409531b789fdc: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--073737606d2b4fecb79409531b789fdc_orientation);
  --horizontalAxis-073737606d2b4fecb79409531b789fdc: var(
    --073737606d2b4fecb79409531b789fdc_horizontalAlignment
  );
}
.layer-551-582a383533554d38a3a58b8617030571 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
  margin-top: var(--ItemMargin2V);
}
.layer-553-266bb3031f2c4640968055f44fc7b70f {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-475-385b347606114a5fa8f60072950203d0 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-554-279fb8306dc043e1a18398009bbb324b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-474-5f03b531eca64e10b385767a5dd77b8b {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.layer-552-e0751702ce2f4c42beeb861f1d52be52 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-555-756aeae75299467ca7a3e427879995e3 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-473-996b11b0174547b086a5be8a4b69aaed {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-556-34670f786c13442d9c1b85f1f5283ca6 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-472-774b5513577448d898f1a569dfa2f7e9 {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.layer-557-47181be32de04aadb41e0943d50b721a {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
  margin-top: var(--ItemMargin2V);
}
.layer-558-560bad01bccd4dfeb5ed3c6275e96b2b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-471-d4b5cc72836f4c168ee842142c43f0eb {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-559-f914580563624d9189bb353e8f98d3a1 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-470-eb142efe1dde44b184474a71d876b1e4 {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.layer-550-85f962fe8a414d01a82086c5055e0893 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.layer-560-674430d4dcd948438cf7954112a7e634 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-562-31742b8bb400402db8e5818a5bc53d16 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-469-495709f574ba43a099e6c0bbc1b0dc9b {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-561-58c6f0c508f34f4abbea2f02f5bbd647 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-468-3a80bf5dc50e487d902281cd4ef2974f {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.layer-704-84acdc2713a74cc6aa3a8f900934fc38 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-831-1362ec1d01f24845bcd616ea0e42b25e {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-1220-e5ac884349954747ae53ef043dd1b1be {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-285-1f7998ccf4944c8dbfe706a5ef8d29e7 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-286-83a88a94714d4657be854097dfb8b8c0 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-287-61aee76781bf4a4683060801f804fbb7 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-288-d0162e402cf54815a2a052a0aa2c22ae {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-1221-be33609c36c442c5a1782bc64b35be32 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-1068-636c63fbf5b6419ca362f5b84f14e24e {
  font-family: var(--FontBold);
  font-size: var(--FooterTextSize);
}
.text-1069-6f57cab7946f4fa8807b8da66aa563e6 {
  font-family: var(--FontRegular);
  font-size: var(--FooterTextSize);
}

.derechodelosnegociossection2-orientation {
  align-items: var(--horizontalAxis-derechodelosnegociossection2);
  justify-content: var(--verticalAxis-derechodelosnegociossection2);
}
.layer-447-073737606d2b4fecb79409531b789fdc-orientation {
  align-items: var(--verticalAxis-073737606d2b4fecb79409531b789fdc);
  justify-content: var(--horizontalAxis-073737606d2b4fecb79409531b789fdc);
}
@media (max-width: 480px) {
  .Derechodelosnegocios {
    --73d2cc46a4c84b759ace7594fbdc4687_visible: flex;
    --a87b299feca54a9580754561145b86bd_visible: none;
    --bcb4419adbe746d3a8c9536ff9158594_visible: none;
    --derechodelosnegociossection2_orientation: column;
    --073737606d2b4fecb79409531b789fdc_orientation: column;
    --073737606d2b4fecb79409531b789fdc_horizontalAlignment: center;
    --min-derechodelosnegociossection2_valueheight: unset;
    --derechodelosnegociossection2_valueheight: fit-content;
    --0441bc4856f44527baf75ce10f3594d6_textAlign: center;
  }

  .derechodelosnegociossection2-orientation {
    align-items: var(--horizontalAxis-derechodelosnegociossection2);
    justify-content: var(--verticalAxis-derechodelosnegociossection2);
  }
  .layer-447-073737606d2b4fecb79409531b789fdc-orientation {
    align-items: var(--horizontalAxis-073737606d2b4fecb79409531b789fdc);
    justify-content: var(--verticalAxis-073737606d2b4fecb79409531b789fdc);
  }
}
@media (min-width: 1020px) {
  .Derechodelosnegocios {
    --73d2cc46a4c84b759ace7594fbdc4687_visible: none;
    --a87b299feca54a9580754561145b86bd_visible: flex;
    --bcb4419adbe746d3a8c9536ff9158594_visible: flex;
    --derechodelosnegociossection2_orientation: column;
    --073737606d2b4fecb79409531b789fdc_orientation: row;
    --073737606d2b4fecb79409531b789fdc_horizontalAlignment: space-between;
    --min-derechodelosnegociossection2_valueheight: 60vh;
    --derechodelosnegociossection2_valueheight: 60vh;
    --0441bc4856f44527baf75ce10f3594d6_textAlign: center;
  }

  .derechodelosnegociossection2-orientation {
    align-items: var(--horizontalAxis-derechodelosnegociossection2);
    justify-content: var(--verticalAxis-derechodelosnegociossection2);
  }
  .layer-447-073737606d2b4fecb79409531b789fdc-orientation {
    align-items: var(--verticalAxis-073737606d2b4fecb79409531b789fdc);
    justify-content: var(--horizontalAxis-073737606d2b4fecb79409531b789fdc);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Derechodelosnegocios {
    --73d2cc46a4c84b759ace7594fbdc4687_visible: flex;
    --a87b299feca54a9580754561145b86bd_visible: none;
    --bcb4419adbe746d3a8c9536ff9158594_visible: none;
    --derechodelosnegociossection2_orientation: column;
    --073737606d2b4fecb79409531b789fdc_orientation: column;
    --073737606d2b4fecb79409531b789fdc_horizontalAlignment: center;
    --min-derechodelosnegociossection2_valueheight: unset;
    --derechodelosnegociossection2_valueheight: fit-content;
    --0441bc4856f44527baf75ce10f3594d6_textAlign: center;
  }

  .derechodelosnegociossection2-orientation {
    align-items: var(--horizontalAxis-derechodelosnegociossection2);
    justify-content: var(--verticalAxis-derechodelosnegociossection2);
  }
  .layer-447-073737606d2b4fecb79409531b789fdc-orientation {
    align-items: var(--horizontalAxis-073737606d2b4fecb79409531b789fdc);
    justify-content: var(--verticalAxis-073737606d2b4fecb79409531b789fdc);
  }
}
