.Nosotros {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.nosotrosheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.layer-190-7275e2d17eec41bab67ef9b42c21db93 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-185-abdc99b8c6af4650a6aaffb104266141 {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-191-fbeb0b5af3e74b008ff14bf9fcc32aa3 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--fbeb0b5af3e74b008ff14bf9fcc32aa3_visible);
}
.text-168-7efe370e236b42bda831d553611dd2f7 {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-169-a0a6c0fa86f44722a5d1ae8e8488b920 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-170-3999ae8144cd4b7abea16cd8e2958d5b {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-171-d6e1ee4690a245eebe1e2e83a11a3394 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-192-8fc0dbb345d3493cbb9a5fc38c11383a {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--8fc0dbb345d3493cbb9a5fc38c11383a_visible);
}
.text-172-84e58c0ab1e14e6e95f7f3c2bcb357c1 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-193-eec0666785694219a877da91dfa34558 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--eec0666785694219a877da91dfa34558_visible);
}
.icon-7-65ad13bb97d0486e8f38a20ceea6b072 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.nosotrossection1 {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Nosotros.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.text-426-bb6fa25c8b9b43cf8e3335c309540633 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.nosotrossection2 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-nosotrossection2_valueheight);
  height: var(--nosotrossection2_valueheight);
}
.layer-464-416175c480aa485990e41800c75082ec {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--416175c480aa485990e41800c75082ec_orientation);
  --horizontalAxis-416175c480aa485990e41800c75082ec: var(
    --416175c480aa485990e41800c75082ec_horizontalAlignment
  );
}
.layer-187-6bcbaee1c9d5475a8ede36e24ba7c695 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
}
.layer-592-3fce799a69994a46b0fcd13bbe9fd8cd {
  border-radius: 15px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-229-9658179f1616425f98a18a4b2fd6a034 {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.text-160-47a2c8aa7e034a2d906376735707daf6 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-161-4fd3fe7b7d9f4ae9a6004ecc5f300454 {
  text-align: center;

  width: 250px;
  min-width: 250px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.layer-188-153233a9e0f24dec8d8f5799e69a6af1 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
}
.layer-593-c8a86431bf5942f2952420c34f68a016 {
  border-radius: 15px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-230-70c2857d9bb04d30ac044b3f0b83ba99 {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.text-162-74602e861547418c8fd00bd5f447801c {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.text-163-03b995b4abf34932bb4d0cd51615e96e {
  text-align: center;

  width: 250px;
  min-width: 250px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.layer-189-399dddfa2daf4cfebb25eb022b0df987 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
}
.layer-506-8bf790482fa84c70b582a5222ad8ab50 {
  border-radius: 15px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-177-a86bc788825a4dcdb9b7de5465e0c955 {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.text-164-9212fdb9ae894dc6b40aaebbacf30562 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin3V);
}
.text-165-e673dac373dd4fa6a2e9577e49adf8b9 {
  text-align: center;

  width: 250px;
  min-width: 250px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.nosotrosfooter {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-481-8ea8d47ac0df41bd974edc6a3ade41ea {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-95-be46962f2c7a4604a8db5697a1dccaa3 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-44-b1e4a4ccad6a49058c9e8d8274ecbe00 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-45-b6d0f595e5fb4be9b82f14bbb5f204d4 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-46-7365aa0eb1af4f7ab2276b19d4ae7a49 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-47-74c50e2b301245b5987c5c72b30d8ce5 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-96-0cf1699454a84b568873220e1390f61f {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-81-43caecdf727b4996ad85b095a8116905 {
  text-align: center;

  font-family: var(--FontBold);
  font-size: var(--FooterTextSize);
}
.text-82-77addbf003584e37b65f343026a05ec9 {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.layer-464-416175c480aa485990e41800c75082ec-orientation {
  align-items: var(--verticalAxis-416175c480aa485990e41800c75082ec);
  justify-content: var(--horizontalAxis-416175c480aa485990e41800c75082ec);
}
@media (max-width: 480px) {
  .Nosotros {
    --eec0666785694219a877da91dfa34558_visible: flex;
    --8fc0dbb345d3493cbb9a5fc38c11383a_visible: none;
    --fbeb0b5af3e74b008ff14bf9fcc32aa3_visible: none;
    --416175c480aa485990e41800c75082ec_orientation: column;
    --416175c480aa485990e41800c75082ec_horizontalAlignment: center;
    --min-nosotrossection2_valueheight: unset;
    --nosotrossection2_valueheight: fit-content;
  }

  .layer-464-416175c480aa485990e41800c75082ec-orientation {
    align-items: var(--horizontalAxis-416175c480aa485990e41800c75082ec);
    justify-content: var(--verticalAxis-416175c480aa485990e41800c75082ec);
  }
}
@media (min-width: 1020px) {
  .Nosotros {
    --eec0666785694219a877da91dfa34558_visible: none;
    --8fc0dbb345d3493cbb9a5fc38c11383a_visible: flex;
    --fbeb0b5af3e74b008ff14bf9fcc32aa3_visible: flex;
    --416175c480aa485990e41800c75082ec_orientation: row;
    --416175c480aa485990e41800c75082ec_horizontalAlignment: space-between;
    --min-nosotrossection2_valueheight: 60vh;
    --nosotrossection2_valueheight: 60vh;
  }

  .layer-464-416175c480aa485990e41800c75082ec-orientation {
    align-items: var(--verticalAxis-416175c480aa485990e41800c75082ec);
    justify-content: var(--horizontalAxis-416175c480aa485990e41800c75082ec);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Nosotros {
    --eec0666785694219a877da91dfa34558_visible: flex;
    --8fc0dbb345d3493cbb9a5fc38c11383a_visible: none;
    --fbeb0b5af3e74b008ff14bf9fcc32aa3_visible: none;
    --416175c480aa485990e41800c75082ec_orientation: column;
    --416175c480aa485990e41800c75082ec_horizontalAlignment: center;
    --min-nosotrossection2_valueheight: unset;
    --nosotrossection2_valueheight: fit-content;
  }

  .layer-464-416175c480aa485990e41800c75082ec-orientation {
    align-items: var(--horizontalAxis-416175c480aa485990e41800c75082ec);
    justify-content: var(--verticalAxis-416175c480aa485990e41800c75082ec);
  }
}
