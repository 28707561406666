.home_Home__1nYqf {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.home_homeheader__1uIV3 {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-right: var(--HeaderPaddingH);
  padding-left: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.home_layer-2-3d08f9ba4b6d44e38b52a6eea671db40__3XajM {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.home_bavieralogo__3Ruiw {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.home_layer-3-5e6ce39d6d51493a8669ba30f486bc31__TPL_V {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--5e6ce39d6d51493a8669ba30f486bc31_visible);
}
.home_text-2-61d7d9db557943988ee7cbf6c7a87f19__3UqOA {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.home_text-3-5a304adcd3564e858c39be3eddb21239__1X-i9 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.home_text-4-8fcc6e377d604e30a70030835dc3dfa1__26Rgb {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.home_text-5-af6cbc7d5d3d4c83b1261c529fc185b7__1NoVd {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.home_layer-4-753cd528892f4768a0ac5e207115f7a6__2_H2M {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--753cd528892f4768a0ac5e207115f7a6_visible);
}
.home_layer-6-e5e47c53dbc444899e1ace36ef78a685__BVLpN {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
}
.home_text-1-55678b833689471cab623acf2f26ea0d__2_xF3 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.home_layer-5-11f9ece7ab4b46a88674e64e8c7a7ce2___Prk2 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--11f9ece7ab4b46a88674e64e8c7a7ce2_visible);
}
.home_icon-1-c7c1bcc9531d4248a041d32a759fcd23__2IS0c {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.home_homesection1__2gbGt {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/High-light Towers Munich.png");
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.home_layer-461-2e55c1429e5b47f39764cbab51cf03d5__1ZmJY {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.home_text-73-be9b44557e13492faadcc957037911c3__25wlz {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.home_text-74-6eec02498f594789be456d4430358849__2A4cw {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
  margin-left: var(--ItemMargin1H);
  margin-right: var(--ItemMargin1H);
}
.home_text-75-35e2d7494ef24839ba839307d19d9b4a__3VPL2 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--ParagraphTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.home_layer-88-2d54f9ce2a4141bcb83086f6a796c529__1gE8x {
  border-radius: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
}
.home_text-76-dfb9d1c386e04375b44eb1f148694a46__1rzW1 {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.home_homesection2__BExCx {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--PrimaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-homesection2_valueheight);
  height: var(--homesection2_valueheight);
}
.home_layer-460-41f4ea6174ea4e2cbdf539db1c80ff23__HTCWJ {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
}
.home_text-412-7d170979998248e29e5d343ef7fa5da3__z2Co4 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.home_layer-10-b5b2031d9d95455da1ee29c59104c389__2VqBC {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--b5b2031d9d95455da1ee29c59104c389_orientation);
  --horizontalAxis-b5b2031d9d95455da1ee29c59104c389: var(
    --b5b2031d9d95455da1ee29c59104c389_horizontalAlignment
  );
}
.home_layer-12-617745cc5f5a439fafbe093b5d77b90b__1G2ZP {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
}
.home_layer-547-9f0d99d76dbe4ad880577e7253117e71__2C4F5 {
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.home_imageview-198-09246f266ec04f5d94553b60f47a0e9c__fCLSu {
  min-width: var(--min-ImageSize4);
  width: var(--ImageSize4);
  min-height: var(--min-ImageSize4);
  height: var(--ImageSize4);
}
.home_text-7-a7ac179f78fc4b6194c754482f8ba3a0__1MG7a {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.home_layer-13-375e0f56ba91470dbf8498e41aa04564__1CKtl {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
}
.home_layer-548-56e92fe039794446a2535372370edabf__2LI8u {
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.home_imageview-199-8235e661781e47aab37b57a78772b172__T2Gjh {
  min-height: var(--min-ImageSize4);
  height: var(--ImageSize4);
  min-width: var(--min-ImageSize4);
  width: var(--ImageSize4);
}
.home_text-8-e17bf5425694494a919c8a9185b3b203__1GyYb {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.home_layer-14-677393b17ba0472a9774252111d7e1ae__3hep7 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
}
.home_layer-549-9fe93dd87a814651aaec4a8f866ddacc__3G9A8 {
  border-radius: 10px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.home_imageview-200-943d490e929e49d18002877202cd039e__27Pau {
  min-height: var(--min-ImageSize4);
  height: var(--ImageSize4);
  min-width: var(--min-ImageSize4);
  width: var(--ImageSize4);
}
.home_text-9-f7e726d5d02545c2821fd206d8dd11af__266tt {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.home_layer-15-d9bb8bd34c7d466daa9000ffa20449ee__1a9K2 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  min-width: var(--min-d9bb8bd34c7d466daa9000ffa20449ee_valuewidth);
  width: var(--d9bb8bd34c7d466daa9000ffa20449ee_valuewidth);
}
.home_layer-550-736b9152a4db43708377401c5b4611a9__33hxd {
  border-radius: 10px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.home_imageview-201-1afee32b0e7d4cadbf0207185760f4f7__2aQjM {
  min-height: var(--min-ImageSize4);
  height: var(--ImageSize4);
  min-width: var(--min-ImageSize4);
  width: var(--ImageSize4);
}
.home_text-10-b5f74ed610464260a65c68fc529c7d7d__2L5uv {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.home_layer-16-02c9afd51dc940ac8cc71d1ecda2c342__1VeuC {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  min-width: var(--min-02c9afd51dc940ac8cc71d1ecda2c342_valuewidth);
  width: var(--02c9afd51dc940ac8cc71d1ecda2c342_valuewidth);
}
.home_layer-551-f9e26648344b491d8b41aca519c1fc30__2v6bS {
  border-radius: 10px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.home_imageview-202-8029b3f9f7f5493aa13a9028379a3d48__2zm73 {
  min-width: var(--min-ImageSize4);
  width: var(--ImageSize4);
  min-height: var(--min-ImageSize4);
  height: var(--ImageSize4);
}
.home_text-11-54678da62af048c9a48c289ce046d471__14WXk {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.home_homesection3__1B9cH {
  --horizontalAxis-homesection3: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  flex-direction: var(--homesection3_orientation);
  min-height: var(--min-homesection3_valueheight);
  height: var(--homesection3_valueheight);
}
.home_layer-463-fd5f7689f3de4b8caf6cc004c7ac10e5__165tm {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
}
.home_text-413-8907e6bd3afd4633892247b99a4114ad__cScKd {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize2);
}
.home_layer-462-005da6786f074e03b60ff6ce70a6093e__2TI3R {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--005da6786f074e03b60ff6ce70a6093e_orientation);
  --horizontalAxis-005da6786f074e03b60ff6ce70a6093e: var(
    --005da6786f074e03b60ff6ce70a6093e_horizontalAlignment
  );
}
.home_layer-47-b340f6e15b6243878f7770afc59489b3__1cSNJ {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  margin-right: var(--ItemMargin2H);
  margin-left: var(--ItemMargin2H);
}
.home_layer-905-a11a01210c7e47aaa9aa9383c96bbbc5__2o3vs {
  border-radius: 10px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-284-7ec07b741a8d490eaaebe57ab0cc478e__8j5rm {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.home_text-30-d2ca6f1e7c294abf98da5c5e4d58ce5d__1IRcX {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.home_layer-51-79e347ccf3104a498b25e704559487e3__2dEpz {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.home_layer-909-971eb83f20734f30b38a476b1dab4610__jFjej {
  border-radius: 10px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-288-999a499f799b4216abc09ec1bc8bcfa1__1I_WR {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.home_text-33-22a2d02845bb4d0897a61ba5d7bbf191__V2D0A {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.home_layer-49-eed115637b6d48c894db29338fc01e4d__3MEhv {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  margin-right: var(--ItemMargin2H);
  margin-left: var(--ItemMargin2H);
}
.home_layer-907-3f6aea514aa2478d96c146c5528a006d__31N1- {
  border-radius: 10px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-286-36f8e54109804ee297de89c828cf29ac__11Kc7 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.home_text-31-4ef663560d984ff2b64b9441063997be__2jSbf {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.home_layer-911-0c007086943c4da381594c0dd9140c1d__GDt_y {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.home_layer-915-7de783995fe5403ebc5190e896b84761__3PY0Y {
  border-radius: 10px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-290-636a2808d7fb4118a2721946df42a87c__t-nbA {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.home_text-737-4a23114c1d434b88badd0d96318ced17__2eSAQ {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.home_layer-910-09075171494d4fe8b95fde1bd80e72a6__3fAfx {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.home_layer-914-d545e576c9ff4b73bf29e271c41b830d__XWHkO {
  border-radius: 10px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-289-34d8b82f1bbb4bca976c3af4d5bcc069__4oJDu {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.home_text-736-559bcbe524704bf0b39b1b6ca64ed92f__3YJYT {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.home_layer-50-c8596553f8084f7c8d7c33984f3eb2ca__STawZ {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  margin-right: var(--ItemMargin2H);
  margin-left: var(--ItemMargin2H);
}
.home_layer-908-4c9e25658e69423c89d6a2f97dd251ee__Kuzz9 {
  border-radius: 10px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-287-8bda81d1be7e47aead79e20fee3003c0__3MI9r {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.home_text-32-c0ff9bd68fda4420ac2b649434213f28__GjlQj {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.home_layer-529-c35806fb7cc344e6acee5becb851237b__3yPSt {
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.home_layer-533-c77b6d3ee4c940b096b27242f7581cd9__3dOnz {
  border-radius: 10px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-172-4c356a1dfb594e21b4553de568e3b6ff__7SVCK {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.home_text-420-bce4d0ccbcdd4202ae7488794c57fcfe__3KWey {
  font-weight: bold;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.home_layer-913-99ba4ff0683c459ab446e25e3a31329d__1jLNe {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.home_layer-917-3c59f69d8ce4473090fa10f4ca006e6a__abwY7 {
  border-radius: 10px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-292-3c0de2a151d546608a30bc899edb6cb4__2GCDi {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.home_text-739-0bd843d194c74a15981f438e1f05e10e__27pIi {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.home_layer-48-3249c65d8d004ca4bbf6b5b42410ed38__33Typ {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  margin-right: var(--ItemMargin2H);
  margin-left: var(--ItemMargin2H);
}
.home_layer-906-14e159096b18422f8980f85e41a20caf__15aib {
  border-radius: 10px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-285-13c68cddd5f54222a46dc5564a563603__2LP11 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.home_text-29-99e8482831ae4fe0876b5208d61d2e80__2KALX {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.home_layer-912-5a38091f55d24642a9331d332b154b07__2Do9Z {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.home_layer-916-c6527fd139834091a97927affb593888__8_nTm {
  border-radius: 10px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-291-a09853d6e4ad448b95b7b83b226ed3c7__1qZGc {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.home_text-738-a4a73fe769cc475eb064a7273c3c5b36__3_HKL {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.home_layer-530-22a115fd7b4747e6a94b58701a808a80__2ORdv {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.home_layer-532-6a6c0aa65da54989aa6b65cda366f7d1__2oLB_ {
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-173-62b0a8e116dc4a42a52fd21a9061023c__3JvLZ {
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
}
.home_text-421-da9bd6f8f03a4932940baf60b8f3b3af__q6d-C {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.home_homesection4__3TZXb {
  --verticalAxis-homesection4: center;
  --horizontalAxis-homesection4: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  flex-direction: var(--homesection4_orientation);
  min-height: var(--min-homesection4_valueheight);
  height: var(--homesection4_valueheight);
}
.home_layer-465-0e48bb8d134647028876f5c8cde732eb__2EsiK {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
}
.home_text-414-d4172df906634e6d986018dbab71f624__2Zh44 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize2);
}
.home_layer-500-70b50d7b57e44903ba3f11d67d038022__WUcNq {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--70b50d7b57e44903ba3f11d67d038022_orientation);
  --horizontalAxis-70b50d7b57e44903ba3f11d67d038022: var(
    --70b50d7b57e44903ba3f11d67d038022_horizontalAlignment
  );
  --verticalAxis-70b50d7b57e44903ba3f11d67d038022: var(
    --70b50d7b57e44903ba3f11d67d038022_verticalAlignment
  );
}
.home_layer-572-4d457ed652c44e91a41ef6198816c00f__2e0uB {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 250px;
  min-width: 250px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
}
.home_layer-464-afa2a5cde3864fe88ba599abc6067850__U1I8c {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 1px 10px #e0e0e0;
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-164-3854a3065ac34686863a563a3d3741ff__iUNzs {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.home_layer-573-f2a163794c2f424a89a2e31635df2100__2DLNr {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.home_text-34-d1c8c28dc3dd4ee48b3102715f049ab4__3KyCE {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.home_text-35-ac987fe3aedf4e0a82603430b1d2ea48__3zUM9 {
  text-align: center;

  width: 100px;
  min-width: 100px;
  font-family: var(--FontMedium);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.home_text-38-62dbffd0adbc4123ae7d512d71e34ca3__1ib6n {
  text-align: center;

  margin-left: 15px;
  box-sizing: border-box;

  min-width: unset;
  width: calc(100% - calc(15px + 0px));
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.home_layer-54-0978b6d59d64488db55e7c5c8215035a__22xyA {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  min-width: var(--min-0978b6d59d64488db55e7c5c8215035a_valuewidth);
  width: var(--0978b6d59d64488db55e7c5c8215035a_valuewidth);
}
.home_layer-545-13de03b1d2f94b559674539a1bca3202__3weVP {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-196-2a970db8d9c44706806f2711bc2fbb63__2xh6D {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.home_text-40-8c2b570dedda4e39bfe5a990ff9f5b94__1E-T0 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.home_text-41-340af16347db4b00b99909e19981ace7__wqur4 {
  text-align: center;

  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.home_text-42-a6e3d9fa016f4a81b8ca897e4a7f34b2__2oeeF {
  text-align: center;

  margin-left: 15px;
  box-sizing: border-box;

  min-width: unset;
  width: calc(100% - calc(15px + 0px));
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.home_layer-53-0508c285e0364557aa843a3003f85a12__2lHyI {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  min-width: var(--min-0508c285e0364557aa843a3003f85a12_valuewidth);
  width: var(--0508c285e0364557aa843a3003f85a12_valuewidth);
}
.home_layer-546-070167c8d5ef4c6d91542a7e0dcadfe2__208yi {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin2V);
}
.home_imageview-197-6606eeeb479d45b1b0047eee77339248__3fsrB {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.home_text-36-894be80911dd4819ad1ba7f50c98f8fc__torSV {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.home_text-37-5c162c7952b14e509f8bff8ed5f2283b__2K5un {
  text-align: center;

  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.home_text-39-1de9c3c58cf6404c82353a08d7125c29__2ESvP {
  text-align: center;

  margin-left: 15px;
  box-sizing: border-box;

  min-width: unset;
  width: calc(100% - calc(15px + 0px));
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.home_homesection5__18n93 {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--PrimaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-homesection5_valueheight);
  height: var(--homesection5_valueheight);
}
.home_layer-464-e44e86c9a03a44c598b7967f7fb0ee8c__2BdaR {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
}
.home_text-412-f65b8e716df84f809599a24c660e98f4__3inzZ {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.home_layer-3108-feca6ee1f9534104ac857129a21a7d1f__2O_6H {
  min-height: unset;
  height: calc(100% - calc(var(--ItemMargin1V) + var(--ItemMargin1V)));

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
}
.home_homefooter__2o88U {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.home_layer-465-ae4a5b0dbc9e4006bc0572d4154f66c7__1CbCh {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
  align-items: var(--ae4a5b0dbc9e4006bc0572d4154f66c7_horizontalAlignment);
  justify-content: var(--ae4a5b0dbc9e4006bc0572d4154f66c7_verticalAlignment);
}
.home_layer-481-41ee69d9b1374da9ae51cd716a8e6dfe__2lw83 {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.home_imageview-164-00d2003f8801484abf7064d16b4c4f1b__2379q {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.home_imageview-165-47bd3700c7a3465e9acfd5860ff7c506__3ufrD {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.home_imageview-166-ed46fa2d855b4bc3bf6aace80db974a2__3dysD {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.home_imageview-167-d37a205ae13644a7970ad3d8d26561a7__1p5KW {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.home_layer-467-df3802d767a345d4bca59e0b3f7f49ca__1ZlgW {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.home_text-43-3b6d765ee7624927a76a7302e1e10daa__1lby4 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.home_text-106-81287af6f601496ab2ed4c44b05488d6__3ufsl {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.home_layer-10-b5b2031d9d95455da1ee29c59104c389-orientation__1jkfY {
  align-items: var(--horizontalAxis-b5b2031d9d95455da1ee29c59104c389);
  justify-content: var(--verticalAxis-b5b2031d9d95455da1ee29c59104c389);
}
.home_homesection3-orientation__1Cca7 {
  align-items: var(--horizontalAxis-homesection3);
  justify-content: var(--verticalAxis-homesection3);
}
.home_layer-462-005da6786f074e03b60ff6ce70a6093e-orientation__3Mjp- {
  align-items: var(--horizontalAxis-005da6786f074e03b60ff6ce70a6093e);
  justify-content: var(--verticalAxis-005da6786f074e03b60ff6ce70a6093e);
}
.home_homesection4-orientation__GBz4m {
  align-items: var(--horizontalAxis-homesection4);
  justify-content: var(--verticalAxis-homesection4);
}
.home_layer-500-70b50d7b57e44903ba3f11d67d038022-orientation__1X6eR {
  align-items: var(--horizontalAxis-70b50d7b57e44903ba3f11d67d038022);
  justify-content: var(--verticalAxis-70b50d7b57e44903ba3f11d67d038022);
}
@media (max-width: 480px) {
  .home_Home__1nYqf {
    --5e6ce39d6d51493a8669ba30f486bc31_visible: none;
    --753cd528892f4768a0ac5e207115f7a6_visible: none;
    --11f9ece7ab4b46a88674e64e8c7a7ce2_visible: flex;
    --b5b2031d9d95455da1ee29c59104c389_orientation: column;
    --homesection3_orientation: column;
    --homesection4_orientation: column;
    --b5b2031d9d95455da1ee29c59104c389_horizontalAlignment: center;
    --min-homesection2_valueheight: unset;
    --homesection2_valueheight: fit-content;
    --min-d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: unset;
    --d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: fit-content;
    --min-02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: unset;
    --02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: fit-content;
    --min-homesection3_valueheight: unset;
    --homesection3_valueheight: fit-content;
    --min-homesection4_valueheight: unset;
    --homesection4_valueheight: fit-content;
    --70b50d7b57e44903ba3f11d67d038022_orientation: column;
    --70b50d7b57e44903ba3f11d67d038022_horizontalAlignment: center;
    --70b50d7b57e44903ba3f11d67d038022_verticalAlignment: center;
    --min-0978b6d59d64488db55e7c5c8215035a_valuewidth: unset;
    --0978b6d59d64488db55e7c5c8215035a_valuewidth: 100%;
    --min-0508c285e0364557aa843a3003f85a12_valuewidth: unset;
    --0508c285e0364557aa843a3003f85a12_valuewidth: 100%;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_horizontalAlignment: center;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_verticalAlignment: center;
    --005da6786f074e03b60ff6ce70a6093e_orientation: column;
    --005da6786f074e03b60ff6ce70a6093e_horizontalAlignment: center;
    --min-homesection5_valueheight: 75vh;
    --homesection5_valueheight: 75vh;
  }

  .home_layer-10-b5b2031d9d95455da1ee29c59104c389-orientation__1jkfY {
    align-items: var(--horizontalAxis-b5b2031d9d95455da1ee29c59104c389);
    justify-content: var(--verticalAxis-b5b2031d9d95455da1ee29c59104c389);
  }
  .home_homesection3-orientation__1Cca7 {
    align-items: var(--horizontalAxis-homesection3);
    justify-content: var(--verticalAxis-homesection3);
  }
  .home_layer-462-005da6786f074e03b60ff6ce70a6093e-orientation__3Mjp- {
    align-items: var(--horizontalAxis-005da6786f074e03b60ff6ce70a6093e);
    justify-content: var(--verticalAxis-005da6786f074e03b60ff6ce70a6093e);
  }
  .home_homesection4-orientation__GBz4m {
    align-items: var(--horizontalAxis-homesection4);
    justify-content: var(--verticalAxis-homesection4);
  }
  .home_layer-500-70b50d7b57e44903ba3f11d67d038022-orientation__1X6eR {
    align-items: var(--horizontalAxis-70b50d7b57e44903ba3f11d67d038022);
    justify-content: var(--verticalAxis-70b50d7b57e44903ba3f11d67d038022);
  }
  .home_layer-54-0978b6d59d64488db55e7c5c8215035a__22xyA {
    width: var(--0978b6d59d64488db55e7c5c8215035a_valuewidth);
  }
  .home_layer-53-0508c285e0364557aa843a3003f85a12__2lHyI {
    width: var(--0508c285e0364557aa843a3003f85a12_valuewidth);
  }
}
@media (min-width: 1020px) {
  .home_Home__1nYqf {
    --5e6ce39d6d51493a8669ba30f486bc31_visible: flex;
    --753cd528892f4768a0ac5e207115f7a6_visible: flex;
    --11f9ece7ab4b46a88674e64e8c7a7ce2_visible: none;
    --b5b2031d9d95455da1ee29c59104c389_orientation: row;
    --homesection3_orientation: column;
    --homesection4_orientation: column;
    --b5b2031d9d95455da1ee29c59104c389_horizontalAlignment: space-between;
    --min-homesection2_valueheight: unset;
    --homesection2_valueheight: fit-content;
    --min-d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: unset;
    --d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: fit-content;
    --min-02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: unset;
    --02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: fit-content;
    --min-homesection3_valueheight: unset;
    --homesection3_valueheight: fit-content;
    --min-homesection4_valueheight: unset;
    --homesection4_valueheight: fit-content;
    --70b50d7b57e44903ba3f11d67d038022_orientation: row;
    --70b50d7b57e44903ba3f11d67d038022_horizontalAlignment: space-between;
    --70b50d7b57e44903ba3f11d67d038022_verticalAlignment: flex-start;
    --min-0978b6d59d64488db55e7c5c8215035a_valuewidth: 250px;
    --0978b6d59d64488db55e7c5c8215035a_valuewidth: 250px;
    --min-0508c285e0364557aa843a3003f85a12_valuewidth: 250px;
    --0508c285e0364557aa843a3003f85a12_valuewidth: 250px;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_horizontalAlignment: center;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_verticalAlignment: center;
    --005da6786f074e03b60ff6ce70a6093e_orientation: row;
    --005da6786f074e03b60ff6ce70a6093e_horizontalAlignment: flex-start;
    --min-homesection5_valueheight: 90vh;
    --homesection5_valueheight: 90vh;
  }

  .home_layer-10-b5b2031d9d95455da1ee29c59104c389-orientation__1jkfY {
    align-items: var(--verticalAxis-b5b2031d9d95455da1ee29c59104c389);
    justify-content: var(--horizontalAxis-b5b2031d9d95455da1ee29c59104c389);
  }
  .home_homesection3-orientation__1Cca7 {
    align-items: var(--horizontalAxis-homesection3);
    justify-content: var(--verticalAxis-homesection3);
  }
  .home_layer-462-005da6786f074e03b60ff6ce70a6093e-orientation__3Mjp- {
    align-items: var(--verticalAxis-005da6786f074e03b60ff6ce70a6093e);
    justify-content: var(--horizontalAxis-005da6786f074e03b60ff6ce70a6093e);
  }
  .home_homesection4-orientation__GBz4m {
    align-items: var(--horizontalAxis-homesection4);
    justify-content: var(--verticalAxis-homesection4);
  }
  .home_layer-500-70b50d7b57e44903ba3f11d67d038022-orientation__1X6eR {
    align-items: var(--verticalAxis-70b50d7b57e44903ba3f11d67d038022);
    justify-content: var(--horizontalAxis-70b50d7b57e44903ba3f11d67d038022);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .home_Home__1nYqf {
    --5e6ce39d6d51493a8669ba30f486bc31_visible: none;
    --753cd528892f4768a0ac5e207115f7a6_visible: none;
    --11f9ece7ab4b46a88674e64e8c7a7ce2_visible: flex;
    --b5b2031d9d95455da1ee29c59104c389_orientation: column;
    --homesection3_orientation: column;
    --homesection4_orientation: column;
    --b5b2031d9d95455da1ee29c59104c389_horizontalAlignment: center;
    --min-homesection2_valueheight: unset;
    --homesection2_valueheight: fit-content;
    --min-d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: unset;
    --d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: fit-content;
    --min-02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: unset;
    --02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: fit-content;
    --min-homesection3_valueheight: unset;
    --homesection3_valueheight: fit-content;
    --min-homesection4_valueheight: unset;
    --homesection4_valueheight: fit-content;
    --70b50d7b57e44903ba3f11d67d038022_orientation: column;
    --70b50d7b57e44903ba3f11d67d038022_horizontalAlignment: center;
    --70b50d7b57e44903ba3f11d67d038022_verticalAlignment: flex-start;
    --min-0978b6d59d64488db55e7c5c8215035a_valuewidth: 290px;
    --0978b6d59d64488db55e7c5c8215035a_valuewidth: 290px;
    --min-0508c285e0364557aa843a3003f85a12_valuewidth: 290px;
    --0508c285e0364557aa843a3003f85a12_valuewidth: 290px;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_horizontalAlignment: center;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_verticalAlignment: center;
    --005da6786f074e03b60ff6ce70a6093e_orientation: column;
    --005da6786f074e03b60ff6ce70a6093e_horizontalAlignment: center;
    --min-homesection5_valueheight: 90vh;
    --homesection5_valueheight: 90vh;
  }

  .home_layer-10-b5b2031d9d95455da1ee29c59104c389-orientation__1jkfY {
    align-items: var(--horizontalAxis-b5b2031d9d95455da1ee29c59104c389);
    justify-content: var(--verticalAxis-b5b2031d9d95455da1ee29c59104c389);
  }
  .home_homesection3-orientation__1Cca7 {
    align-items: var(--horizontalAxis-homesection3);
    justify-content: var(--verticalAxis-homesection3);
  }
  .home_layer-462-005da6786f074e03b60ff6ce70a6093e-orientation__3Mjp- {
    align-items: var(--horizontalAxis-005da6786f074e03b60ff6ce70a6093e);
    justify-content: var(--verticalAxis-005da6786f074e03b60ff6ce70a6093e);
  }
  .home_homesection4-orientation__GBz4m {
    align-items: var(--horizontalAxis-homesection4);
    justify-content: var(--verticalAxis-homesection4);
  }
  .home_layer-500-70b50d7b57e44903ba3f11d67d038022-orientation__1X6eR {
    align-items: var(--horizontalAxis-70b50d7b57e44903ba3f11d67d038022);
    justify-content: var(--verticalAxis-70b50d7b57e44903ba3f11d67d038022);
  }
}

.mobilemenu_Mobilemenu__29nlv {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.mobilemenu_mobilemenuheader__3uuLs {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.mobilemenu_layer-70-877b56a3b9a04fadb5465069d3263fd2__32k2F {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.mobilemenu_imageview-202-7184976d1c664e8dabfcbfe6cf10a1f0__3p0AD {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.mobilemenu_layer-71-88da2b854d2f4e57bac0120f564dab4e__5YtSs {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.mobilemenu_icon-3-7bd413645027488dbbc8ee6093395b0f__1-l75 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.mobilemenu_mobilemenusection1__2CIpp {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/High-light Towers Munich.png");
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.mobilemenu_mobilemenusections__3fav8 {
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  height: 50vh;
  min-height: 50vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.mobilemenu_layer-72-3f049c0123b541d8a4f7e310332cfd98__LC-5J {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.mobilemenu_text-59-88b328d2847c43d6a918ee18b36030cc__1GiZh {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin2V);
}
.mobilemenu_text-60-19f7f8b675284670b6c30d2a8d2fcf41__EgD7v {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin2V);
}
.mobilemenu_text-61-1f11c897ae804526a9f6554f1dcfbaf3__15Lz2 {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin2V);
}
.mobilemenu_text-62-2ed4b81989d941688f6a5dbc7dc0f8bc__2rTbU {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin2V);
}
.mobilemenu_layer-74-49f97056fd894b4d84460b167ae61355__1wbJ1 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
}
.mobilemenu_text-63-26ef4549af7748be9fd7ac1e017f384e__wwc7M {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}

.nosotros_Nosotros__13NFe {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.nosotros_nosotrosheader__3ZQzV {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.nosotros_layer-190-7275e2d17eec41bab67ef9b42c21db93__3STXh {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.nosotros_imageview-185-abdc99b8c6af4650a6aaffb104266141__3f9s2 {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.nosotros_layer-191-fbeb0b5af3e74b008ff14bf9fcc32aa3__1qH0s {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--fbeb0b5af3e74b008ff14bf9fcc32aa3_visible);
}
.nosotros_text-168-7efe370e236b42bda831d553611dd2f7__15l5b {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.nosotros_text-169-a0a6c0fa86f44722a5d1ae8e8488b920__1yTsG {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.nosotros_text-170-3999ae8144cd4b7abea16cd8e2958d5b__2tsR- {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.nosotros_text-171-d6e1ee4690a245eebe1e2e83a11a3394__QyjFW {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.nosotros_layer-192-8fc0dbb345d3493cbb9a5fc38c11383a__1E_PJ {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--8fc0dbb345d3493cbb9a5fc38c11383a_visible);
}
.nosotros_text-172-84e58c0ab1e14e6e95f7f3c2bcb357c1__t3aRs {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.nosotros_layer-193-eec0666785694219a877da91dfa34558__1LIj_ {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--eec0666785694219a877da91dfa34558_visible);
}
.nosotros_icon-7-65ad13bb97d0486e8f38a20ceea6b072__2IsEF {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.nosotros_nosotrossection1__3eCl7 {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Nosotros.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.nosotros_text-426-bb6fa25c8b9b43cf8e3335c309540633__rmyr2 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.nosotros_nosotrossection2__1NDpN {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-nosotrossection2_valueheight);
  height: var(--nosotrossection2_valueheight);
}
.nosotros_layer-464-416175c480aa485990e41800c75082ec__1GCXf {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--416175c480aa485990e41800c75082ec_orientation);
  --horizontalAxis-416175c480aa485990e41800c75082ec: var(
    --416175c480aa485990e41800c75082ec_horizontalAlignment
  );
}
.nosotros_layer-187-6bcbaee1c9d5475a8ede36e24ba7c695__2M0mS {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
}
.nosotros_layer-592-3fce799a69994a46b0fcd13bbe9fd8cd__3QJVK {
  border-radius: 15px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.nosotros_imageview-229-9658179f1616425f98a18a4b2fd6a034__3K3nG {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.nosotros_text-160-47a2c8aa7e034a2d906376735707daf6__JjTgi {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.nosotros_text-161-4fd3fe7b7d9f4ae9a6004ecc5f300454__3uQ_x {
  text-align: center;

  width: 250px;
  min-width: 250px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.nosotros_layer-188-153233a9e0f24dec8d8f5799e69a6af1__10rsJ {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
}
.nosotros_layer-593-c8a86431bf5942f2952420c34f68a016__2OOVm {
  border-radius: 15px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.nosotros_imageview-230-70c2857d9bb04d30ac044b3f0b83ba99__38QKZ {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.nosotros_text-162-74602e861547418c8fd00bd5f447801c__EueZR {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.nosotros_text-163-03b995b4abf34932bb4d0cd51615e96e__16yPn {
  text-align: center;

  width: 250px;
  min-width: 250px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.nosotros_layer-189-399dddfa2daf4cfebb25eb022b0df987__Wx8yf {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
}
.nosotros_layer-506-8bf790482fa84c70b582a5222ad8ab50__3eMan {
  border-radius: 15px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.nosotros_imageview-177-a86bc788825a4dcdb9b7de5465e0c955__1zsOI {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.nosotros_text-164-9212fdb9ae894dc6b40aaebbacf30562__BelFz {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin3V);
}
.nosotros_text-165-e673dac373dd4fa6a2e9577e49adf8b9__1w7tC {
  text-align: center;

  width: 250px;
  min-width: 250px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.nosotros_nosotrosfooter__3zseG {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.nosotros_layer-481-8ea8d47ac0df41bd974edc6a3ade41ea__19rQZ {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.nosotros_layer-95-be46962f2c7a4604a8db5697a1dccaa3__2yZDV {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.nosotros_imageview-44-b1e4a4ccad6a49058c9e8d8274ecbe00__IN87y {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.nosotros_imageview-45-b6d0f595e5fb4be9b82f14bbb5f204d4__20Mq4 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.nosotros_imageview-46-7365aa0eb1af4f7ab2276b19d4ae7a49__wgetH {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.nosotros_imageview-47-74c50e2b301245b5987c5c72b30d8ce5__32kB9 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.nosotros_layer-96-0cf1699454a84b568873220e1390f61f__1ej6C {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.nosotros_text-81-43caecdf727b4996ad85b095a8116905__25HwY {
  text-align: center;

  font-family: var(--FontBold);
  font-size: var(--FooterTextSize);
}
.nosotros_text-82-77addbf003584e37b65f343026a05ec9__1B4sD {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.nosotros_layer-464-416175c480aa485990e41800c75082ec-orientation__2mLRX {
  align-items: var(--verticalAxis-416175c480aa485990e41800c75082ec);
  justify-content: var(--horizontalAxis-416175c480aa485990e41800c75082ec);
}
@media (max-width: 480px) {
  .nosotros_Nosotros__13NFe {
    --eec0666785694219a877da91dfa34558_visible: flex;
    --8fc0dbb345d3493cbb9a5fc38c11383a_visible: none;
    --fbeb0b5af3e74b008ff14bf9fcc32aa3_visible: none;
    --416175c480aa485990e41800c75082ec_orientation: column;
    --416175c480aa485990e41800c75082ec_horizontalAlignment: center;
    --min-nosotrossection2_valueheight: unset;
    --nosotrossection2_valueheight: fit-content;
  }

  .nosotros_layer-464-416175c480aa485990e41800c75082ec-orientation__2mLRX {
    align-items: var(--horizontalAxis-416175c480aa485990e41800c75082ec);
    justify-content: var(--verticalAxis-416175c480aa485990e41800c75082ec);
  }
}
@media (min-width: 1020px) {
  .nosotros_Nosotros__13NFe {
    --eec0666785694219a877da91dfa34558_visible: none;
    --8fc0dbb345d3493cbb9a5fc38c11383a_visible: flex;
    --fbeb0b5af3e74b008ff14bf9fcc32aa3_visible: flex;
    --416175c480aa485990e41800c75082ec_orientation: row;
    --416175c480aa485990e41800c75082ec_horizontalAlignment: space-between;
    --min-nosotrossection2_valueheight: 60vh;
    --nosotrossection2_valueheight: 60vh;
  }

  .nosotros_layer-464-416175c480aa485990e41800c75082ec-orientation__2mLRX {
    align-items: var(--verticalAxis-416175c480aa485990e41800c75082ec);
    justify-content: var(--horizontalAxis-416175c480aa485990e41800c75082ec);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .nosotros_Nosotros__13NFe {
    --eec0666785694219a877da91dfa34558_visible: flex;
    --8fc0dbb345d3493cbb9a5fc38c11383a_visible: none;
    --fbeb0b5af3e74b008ff14bf9fcc32aa3_visible: none;
    --416175c480aa485990e41800c75082ec_orientation: column;
    --416175c480aa485990e41800c75082ec_horizontalAlignment: center;
    --min-nosotrossection2_valueheight: unset;
    --nosotrossection2_valueheight: fit-content;
  }

  .nosotros_layer-464-416175c480aa485990e41800c75082ec-orientation__2mLRX {
    align-items: var(--horizontalAxis-416175c480aa485990e41800c75082ec);
    justify-content: var(--verticalAxis-416175c480aa485990e41800c75082ec);
  }
}

.equipo_Equipo__2a_PZ {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.equipo_equipoheader__1MwUN {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.equipo_layer-130-e88bd466c4a64733aacf1b663338bf80__3QGeX {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.equipo_imageview-238-c5593d147b4e415096b98f570041c122__1TuSz {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.equipo_layer-131-df0931a01d5147da833f2f82d2e281a8__3vBTK {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--df0931a01d5147da833f2f82d2e281a8_visible);
}
.equipo_text-124-7ff37d8633a141e4b59cbd2aa11b1360__22HKq {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.equipo_text-125-2f9a85278af1463882fcf6efab937b4a__3cN0c {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.equipo_text-126-c87a96e5263e41ae8b5621bda905da34__ELR7- {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.equipo_text-127-ff5a492558244a8eae6bf5b22e9257b5__1-gL2 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.equipo_layer-132-44dc677b353e4c91888a8f186ad3f6f6__1F09x {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--44dc677b353e4c91888a8f186ad3f6f6_visible);
}
.equipo_text-128-6b4f740993e14878a0e7fe5376fa8e7a__2MTC4 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.equipo_layer-133-697d9d9bb40b46ada5838c25256f3581__3fEBO {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--697d9d9bb40b46ada5838c25256f3581_visible);
}
.equipo_icon-6-9b006cc311a447189c89ff552bb8c285__8Q3xu {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.equipo_equiposection1__3eKV6 {
  justify-content: center;
  align-items: center;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding: 2rem var(--ItemMargin1H);
  min-height: var(--min-equiposection1_valueheight);
  height: var(--equiposection1_valueheight);
  display: flex;
  flex-direction: column;
  grid-gap: 4rem;
  gap: 4rem;
}
.equipo_layer-119-7bc330e88c2341b88b3352beac7356a2__1sc1w {
  --verticalAxis-7bc330e88c2341b88b3352beac7356a2: center;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  flex-direction: var(--7bc330e88c2341b88b3352beac7356a2_orientation);
  --horizontalAxis-7bc330e88c2341b88b3352beac7356a2: var(
    --7bc330e88c2341b88b3352beac7356a2_horizontalAlignment
  );
}
.equipo_layer-121-cd604044518a4a50814d4eab570e7199__3wEJo {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.equipo_layer-613-97ddd4409ceb41e993a0811e30df1e67__2D5wG {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.equipo_imageview-57-6f148ea86d024bf998c0f0b26a7322d7__29U1u {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.equipo_text-108-7349b2207bd849558a7815d17bb9a99c__3uAip {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.equipo_text-109-4d9491eb9323491e802e42f0d8776423__SnFS0 {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.equipo_imageview-183-5f50fe5a5a544ec09dcb406ba063ee01__3X9c9 {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.equipo_layer-122-c86f5fa73da74a9c9d3a1f2079a97a94__3Uhlg {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.equipo_layer-614-bd467e738bb74b579d658132a2800269__BK4jZ {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.equipo_imageview-59-bdfedbb077ef48ab8e556bc6ea004fcb__2-CVV {
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
}
.equipo_text-110-29d426a2a21a462d845c9c9388f0e96b__2y8K9 {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.equipo_text-111-5e7c0ea1371d438199ec3fc9ce10477c__7EiR_ {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.equipo_imageview-60-205d069735ac44cf8853a6d3ae8e5b44__1ttT3 {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.equipo_layer-124-dfa630de779540679c45118eb31e1a07__2-14h {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.equipo_layer-615-afa9e4ba2403402a82f4343e7cfb2823__3ncMd {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.equipo_imageview-63-2e0611094b95479ab118519f321a4cb0__33Z8G {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.equipo_text-114-60a5249ad3ea4d4798f1d4d62a5ab129__3MNgF {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.equipo_text-115-10a29eb9410d48f0952633e3651cdf92__S-bEx {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.equipo_imageview-64-201a5b2a8b9c4a3394adbe33a85bf7b6__3yIhO {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.equipo_layer-123-f090316cf9a44f4ca5e5149f0da7c535__1wLl1 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin2V);
}
.equipo_layer-620-5107e29a44d1471397a8fba6c77ed97b__2HT8s {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.equipo_imageview-61-f5061a2725034ac1a6fa0e8c361d4ee3__4aYeh {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.equipo_text-112-1864002942f140869fbbd517659e6f97__u6HN2 {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.equipo_text-113-14040472f0204dca9d1e65bd0fa02132__3rLc2 {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.equipo_imageview-62-7b705905361947d6822d1d1d834e498a__35l4a {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.equipo_layer-120-686902dff360450ea740c2e4277b068b__-kMdz {
  --verticalAxis-686902dff360450ea740c2e4277b068b: center;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  flex-direction: var(--686902dff360450ea740c2e4277b068b_orientation);
  --horizontalAxis-686902dff360450ea740c2e4277b068b: var(
    --686902dff360450ea740c2e4277b068b_horizontalAlignment
  );
  padding: 0;
}
.equipo_layer-125-d31b0b64c59f4e3fbed5c1d60797cbf7__Tg70d {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.equipo_layer-617-390b98faf31f41fdaffddc26b3416c44__1fmS5 {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.equipo_imageview-65-504f17405fd4460ca6ddd02269cf7149__24h2u {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.equipo_text-116-f1b00d4a725a4bd0854526d804f44a28__1P0l_ {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.equipo_text-117-2704d262e1f34476abbccf22ebca6910__1yZeY {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.equipo_imageview-66-64bc6664425c4f36a1ab77cf5a5c3f4a__RUJ3W {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.equipo_layer-126-b949a8af2d26485b83198f98045011a0__1Bv0c {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.equipo_layer-618-f7332bd51d514f288ac797124953192c__1rMvY {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.equipo_imageview-67-20f417fc569d4aa297bf8ea9eb1b54ad__nmmpB {
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
}
.equipo_text-118-25a55263ea1b441ea5e3687a3ee0e745__1BSaH {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.equipo_text-119-ef5e4b1f28ac4b438e79df59c2877ad5__75D8s {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.equipo_imageview-68-959e427782be489e8e46a9843581e00e__3fAdw {
  cursor: pointer;
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
}
.equipo_layer-127-9da32d77ccef448bbe96945f508b799d__1mBCr {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.equipo_layer-619-295c4dbf786b4c589e3759d1344cac70__210II {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.equipo_imageview-69-68fff1e56cb04bbf88dfe9f3cb642561__l12bf {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.equipo_text-120-5f047a0f154e453992fe275d0c8bf839__2IEek {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.equipo_text-121-d20b53dc6b2a4722bdf6b7440a2a1eba__3PvmL {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.equipo_imageview-70-a6a7d6b4f6984b91a51d8978cd6cfa02__1HewR {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.equipo_equipofooter__3zOJg {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.equipo_layer-500-8f3575fe37ad486486476de31da2fb0f__ZzbxU {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.equipo_layer-128-c6697fd6d2c44327a96e8bb3a45e11a9__kW3I9 {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.equipo_imageview-71-21ae404e7dc9498193fe1a6eca7b3fda__sJ0Kz {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.equipo_imageview-72-f81a1dc4ce5f43d3bcd7038fb8b62db4__2DFM0 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.equipo_imageview-73-d6b829ffb04b4f528e8dec9626df5839__2XQ2P {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.equipo_imageview-74-a42e629dbc7e4c7daa62f1af292425c1__1NT89 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.equipo_layer-129-57922db7ecfe4b1994e8884c0f9297cf__3W9EK {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.equipo_text-122-6fee9b6acd1d4a3a9a4c62b941344745__NaBxO {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.equipo_text-123-a086dfb462094a5499f8e5913f53817c__35nnD {
  font-family: var(--FontRegular);
  font-size: var(--FooterTextSize);
}

.equipo_layer-119-7bc330e88c2341b88b3352beac7356a2-orientation__wiwdD {
  align-items: var(--verticalAxis-7bc330e88c2341b88b3352beac7356a2);
  justify-content: var(--horizontalAxis-7bc330e88c2341b88b3352beac7356a2);
}
.equipo_layer-120-686902dff360450ea740c2e4277b068b-orientation__1mw8F {
  align-items: var(--verticalAxis-686902dff360450ea740c2e4277b068b);
  justify-content: var(--horizontalAxis-686902dff360450ea740c2e4277b068b);
}
@media (max-width: 480px) {
  .equipo_Equipo__2a_PZ {
    --7bc330e88c2341b88b3352beac7356a2_orientation: column;
    --686902dff360450ea740c2e4277b068b_orientation: column;
    --697d9d9bb40b46ada5838c25256f3581_visible: flex;
    --44dc677b353e4c91888a8f186ad3f6f6_visible: none;
    --df0931a01d5147da833f2f82d2e281a8_visible: none;
    --min-equiposection1_valueheight: unset;
    --equiposection1_valueheight: fit-content;
    --7bc330e88c2341b88b3352beac7356a2_horizontalAlignment: center;
    --686902dff360450ea740c2e4277b068b_horizontalAlignment: center;
  }
  
  .equipo_layer-119-7bc330e88c2341b88b3352beac7356a2__1sc1w,
  .equipo_layer-120-686902dff360450ea740c2e4277b068b__-kMdz {
    grid-gap: 3rem;
    gap: 3rem;
  }
}
@media (min-width: 1020px) {
  .equipo_Equipo__2a_PZ {
    --7bc330e88c2341b88b3352beac7356a2_orientation: row;
    --686902dff360450ea740c2e4277b068b_orientation: row;
    --697d9d9bb40b46ada5838c25256f3581_visible: none;
    --44dc677b353e4c91888a8f186ad3f6f6_visible: flex;
    --df0931a01d5147da833f2f82d2e281a8_visible: flex;
    --min-equiposection1_valueheight: 90vh;
    --equiposection1_valueheight: 90vh;
    --7bc330e88c2341b88b3352beac7356a2_horizontalAlignment: center;
    --686902dff360450ea740c2e4277b068b_horizontalAlignment: center;
  }

  .equipo_layer-119-7bc330e88c2341b88b3352beac7356a2__1sc1w,
  .equipo_layer-120-686902dff360450ea740c2e4277b068b__-kMdz {
    grid-gap: 4rem;
    gap: 4rem;
    justify-content: center;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .equipo_Equipo__2a_PZ {
    --7bc330e88c2341b88b3352beac7356a2_orientation: column;
    --686902dff360450ea740c2e4277b068b_orientation: column;
    --697d9d9bb40b46ada5838c25256f3581_visible: flex;
    --44dc677b353e4c91888a8f186ad3f6f6_visible: none;
    --df0931a01d5147da833f2f82d2e281a8_visible: none;
    --min-equiposection1_valueheight: unset;
    --equiposection1_valueheight: fit-content;
    --7bc330e88c2341b88b3352beac7356a2_horizontalAlignment: center;
    --686902dff360450ea740c2e4277b068b_horizontalAlignment: center;
  }

  .equipo_layer-119-7bc330e88c2341b88b3352beac7356a2__1sc1w,
  .equipo_layer-120-686902dff360450ea740c2e4277b068b__-kMdz {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }
}

.areasdepractica_Areasdepractica__2yLcV {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.areasdepractica_areasdepracticaheader__1t_2T {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.areasdepractica_layer-112-6c6ae025310f45bca25605d15fc721c0__1Dzhc {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.areasdepractica_imageview-252-bd4d1eb68f394ea2b338cad83c217efb__2YlKw {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.areasdepractica_layer-113-655def239ea44152bc9b532895aa9e3a__2U-8P {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--655def239ea44152bc9b532895aa9e3a_visible);
}
.areasdepractica_text-104-5ccb1d7c3a2a4a8da3e39699076d5f0d__1Bkpg {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.areasdepractica_text-105-673cd3e949ff4134bebb5a71880a8684__15MgL {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.areasdepractica_text-106-8c35cfc506594f68b041127f6994cefa__3qj3s {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.areasdepractica_text-107-6133556da82e45aba737202df37f1931__3g1Yi {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.areasdepractica_layer-114-5395ab6e28cc4d51b4a0c716a2e94fac__btW2Y {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--5395ab6e28cc4d51b4a0c716a2e94fac_visible);
}
.areasdepractica_text-103-c5c0c21aa2e642598e29af1408429d70__3xfKD {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.areasdepractica_layer-115-fbf4592cb836416c969f70f5e2a0e8ee__1Wxte {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--fbf4592cb836416c969f70f5e2a0e8ee_visible);
}
.areasdepractica_icon-5-fc135017ad7b4694bc615f4849a45d69__3Y71N {
  font-size: var(--fc135017ad7b4694bc615f4849a45d69_valueheight);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  min-height: var(--min-fc135017ad7b4694bc615f4849a45d69_valueheight);
  height: var(--fc135017ad7b4694bc615f4849a45d69_valueheight);
}
.areasdepractica_areasdepracticasections__30ecF {
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--areasdepracticasections_orientation);
}
.areasdepractica_areasdepracticasection1__jwxeF {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Shaking Hands.png");
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  min-height: var(--min-areasdepracticasection1_valueheight);
  height: var(--areasdepracticasection1_valueheight);
}
.areasdepractica_layer-473-30c9bdeffbfc4c9586b9384a1b7ba9ee__3dz9l {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.areasdepractica_text-424-46f53917ad464e179def4d6e387f832c__2YEgb {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.areasdepractica_areasdepracticasection2__u8u99 {
  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.areasdepractica_layer-105-9437ab65e5b2417da06b14da6a7c330d__uH1if {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.areasdepractica_layer-724-45be52233c4a41bd9cfb70c10b840053__1bfJ7 {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.areasdepractica_text-98-81c38722a0724aaf96553a4d4b170cd8__35_bY {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.areasdepractica_layer-723-fe4a85cb47c64fca87f5fb80713a3364__2Cvpy {
  justify-content: center;
  align-items: center;

  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.areasdepractica_text-588-50293e9869904db38922ea0113daffb6__26rRd {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.areasdepractica_layer-106-8794ad9f64e04b098e64681c5bb4a167__33Pgk {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
}
.areasdepractica_layer-725-18e2a778bbb745baa9be7ac72b0b33a7__3oeNH {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;

  --margin-bottom: var(--ItemMargin3V);
  margin-bottom: var(--margin-bottom);
  min-height: var(--min-18e2a778bbb745baa9be7ac72b0b33a7_valueheight);
  height: var(--18e2a778bbb745baa9be7ac72b0b33a7_valueheight);
  min-width: var(--min-18e2a778bbb745baa9be7ac72b0b33a7_valuewidth);
  width: var(--18e2a778bbb745baa9be7ac72b0b33a7_valuewidth);
}
.areasdepractica_text-99-4d7261a5daa04d488fa7b4109e58e3ab__C8Sxk {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.areasdepractica_layer-3108-d173fe693bd54c2abc7a793a88ebb6ff__QZOZc {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.areasdepractica_text-589-edc9d5be10bd454e9cab2db0b9cea520__1Gket {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.areasdepractica_layer-726-7eb28cd100a64ba3995eb684d625dd79__2Moid {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;

  --margin-bottom: var(--ItemMargin3V);
  margin-bottom: var(--margin-bottom);
  min-height: var(--min-7eb28cd100a64ba3995eb684d625dd79_valueheight);
  height: var(--7eb28cd100a64ba3995eb684d625dd79_valueheight);
  min-width: var(--min-7eb28cd100a64ba3995eb684d625dd79_valuewidth);
  width: var(--7eb28cd100a64ba3995eb684d625dd79_valuewidth);
}
.areasdepractica_layer-107-2ae52e136d8a4b148e1e56d502b900bc__SVaIL {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.areasdepractica_layer-727-a67364d52cb74ab8a6f066ebd871ae9e__1j5o5 {
  flex-shrink: 1;

  --margin-bottom: var(--ItemMargin3V);
  margin-bottom: var(--margin-bottom);
  min-height: var(--min-a67364d52cb74ab8a6f066ebd871ae9e_valueheight);
  height: var(--a67364d52cb74ab8a6f066ebd871ae9e_valueheight);
  min-width: var(--min-a67364d52cb74ab8a6f066ebd871ae9e_valuewidth);
  width: var(--a67364d52cb74ab8a6f066ebd871ae9e_valuewidth);
}
.areasdepractica_text-100-4db6eb61e75a4ffe8c14a921c0a761ab__2DE0_ {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.areasdepractica_layer-730-a9053730d74740448e6f77b7e7e4127a__3EzAm {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;
  min-height: var(--min-a9053730d74740448e6f77b7e7e4127a_valueheight);
  height: var(--a9053730d74740448e6f77b7e7e4127a_valueheight);
  min-width: var(--min-a9053730d74740448e6f77b7e7e4127a_valuewidth);
  width: var(--a9053730d74740448e6f77b7e7e4127a_valuewidth);
}
.areasdepractica_text-590-ca98d0b8646942f9becce8e2e20237c6__2uSDO {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.areasdepractica_layer-108-6634e7881dc344f7aa72ef86ddfc50d7__3lOpC {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
}
.areasdepractica_layer-728-74c7e4a778af4e1fb60e29b44c9b29ce__3_jqP {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;

  --margin-bottom: var(--ItemMargin3V);
  margin-bottom: var(--margin-bottom);
  min-height: var(--min-74c7e4a778af4e1fb60e29b44c9b29ce_valueheight);
  height: var(--74c7e4a778af4e1fb60e29b44c9b29ce_valueheight);
  min-width: var(--min-74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth);
  width: var(--74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth);
}
.areasdepractica_text-101-fd769ce6d15b47b2b6b787d94546bee9__3rxwo {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.areasdepractica_layer-731-a0a914708d1e4cc3a3aa645a32c88c0d__c4xP5 {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;
  min-height: var(--min-a0a914708d1e4cc3a3aa645a32c88c0d_valueheight);
  height: var(--a0a914708d1e4cc3a3aa645a32c88c0d_valueheight);
  min-width: var(--min-a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth);
  width: var(--a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth);
}
.areasdepractica_text-591-677f153832e5499597fed6553a66edae__3wyOY {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.areasdepractica_layer-109-2de9a513daab46879388010ff5c8c7a5__QphjU {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.areasdepractica_layer-729-8762de753ddf403e8c4d14b7c5de4c31__a2yBL {
  flex-shrink: 1;

  --margin-bottom: var(--ItemMargin3V);
  margin-bottom: var(--margin-bottom);
  min-height: var(--min-8762de753ddf403e8c4d14b7c5de4c31_valueheight);
  height: var(--8762de753ddf403e8c4d14b7c5de4c31_valueheight);
  min-width: var(--min-8762de753ddf403e8c4d14b7c5de4c31_valuewidth);
  width: var(--8762de753ddf403e8c4d14b7c5de4c31_valuewidth);
}
.areasdepractica_text-102-4b00d730ab274051908b8e2ab60e32ea__YZ-Rv {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.areasdepractica_layer-732-7dfabd7616db4f96a37980c03b4cc549__14Nqu {
  margin-left: 6vw;
  margin-right: 6vw;
  box-sizing: border-box;
  flex-shrink: 1;
  min-height: var(--min-7dfabd7616db4f96a37980c03b4cc549_valueheight);
  height: var(--7dfabd7616db4f96a37980c03b4cc549_valueheight);
  min-width: var(--min-7dfabd7616db4f96a37980c03b4cc549_valuewidth);
  width: var(--7dfabd7616db4f96a37980c03b4cc549_valuewidth);
}
.areasdepractica_text-592-5c332a7e1073408d8c57abffd6c152d6__2jGYR {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.areasdepractica_layer-525-0432ec8d04e24927926df9c414d68f44__Wm22Q {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.areasdepractica_layer-554-b7c8c85d39bd4d8e8f4d34d997e8aab8__3nDq4 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.areasdepractica_layer-1043-265b3e58eb6c4ff6a476e53af9a6bf98__3k43k {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.areasdepractica_imageview-296-9a7b5deb8dd043abad1b86a55372fd74__3D8rj {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.areasdepractica_imageview-297-09ea20d1d14d4ef19573a11f50df786e__1D-Zs {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.areasdepractica_imageview-298-452738ffa50e45e183a79e600eb0bb26__1603S {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.areasdepractica_imageview-299-b41a89827bca4fb0b15d98988090f4fb__4DRDM {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
}
.areasdepractica_layer-111-ab0b6e55cfbf49a0afb5d9c0b0bb4411__1mU6R {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.areasdepractica_text-96-04e9b3a7f5c549269a7250bb65c21a4f__3srYG {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.areasdepractica_text-97-25890b7d195f4122927181352ccb4e81__1x4xH {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.areasdepractica_areasdepracticasections-orientation__2rPP3 {
  align-items: var(--verticalAxis-areasdepracticasections);
  justify-content: var(--horizontalAxis-areasdepracticasections);
}
@media (max-width: 480px) {
  .areasdepractica_Areasdepractica__2yLcV {
    --fbf4592cb836416c969f70f5e2a0e8ee_visible: flex;
    --5395ab6e28cc4d51b4a0c716a2e94fac_visible: none;
    --655def239ea44152bc9b532895aa9e3a_visible: none;
    --min-fc135017ad7b4694bc615f4849a45d69_valueheight: 30px;
    --fc135017ad7b4694bc615f4849a45d69_valueheight: 30px;
    --areasdepracticasections_orientation: column;
    --min-areasdepracticasection1_valueheight: 30vh;
    --areasdepracticasection1_valueheight: 30vh;
    --50293e9869904db38922ea0113daffb6_text: Constitución y liquidación de
        sociedades nacionales,
      reformas estatutarias y acompañamiento legal en procesos de
        adquisiciones...;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valueheight: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valueheight: fit-content;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valueheight: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valueheight: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valuewidth: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valuewidth: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valueheight: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valueheight: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valueheight: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valueheight: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valuewidth: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valuewidth: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valueheight: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valueheight: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valuewidth: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valuewidth: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valueheight: unset;
    --a9053730d74740448e6f77b7e7e4127a_valueheight: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valuewidth: unset;
    --a9053730d74740448e6f77b7e7e4127a_valuewidth: fit-content;
  }

  .areasdepractica_areasdepracticasections-orientation__2rPP3 {
    align-items: var(--horizontalAxis-areasdepracticasections);
    justify-content: var(--verticalAxis-areasdepracticasections);
  }
}
@media (min-width: 1020px) {
  .areasdepractica_Areasdepractica__2yLcV {
    --fbf4592cb836416c969f70f5e2a0e8ee_visible: none;
    --5395ab6e28cc4d51b4a0c716a2e94fac_visible: flex;
    --655def239ea44152bc9b532895aa9e3a_visible: flex;
    --min-fc135017ad7b4694bc615f4849a45d69_valueheight: 30px;
    --fc135017ad7b4694bc615f4849a45d69_valueheight: 30px;
    --areasdepracticasections_orientation: row;
    --min-areasdepracticasection1_valueheight: unset;
    --areasdepracticasection1_valueheight: 100%;
    --50293e9869904db38922ea0113daffb6_text: Constitución y liquidación de
        sociedades nacionales,
      reformas estatutarias y acompañamiento legal en procesos de
        adquisiciones...;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valueheight: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valueheight: fit-content;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valueheight: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valueheight: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valuewidth: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valuewidth: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valueheight: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valueheight: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valueheight: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valueheight: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valuewidth: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valuewidth: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valueheight: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valueheight: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valuewidth: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valuewidth: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valueheight: unset;
    --a9053730d74740448e6f77b7e7e4127a_valueheight: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valuewidth: unset;
    --a9053730d74740448e6f77b7e7e4127a_valuewidth: fit-content;
  }

  .areasdepractica_areasdepracticasections-orientation__2rPP3 {
    align-items: var(--verticalAxis-areasdepracticasections);
    justify-content: var(--horizontalAxis-areasdepracticasections);
  }
  .areasdepractica_areasdepracticasection1__jwxeF {
    height: var(--areasdepracticasection1_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .areasdepractica_Areasdepractica__2yLcV {
    --fbf4592cb836416c969f70f5e2a0e8ee_visible: flex;
    --5395ab6e28cc4d51b4a0c716a2e94fac_visible: none;
    --655def239ea44152bc9b532895aa9e3a_visible: none;
    --min-fc135017ad7b4694bc615f4849a45d69_valueheight: 24px;
    --fc135017ad7b4694bc615f4849a45d69_valueheight: 24px;
    --areasdepracticasections_orientation: column;
    --min-areasdepracticasection1_valueheight: 30vh;
    --areasdepracticasection1_valueheight: 30vh;
    --50293e9869904db38922ea0113daffb6_text: Constitución y liquidación de
        sociedades nacionales,
      reformas estatutarias y acompañamiento legal en procesos de
        adquisiciones...;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valueheight: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valueheight: fit-content;
    --min-18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: unset;
    --18e2a778bbb745baa9be7ac72b0b33a7_valuewidth: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valueheight: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valueheight: fit-content;
    --min-7eb28cd100a64ba3995eb684d625dd79_valuewidth: unset;
    --7eb28cd100a64ba3995eb684d625dd79_valuewidth: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valueheight: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valueheight: fit-content;
    --min-a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: unset;
    --a67364d52cb74ab8a6f066ebd871ae9e_valuewidth: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valueheight: fit-content;
    --min-74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: unset;
    --74c7e4a778af4e1fb60e29b44c9b29ce_valuewidth: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valueheight: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valueheight: fit-content;
    --min-8762de753ddf403e8c4d14b7c5de4c31_valuewidth: unset;
    --8762de753ddf403e8c4d14b7c5de4c31_valuewidth: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valueheight: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valueheight: fit-content;
    --min-7dfabd7616db4f96a37980c03b4cc549_valuewidth: unset;
    --7dfabd7616db4f96a37980c03b4cc549_valuewidth: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valueheight: fit-content;
    --min-a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: unset;
    --a0a914708d1e4cc3a3aa645a32c88c0d_valuewidth: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valueheight: unset;
    --a9053730d74740448e6f77b7e7e4127a_valueheight: fit-content;
    --min-a9053730d74740448e6f77b7e7e4127a_valuewidth: unset;
    --a9053730d74740448e6f77b7e7e4127a_valuewidth: fit-content;
  }

  .areasdepractica_areasdepracticasections-orientation__2rPP3 {
    align-items: var(--horizontalAxis-areasdepracticasections);
    justify-content: var(--verticalAxis-areasdepracticasections);
  }
}

.actualidad_Actualidad__2BNRh {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.actualidad_actualidadheader__2ohJW {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.actualidad_layer-693-df5d043e51ee4706841b3e5abd03ac6c__2E_v0 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.actualidad_imageview-265-4f3e9a9c329d4a6c8dbdfd26fd78cf5a__209pB {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.actualidad_layer-694-4903a3cab5f9457a87652648453d7f4e__2D-PX {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--4903a3cab5f9457a87652648453d7f4e_visible);
}
.actualidad_text-582-773b0bd783d24c17a3a9a94da8c61172__3AbBa {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.actualidad_text-583-3e8f6d914391468a9c7afa711cc1b380__1azz8 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.actualidad_text-584-068d99dbd72043bcb3b3c9b47ea63384__1vaY4 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.actualidad_text-585-73a80bbf66274c3081e06ee6718d681d__2eKEU {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.actualidad_layer-695-3dd8ab102b0b4bdbbc98a4cc131e1a6b__1p-Ww {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--3dd8ab102b0b4bdbbc98a4cc131e1a6b_visible);
}
.actualidad_text-586-1b017a2da6ed4f93add0081017f40d53__2By-C {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.actualidad_layer-696-31fc538627a84185b260a7677c35681b__3vsVX {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--31fc538627a84185b260a7677c35681b_visible);
}
.actualidad_icon-25-9d210291c38f485ab6113f723a44ad57__3uek0 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.actualidad_actualidadsection1__x41cO {
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.actualidad_layer-598-6b7de485df9c4212aa48d256f9fab225__2wWBB {
  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.actualidad_layer-640-203a36235c934805ad0d205079eeea71__2Or-0 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.actualidad_layer-564-d5538693a7724946b140868c56a51a74__3Ix7i {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.actualidad_layer-476-1f0b2118dd1f42f7b4a4ca72b4ef6c64__3bKFA {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.actualidad_imageview-161-cef94b72e10b41b5bfb5230a981d03d5__2FN6w {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.actualidad_imageview-166-bd5054986ec94b469b7d354dcbb21edf__1D4SE {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.actualidad_imageview-163-2f040373153140cab1acf5834d37d14a__138Tv {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.actualidad_imageview-164-ea85782686bd4898aacffa4a53937b62__1_4Hi {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--ea85782686bd4898aacffa4a53937b62_margin-right);
  margin-right: var(--margin-right);
}
.actualidad_layer-477-619a479db478480dbf1a568c3d312027__aB9yT {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.actualidad_text-436-9c2f983ef0ad440a99217d10eb057c44__yFcZ2 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.actualidad_text-437-b7e14aeb75984b21920c49428a78ad87__2drPw {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .actualidad_Actualidad__2BNRh {
    --31fc538627a84185b260a7677c35681b_visible: flex;
    --3dd8ab102b0b4bdbbc98a4cc131e1a6b_visible: none;
    --4903a3cab5f9457a87652648453d7f4e_visible: none;
    --min-ea85782686bd4898aacffa4a53937b62_margin-right: 0vw;
    --ea85782686bd4898aacffa4a53937b62_margin-right: 0vw;
  }
}
@media (min-width: 1020px) {
  .actualidad_Actualidad__2BNRh {
    --31fc538627a84185b260a7677c35681b_visible: none;
    --3dd8ab102b0b4bdbbc98a4cc131e1a6b_visible: flex;
    --4903a3cab5f9457a87652648453d7f4e_visible: flex;
    --min-ea85782686bd4898aacffa4a53937b62_margin-right: 0px;
    --ea85782686bd4898aacffa4a53937b62_margin-right: 0px;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .actualidad_Actualidad__2BNRh {
    --31fc538627a84185b260a7677c35681b_visible: flex;
    --3dd8ab102b0b4bdbbc98a4cc131e1a6b_visible: none;
    --4903a3cab5f9457a87652648453d7f4e_visible: none;
    --min-ea85782686bd4898aacffa4a53937b62_margin-right: 0px;
    --ea85782686bd4898aacffa4a53937b62_margin-right: 0px;
  }
}

.contacto_Contacto__21trZ {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.contacto_contactoheader__2_0Um {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.contacto_layer-490-6b3dbfb499bd47f8a59679df18d04bdd__27tqX {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.contacto_imageview-271-2eb5ba27b5b949d180ab2f8a8931905f__1BOjz {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.contacto_layer-491-a30e03c7fc224982b42317997406c92a__3OqtC {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--a30e03c7fc224982b42317997406c92a_visible);
}
.contacto_text-473-21d0a301c4f64562bc4391a191424903__3mHdo {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.contacto_text-474-99bb5c2144d944bdb8ae76b791d217a6__bpEdU {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.contacto_text-475-d52175800d624b0295c2081c47cf08f9__1Q0lV {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.contacto_text-476-5f608a4db7e24a0cb3ab6488c07c6a61__1Pfa4 {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
}
.contacto_layer-492-d2060ba68a624221adefed806eec35b9__3E_hg {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--d2060ba68a624221adefed806eec35b9_visible);
}
.contacto_text-477-810bcbf98f4b42b7972d2fa648264f89__3edEa {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.contacto_layer-493-fbde1345fcf246cbb9ee7416287319cf__1ef5z {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--fbde1345fcf246cbb9ee7416287319cf_visible);
}
.contacto_icon-29-8896af37a1974abdbd485cfd223b1fe9__6nskK {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.contacto_contactosection1__eEDn4 {
  --verticalAxis-contactosection1: center;
  --horizontalAxis-contactosection1: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Contactanos.png");
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--contactosection1_orientation);
  min-height: var(--min-contactosection1_valueheight);
  height: var(--contactosection1_valueheight);
}
.contacto_layer-2816-800a009efabf411fa6d39c2418f0daa9__1DRMb {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.contacto_text-2189-8fff9490f6a148159eca3d56983a6d68__3C0OA {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.contacto_contactosection2__3mYX6 {
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--contactosection2_orientation);
  --horizontalAxis-contactosection2: var(
    --contactosection2_horizontalAlignment
  );
  --verticalAxis-contactosection2: var(--contactosection2_verticalAlignment);
}
.contacto_layer-2467-d494000be63046a99715d0f87c6cc5c7__3oUqu {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--PrimaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.contacto_contactofooter__1EUda {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.contacto_layer-574-6ff6732a49e04d69846ded0f69b05b0b__TVeB1 {
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.contacto_layer-453-cc430189b294462fa60cf28b3187edde__3VYo- {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.contacto_imageview-146-2e5bfdcff25a44f185532f2daab9f65f__HoYRG {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.contacto_imageview-147-3fd4bd9036f94b069b7d08ecb80f6e94__2ndn5 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.contacto_imageview-148-ab664a7a1f594b20a8f37d0fb3d21903__2PXwf {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.contacto_imageview-149-8bdfb38be237401ea2fea9c08cf52806__2bL4m {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--8bdfb38be237401ea2fea9c08cf52806_margin-right);
  margin-right: var(--margin-right);
}
.contacto_layer-454-7c985bbeb2524bdebf0c9019c7d42858__RyBL3 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.contacto_text-412-800c11aa0eef4ed0a5318c5a6fd648e4__2Jtc5 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.contacto_text-413-dbfb0bee793d46c9bb26572ccfe45487__3z1Gg {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.contacto_contactosection1-orientation__2pZDV {
  align-items: var(--horizontalAxis-contactosection1);
  justify-content: var(--verticalAxis-contactosection1);
}
.contacto_contactosection2-orientation__uFr8p {
  align-items: var(--horizontalAxis-contactosection2);
  justify-content: var(--verticalAxis-contactosection2);
}
@media (max-width: 480px) {
  .contacto_Contacto__21trZ {
    --fbde1345fcf246cbb9ee7416287319cf_visible: flex;
    --d2060ba68a624221adefed806eec35b9_visible: none;
    --a30e03c7fc224982b42317997406c92a_visible: none;
    --min-8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --contactosection2_orientation: column;
    --contactosection2_horizontalAlignment: center;
    --contactosection2_verticalAlignment: center;
    --contactosection1_orientation: column;
    --min-contactosection1_valueheight: 30vh;
    --contactosection1_valueheight: 30vh;
  }

  .contacto_contactosection1-orientation__2pZDV {
    align-items: var(--horizontalAxis-contactosection1);
    justify-content: var(--verticalAxis-contactosection1);
  }
  .contacto_contactosection2-orientation__uFr8p {
    align-items: var(--horizontalAxis-contactosection2);
    justify-content: var(--verticalAxis-contactosection2);
  }
}
@media (min-width: 1020px) {
  .contacto_Contacto__21trZ {
    --fbde1345fcf246cbb9ee7416287319cf_visible: none;
    --d2060ba68a624221adefed806eec35b9_visible: flex;
    --a30e03c7fc224982b42317997406c92a_visible: flex;
    --min-8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --contactosection2_orientation: column;
    --contactosection2_horizontalAlignment: center;
    --contactosection2_verticalAlignment: center;
    --contactosection1_orientation: column;
    --min-contactosection1_valueheight: 15vh;
    --contactosection1_valueheight: 15vh;
  }

  .contacto_contactosection1-orientation__2pZDV {
    align-items: var(--horizontalAxis-contactosection1);
    justify-content: var(--verticalAxis-contactosection1);
  }
  .contacto_contactosection2-orientation__uFr8p {
    align-items: var(--horizontalAxis-contactosection2);
    justify-content: var(--verticalAxis-contactosection2);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .contacto_Contacto__21trZ {
    --fbde1345fcf246cbb9ee7416287319cf_visible: flex;
    --d2060ba68a624221adefed806eec35b9_visible: none;
    --a30e03c7fc224982b42317997406c92a_visible: none;
    --min-8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --contactosection2_orientation: column;
    --contactosection2_horizontalAlignment: center;
    --contactosection2_verticalAlignment: center;
    --contactosection1_orientation: row;
    --min-contactosection1_valueheight: 30vh;
    --contactosection1_valueheight: 30vh;
  }

  .contacto_contactosection1-orientation__2pZDV {
    align-items: var(--verticalAxis-contactosection1);
    justify-content: var(--horizontalAxis-contactosection1);
  }
  .contacto_contactosection2-orientation__uFr8p {
    align-items: var(--horizontalAxis-contactosection2);
    justify-content: var(--verticalAxis-contactosection2);
  }
}

.agendatucapactitacion_Agendatucapactitacion__28u7Q {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.agendatucapactitacion_agendatucapactitacionheader__8myi3 {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.agendatucapactitacion_layer-919-daed3b06d62d49dfa297a2c4eeecd147__3TCIS {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.agendatucapactitacion_imageview-326-4c682f5e980244d69d2e15a81de3f36c__anyTu {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.agendatucapactitacion_layer-508-827a1197fbae4d74a2f9fef610753de1__1nd8s {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--827a1197fbae4d74a2f9fef610753de1_visible);
}
.agendatucapactitacion_text-484-509e3014cedf45459e08922c7d291f4d__2dQzL {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.agendatucapactitacion_text-485-a6e8a5984ec1426d9ef7b55760b55035__BZpX6 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.agendatucapactitacion_text-486-32d1505412fd4f018b4463cd478f4339__del8l {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.agendatucapactitacion_text-487-170edb9fbb6b4feba54955eb4f63c372__3UDWM {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.agendatucapactitacion_layer-509-89cb91bb7a7f4bfdac29dba848d39ddb__uGbAZ {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaPrimaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--89cb91bb7a7f4bfdac29dba848d39ddb_visible);
}
.agendatucapactitacion_text-483-cd400f7174aa44c1ad298c8af1b83104__3AbtI {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.agendatucapactitacion_layer-510-5db3b1c0286b4f87a9359c85ded3821f__XwATG {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--5db3b1c0286b4f87a9359c85ded3821f_visible);
}
.agendatucapactitacion_icon-31-154e77a6693a4e9badd37b4f445f0583__2843O {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.agendatucapactitacion_agendatucapactitacionsection1__3Kxk3 {
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.agendatucapactitacion_layer-669-a55c66e32f8541a5809b2b5b80db4c02__2oGKc {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.agendatucapactitacion_layer-1528-dfbc466134cf418e99563fb059625d8b__2qisF {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.agendatucapactitacion_layer-478-a78eb289a8594c9b962b70dd7fcf18c6__39nHo {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.agendatucapactitacion_imageview-157-61e282bbf5e846e295ee6eda8cc1ea92__2S8Ym {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.agendatucapactitacion_imageview-158-56a6b6a9714945548a0259091d164c92__2qMwV {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.agendatucapactitacion_imageview-159-f84848aea0a44e339f75ef07d38e0e83__gYwWj {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.agendatucapactitacion_imageview-160-015d49406a944c5f9b83d609555935aa__3I2Dr {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.agendatucapactitacion_layer-479-4d995c65a67743a8b3127a77cd223fc6__148Vh {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.agendatucapactitacion_text-431-6cd5f92e0a474fe7913347225e3f4505__3bFQo {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.agendatucapactitacion_text-432-95b0af126e9442279ea5bdbec1b07fa9__2AXfx {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .agendatucapactitacion_Agendatucapactitacion__28u7Q {
    --5db3b1c0286b4f87a9359c85ded3821f_visible: flex;
    --89cb91bb7a7f4bfdac29dba848d39ddb_visible: none;
    --827a1197fbae4d74a2f9fef610753de1_visible: none;
  }
}
@media (min-width: 1020px) {
  .agendatucapactitacion_Agendatucapactitacion__28u7Q {
    --5db3b1c0286b4f87a9359c85ded3821f_visible: none;
    --89cb91bb7a7f4bfdac29dba848d39ddb_visible: flex;
    --827a1197fbae4d74a2f9fef610753de1_visible: flex;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .agendatucapactitacion_Agendatucapactitacion__28u7Q {
    --5db3b1c0286b4f87a9359c85ded3821f_visible: flex;
    --89cb91bb7a7f4bfdac29dba848d39ddb_visible: none;
    --827a1197fbae4d74a2f9fef610753de1_visible: none;
  }
}

.politicadetratamientodedatos_Politicadetratamientodedatos__2VsWe {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.politicadetratamientodedatos_politicadetratamientodedatosheader__3BEdc {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.politicadetratamientodedatos_layer-192-8c467f7b9f0440b29ac550959d51f043__1rj94 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.politicadetratamientodedatos_imageview-332-85635280a723408c88edbea32ff24de9__VCdAD {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.politicadetratamientodedatos_layer-193-dcda1da104f1453cb34ffd9b57edf130__1cDxd {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--dcda1da104f1453cb34ffd9b57edf130_visible);
}
.politicadetratamientodedatos_text-182-298104f6ee594404a4189c85817a40f6__1mpWV {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.politicadetratamientodedatos_text-183-7b539479ed0340208ff0ed3d3a4e6a14__1BaSl {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.politicadetratamientodedatos_text-184-30156efb28084180bba7084cff0ddeb4__3qJom {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.politicadetratamientodedatos_text-185-c9bf7ba79f88477486a26dd4c7601733__1bBfs {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.politicadetratamientodedatos_layer-194-936c6be7b01a47aa946b3510c80b6b6e__2D-uY {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--936c6be7b01a47aa946b3510c80b6b6e_visible);
}
.politicadetratamientodedatos_text-186-cdd5afdacc0e4267ab9b67e61ca7e14c__2RJFy {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.politicadetratamientodedatos_layer-195-1c13e243f0da4659b6aa14f8994ae82b__xeSUg {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--1c13e243f0da4659b6aa14f8994ae82b_visible);
}
.politicadetratamientodedatos_icon-11-3eebdb6485c742199ec9097dbcfe6c8e__3x3_n {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
}
.politicadetratamientodedatos_politicadetratamientodedatossection1__3cQMW {
  padding-left: 6vw;
  padding-right: 6vw;
  box-sizing: border-box;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
}
.politicadetratamientodedatos_text-189-2e0a115504ba427e8e0b4d9f2eaef968__15OV4 {
  text-align: center;

  min-width: unset;
  width: 100%;
  font-family: var(--FontBold);
  font-size: var(--ParagraphTextSize1);
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin2V);
}
.politicadetratamientodedatos_text-190-f9eaa17f4cdd41129f0c9a73b0736cec__1cPW8 {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-191-23946aa6fcf3406b8cf7a993648adc29__3VRfa {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-192-cb65fd544dd94bd9888a52ffc5b2969b__3gUwW {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-193-154c0227f4e446429ed603e2a0d262b9__2hyjE {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-194-a513b368f6e84711b1850c04a202fa7c__1cPrG {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-195-0eddde7da79f402aaa34bc34ff994988__34nZW {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-196-8788154d5738454484156f43f8660fb0__2GCLT {
  min-width: unset;
  width: 100%;
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-577-4de4a47421d04f47b631c2b3b6cd2e56__1ff5m {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-198-0f6536f2dbda4702bc7b6d52357c8cfd__134Ka {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-199-0a4a74e0a3694ffd90b910db17b465aa__zl5as {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-200-33b5037e58134d53b6914075eca4cca7__1BWoO {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-201-d349dacbcffc41ec8dba4884eebaa02b__5oLA2 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-202-cafe4961762a4a82aa0a3aa018347edc__3L5nr {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-203-967bf5a487e04385a124dc38c8679500__3AMaR {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-204-a7950799264e4088a0dcf321334435e9__1-atF {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-205-8e72491f27d04336b4c83c8f9b401065__3zae4 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-206-c8d435c1b0884054b174267c969ba8ab__UsjdC {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-207-10e8043d53c74802b8d08906d69b3b01__3NKbM {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-208-558ef780e20c46eca6e5cf7d86c0fd7b__mdAaA {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-209-f3d5701bcb874bedb91e24f1271aea9c__1eU2g {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-210-a12ae97eb4fb4f9fb19056e9e1ed7e8c__2hrt4 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-578-94ab15f46db04a7fb9a4021104bcf895__1tna0 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-212-bdc4822287bf418bb0a1d651a42afb95__3ojN4 {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-213-51483cb09dc842838ccf2450efbbd1b1__2dRxZ {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-214-6821c1d92b164ce094e0e17755e75c60__1-Jig {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-215-2bec1b7112234c29955c9d1c22ea901a__3z9zj {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-216-f0d970310332438899efbb8961aae082__74_Pm {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-217-957fc496c8de4692b0815d22b2c053e2__19TSN {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-218-a95a72ef9037498382951c59bc91acde__2uLVv {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-219-81c16cb43e1b460e82758c31bbed26f4__2MpbI {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_text-220-5dae5f7de7734ca1a8ae48abfc676624__7Km9Z {
  font-family: var(--FontLight);
  font-size: var(--ParagraphTextSize4);
  margin-bottom: var(--ItemMargin1V);
}
.politicadetratamientodedatos_layer-679-5d55a97b149e458e84838a9563df60a6__2tB4H {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.politicadetratamientodedatos_layer-806-bc43b10916504b68b53d4b8dd0aaf785__1pk-y {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.politicadetratamientodedatos_layer-196-7f796a355d0a4457abc6e4449049a079__1cwT3 {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.politicadetratamientodedatos_imageview-465-f653ea73c41e4de7826f01b1ed6ca3f4__3nKWI {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.politicadetratamientodedatos_imageview-466-fe6be3fddc164a2c8ce89a416d72ccd3__2uZgK {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.politicadetratamientodedatos_imageview-467-ac67055a20cc42ea8906c08600990e8a__1AZeH {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.politicadetratamientodedatos_imageview-468-c77cbeec117b48af91f33346f774a31e__10jyo {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.politicadetratamientodedatos_layer-197-0688448011ad480fba09976b0128a105__1pPHp {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.politicadetratamientodedatos_text-187-f2c4a3b8a3b44410988717f515e9743a__1abYU {
  font-family: var(--FontBold);
  font-size: var(--FooterTextSize);
}
.politicadetratamientodedatos_text-188-afd4b51de30744f0813f2662c0a31ef4__3JXGj {
  font-family: var(--FontRegular);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .politicadetratamientodedatos_Politicadetratamientodedatos__2VsWe {
    --1c13e243f0da4659b6aa14f8994ae82b_visible: flex;
    --936c6be7b01a47aa946b3510c80b6b6e_visible: none;
    --dcda1da104f1453cb34ffd9b57edf130_visible: none;
  }
}
@media (min-width: 1020px) {
  .politicadetratamientodedatos_Politicadetratamientodedatos__2VsWe {
    --1c13e243f0da4659b6aa14f8994ae82b_visible: none;
    --936c6be7b01a47aa946b3510c80b6b6e_visible: flex;
    --dcda1da104f1453cb34ffd9b57edf130_visible: flex;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .politicadetratamientodedatos_Politicadetratamientodedatos__2VsWe {
    --1c13e243f0da4659b6aa14f8994ae82b_visible: flex;
    --936c6be7b01a47aa946b3510c80b6b6e_visible: none;
    --dcda1da104f1453cb34ffd9b57edf130_visible: none;
  }
}

.derechodelosnegocios_Derechodelosnegocios__37250 {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.derechodelosnegocios_derechodelosnegociosheader__3VD01 {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.derechodelosnegocios_layer-448-99725734ed1a4590b8b2fa466c2231dd__ZwHFf {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechodelosnegocios_imageview-348-f8318da41233470abf2575305f42c71a__2Vu4n {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.derechodelosnegocios_layer-449-bcb4419adbe746d3a8c9536ff9158594__nHaRz {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--bcb4419adbe746d3a8c9536ff9158594_visible);
}
.derechodelosnegocios_text-392-98ed32b1bb7f410fb891d5c3730cfe1c__OR46B {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derechodelosnegocios_text-393-11a5e84432d44a8dac44e48094b7bf60__18GCk {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derechodelosnegocios_text-394-daf50a85e1744e88927473387a2c305e__3sueB {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derechodelosnegocios_text-395-9de0914284fe43c98d81b58f65e23049__3KklR {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.derechodelosnegocios_layer-450-a87b299feca54a9580754561145b86bd__3VNak {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--a87b299feca54a9580754561145b86bd_visible);
}
.derechodelosnegocios_text-396-df2c1d6f37ed4c059281eb0bce5e6c37__1_uZu {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.derechodelosnegocios_layer-451-73d2cc46a4c84b759ace7594fbdc4687__37ZR3 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--73d2cc46a4c84b759ace7594fbdc4687_visible);
}
.derechodelosnegocios_icon-16-0f05fb8a0c4c469785cbdf15a495b8a0__3TUGk {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.derechodelosnegocios_derechodelosnegociossection1__1VLMu {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Derecho de los negocios_square.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.derechodelosnegocios_text-467-0441bc4856f44527baf75ce10f3594d6__NtF-T {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
  text-align: var(--0441bc4856f44527baf75ce10f3594d6_textAlign);
}
.derechodelosnegocios_derechodelosnegociossection2__2roNe {
  --verticalAxis-derechodelosnegociossection2: center;
  --horizontalAxis-derechodelosnegociossection2: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  flex-direction: var(--derechodelosnegociossection2_orientation);
  min-height: var(--min-derechodelosnegociossection2_valueheight);
  height: var(--derechodelosnegociossection2_valueheight);
}
.derechodelosnegocios_layer-447-073737606d2b4fecb79409531b789fdc__1sRjn {
  --verticalAxis-073737606d2b4fecb79409531b789fdc: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--073737606d2b4fecb79409531b789fdc_orientation);
  --horizontalAxis-073737606d2b4fecb79409531b789fdc: var(
    --073737606d2b4fecb79409531b789fdc_horizontalAlignment
  );
}
.derechodelosnegocios_layer-551-582a383533554d38a3a58b8617030571__PwVow {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
  margin-top: var(--ItemMargin2V);
}
.derechodelosnegocios_layer-553-266bb3031f2c4640968055f44fc7b70f__QnAiL {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechodelosnegocios_text-475-385b347606114a5fa8f60072950203d0__2YcKh {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechodelosnegocios_layer-554-279fb8306dc043e1a18398009bbb324b__3kk8X {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechodelosnegocios_text-474-5f03b531eca64e10b385767a5dd77b8b__1alke {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.derechodelosnegocios_layer-552-e0751702ce2f4c42beeb861f1d52be52__2dl2N {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechodelosnegocios_layer-555-756aeae75299467ca7a3e427879995e3__2HVzo {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechodelosnegocios_text-473-996b11b0174547b086a5be8a4b69aaed__P_Vbc {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechodelosnegocios_layer-556-34670f786c13442d9c1b85f1f5283ca6__1wccp {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechodelosnegocios_text-472-774b5513577448d898f1a569dfa2f7e9__3CSIj {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.derechodelosnegocios_layer-557-47181be32de04aadb41e0943d50b721a__1DVzu {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
  margin-top: var(--ItemMargin2V);
}
.derechodelosnegocios_layer-558-560bad01bccd4dfeb5ed3c6275e96b2b__2pg3K {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechodelosnegocios_text-471-d4b5cc72836f4c168ee842142c43f0eb__T2DGQ {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechodelosnegocios_layer-559-f914580563624d9189bb353e8f98d3a1__164ia {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechodelosnegocios_text-470-eb142efe1dde44b184474a71d876b1e4__GlF3s {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.derechodelosnegocios_layer-550-85f962fe8a414d01a82086c5055e0893__11gJP {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.derechodelosnegocios_layer-560-674430d4dcd948438cf7954112a7e634__2uYOF {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechodelosnegocios_layer-562-31742b8bb400402db8e5818a5bc53d16__19Fz5 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechodelosnegocios_text-469-495709f574ba43a099e6c0bbc1b0dc9b__1agLl {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechodelosnegocios_layer-561-58c6f0c508f34f4abbea2f02f5bbd647__37PXv {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechodelosnegocios_text-468-3a80bf5dc50e487d902281cd4ef2974f__3QM_q {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.derechodelosnegocios_layer-704-84acdc2713a74cc6aa3a8f900934fc38__13lUg {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.derechodelosnegocios_layer-831-1362ec1d01f24845bcd616ea0e42b25e__pt-82 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.derechodelosnegocios_layer-1220-e5ac884349954747ae53ef043dd1b1be__1ILLl {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.derechodelosnegocios_imageview-285-1f7998ccf4944c8dbfe706a5ef8d29e7__3NO_A {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derechodelosnegocios_imageview-286-83a88a94714d4657be854097dfb8b8c0__3lipr {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derechodelosnegocios_imageview-287-61aee76781bf4a4683060801f804fbb7__7QLEB {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derechodelosnegocios_imageview-288-d0162e402cf54815a2a052a0aa2c22ae__25HCR {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.derechodelosnegocios_layer-1221-be33609c36c442c5a1782bc64b35be32__3iEf6 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechodelosnegocios_text-1068-636c63fbf5b6419ca362f5b84f14e24e__1NtIp {
  font-family: var(--FontBold);
  font-size: var(--FooterTextSize);
}
.derechodelosnegocios_text-1069-6f57cab7946f4fa8807b8da66aa563e6__1NS9k {
  font-family: var(--FontRegular);
  font-size: var(--FooterTextSize);
}

.derechodelosnegocios_derechodelosnegociossection2-orientation__33B0z {
  align-items: var(--horizontalAxis-derechodelosnegociossection2);
  justify-content: var(--verticalAxis-derechodelosnegociossection2);
}
.derechodelosnegocios_layer-447-073737606d2b4fecb79409531b789fdc-orientation__3MYoF {
  align-items: var(--verticalAxis-073737606d2b4fecb79409531b789fdc);
  justify-content: var(--horizontalAxis-073737606d2b4fecb79409531b789fdc);
}
@media (max-width: 480px) {
  .derechodelosnegocios_Derechodelosnegocios__37250 {
    --73d2cc46a4c84b759ace7594fbdc4687_visible: flex;
    --a87b299feca54a9580754561145b86bd_visible: none;
    --bcb4419adbe746d3a8c9536ff9158594_visible: none;
    --derechodelosnegociossection2_orientation: column;
    --073737606d2b4fecb79409531b789fdc_orientation: column;
    --073737606d2b4fecb79409531b789fdc_horizontalAlignment: center;
    --min-derechodelosnegociossection2_valueheight: unset;
    --derechodelosnegociossection2_valueheight: fit-content;
    --0441bc4856f44527baf75ce10f3594d6_textAlign: center;
  }

  .derechodelosnegocios_derechodelosnegociossection2-orientation__33B0z {
    align-items: var(--horizontalAxis-derechodelosnegociossection2);
    justify-content: var(--verticalAxis-derechodelosnegociossection2);
  }
  .derechodelosnegocios_layer-447-073737606d2b4fecb79409531b789fdc-orientation__3MYoF {
    align-items: var(--horizontalAxis-073737606d2b4fecb79409531b789fdc);
    justify-content: var(--verticalAxis-073737606d2b4fecb79409531b789fdc);
  }
}
@media (min-width: 1020px) {
  .derechodelosnegocios_Derechodelosnegocios__37250 {
    --73d2cc46a4c84b759ace7594fbdc4687_visible: none;
    --a87b299feca54a9580754561145b86bd_visible: flex;
    --bcb4419adbe746d3a8c9536ff9158594_visible: flex;
    --derechodelosnegociossection2_orientation: column;
    --073737606d2b4fecb79409531b789fdc_orientation: row;
    --073737606d2b4fecb79409531b789fdc_horizontalAlignment: space-between;
    --min-derechodelosnegociossection2_valueheight: 60vh;
    --derechodelosnegociossection2_valueheight: 60vh;
    --0441bc4856f44527baf75ce10f3594d6_textAlign: center;
  }

  .derechodelosnegocios_derechodelosnegociossection2-orientation__33B0z {
    align-items: var(--horizontalAxis-derechodelosnegociossection2);
    justify-content: var(--verticalAxis-derechodelosnegociossection2);
  }
  .derechodelosnegocios_layer-447-073737606d2b4fecb79409531b789fdc-orientation__3MYoF {
    align-items: var(--verticalAxis-073737606d2b4fecb79409531b789fdc);
    justify-content: var(--horizontalAxis-073737606d2b4fecb79409531b789fdc);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .derechodelosnegocios_Derechodelosnegocios__37250 {
    --73d2cc46a4c84b759ace7594fbdc4687_visible: flex;
    --a87b299feca54a9580754561145b86bd_visible: none;
    --bcb4419adbe746d3a8c9536ff9158594_visible: none;
    --derechodelosnegociossection2_orientation: column;
    --073737606d2b4fecb79409531b789fdc_orientation: column;
    --073737606d2b4fecb79409531b789fdc_horizontalAlignment: center;
    --min-derechodelosnegociossection2_valueheight: unset;
    --derechodelosnegociossection2_valueheight: fit-content;
    --0441bc4856f44527baf75ce10f3594d6_textAlign: center;
  }

  .derechodelosnegocios_derechodelosnegociossection2-orientation__33B0z {
    align-items: var(--horizontalAxis-derechodelosnegociossection2);
    justify-content: var(--verticalAxis-derechodelosnegociossection2);
  }
  .derechodelosnegocios_layer-447-073737606d2b4fecb79409531b789fdc-orientation__3MYoF {
    align-items: var(--horizontalAxis-073737606d2b4fecb79409531b789fdc);
    justify-content: var(--verticalAxis-073737606d2b4fecb79409531b789fdc);
  }
}

.derecholaboral_Derecholaboral__pRVf7 {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.derecholaboral_derecholaboralheader__3xdSo {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.derecholaboral_layer-541-15a2b5261a0d451d8b59b605d8612f5c__3o5Mv {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_imageview-359-78ec7fec2467409fb42973ad6037345a__K_tcg {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.derecholaboral_layer-542-f9e00d72224c4ee481a17c53a4b68dcf__1bZqy {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--f9e00d72224c4ee481a17c53a4b68dcf_visible);
}
.derecholaboral_text-486-4277b7e658554f4a9889cdc300e2c53b__2GzXr {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derecholaboral_text-487-c04a1a49b34643aa80dd0add731dbe43__1ji0C {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derecholaboral_text-447-79787ecc48eb41798eb1aae3ecc4401c__2i1GH {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derecholaboral_text-489-384623175c3249fba602cecb64af2147__1ukQe {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.derecholaboral_layer-543-ce1c0f49f35f43799a6d4abfdef02665__2cmrs {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--ce1c0f49f35f43799a6d4abfdef02665_visible);
}
.derecholaboral_text-490-40ac392a026942d693029ef5b2ebaaf8__1ZwV9 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.derecholaboral_layer-544-3487d7cd5edd45fd8204a1a499fc4af8__15z0d {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--3487d7cd5edd45fd8204a1a499fc4af8_visible);
}
.derecholaboral_icon-19-c6304fc0e2ff41ab9a4983bf062ee9b5__20m-p {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.derecholaboral_derecholaboralsection1__2haka {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Derecho laboral_square.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-right: var(--ItemMargin1H);
  padding-left: var(--ItemMargin1H);
}
.derecholaboral_text-368-0d86958f27e3481dab8df63e1eddbcf2__1Nd3m {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_derecholaboralsection2__K0xeF {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.derecholaboral_layer-728-3174e0a1485f4d09809f1ff14003b383__6_w6q {
  --verticalAxis-3174e0a1485f4d09809f1ff14003b383: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--3174e0a1485f4d09809f1ff14003b383_orientation);
  --horizontalAxis-3174e0a1485f4d09809f1ff14003b383: var(
    --3174e0a1485f4d09809f1ff14003b383_horizontalAlignment
  );
}
.derecholaboral_layer-324-394d6fdfa2ae4db893f478d97089fe7f__1xg7b {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-345-2b125b5a3f0942abb9ea5ea50836c365__2XWGv {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-391-478a1ca2d7394698a2cfe45ad123163a__355XX {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-346-26f119dea52247f1834260cccfcef3e7__1R5L_ {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-392-df6ef76710bf43c0ba048723b0bc0efa__2XmE9 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-325-e1e8d248cb4b42fca1c9bdd6e51e509b__1MuRG {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-347-fffeb8891f5649ceb226f1aab9403ed7__3M5cm {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-393-35b7149a4bf648b4838001534c68e093__OXjxg {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-348-5fd15322d55641bf9c6d3c03306057b6__37zO8 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-394-bc4b9c70468d4f48a6a54adebe4bd296__zR1TX {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-326-9f066b9be7ac4e49b27778c3dff08254__2UQtj {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-349-d87e5fb9fe2548b48672e04ea36a8377__1zwiH {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-395-3012551ba64447e8aa930e8f7384e0fa__kiXjP {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-350-6d4436d65d6740788de86909395f7dbd__3kdo1 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-396-d6ece14d854c4adda52b279b9c3c727a__3DgX3 {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-729-9ca064655cef41fbbf27dffb442d132a__2lowz {
  --verticalAxis-9ca064655cef41fbbf27dffb442d132a: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--9ca064655cef41fbbf27dffb442d132a_orientation);
  --horizontalAxis-9ca064655cef41fbbf27dffb442d132a: var(
    --9ca064655cef41fbbf27dffb442d132a_horizontalAlignment
  );
}
.derecholaboral_layer-327-e661002f00054328ab4daebbee984cc2__3SwZx {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-351-11bdafdd2bd5435bb5997f86fe7ae4ba__1Qfq1 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-397-525489846c5f4d52a3d26a5730a36a01__1gM1o {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-352-c93f94abf67d4789b4f99b1005a6d8c9__2BINL {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-398-24b90db859a342fa96cc324b45a84c3f__29Me8 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-328-8b5bb76742034ded8f32b7d63ae09854__3NMoD {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-353-17c4729b9c0e4d2085718c2760c7404f__3OMDj {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-399-2f54de83a245432cb028570e13dba9ee__2ZxT2 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-354-6a0744114643414b8034cd4b95ba2a5b__14OEj {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-400-bab999ebcb99475e8adbcbe6c8914884___AT7j {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-329-c9a90b1f2e99419d856fcf6f4dfa15d2__2ki5b {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-355-e73b2d1302f84b7991c08f1dc309f13b__30WAV {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-404-03f5a92637a745a88fb9cb4b16550594__3mMn6 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-356-c0a6f85c507842449dad5d4790b11276__2G1Lg {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-405-483f21e555ba43039f7e60271d82f655__2xk__ {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-732-40cd36593d214e4c8985f8862714d11c__1N3lq {
  --verticalAxis-40cd36593d214e4c8985f8862714d11c: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--40cd36593d214e4c8985f8862714d11c_orientation);
  --horizontalAxis-40cd36593d214e4c8985f8862714d11c: var(
    --40cd36593d214e4c8985f8862714d11c_horizontalAlignment
  );
}
.derecholaboral_layer-330-bac6105a810c4bc6995345b407f5fb83__1vcZd {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-357-5519b3015c0a4021a1be74bad404e4d6__3r2Ui {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-403-339bb4ad662a4a05b167385c83cf9c72__2fWJQ {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-358-89b12de8d68e4288a428702fcd1d8fc2__31atp {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-406-8aff0fb14a9d489a8896ee2046a71264__3XfHk {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-331-9ea51973a6a245f59060b17ec48b34f9__2VtvI {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-359-9c607b6cf92641dc93178e06b5f47ea6__2o7sa {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-407-72dd9635dd844df09ee6607d9fe2b9bc__XrWKn {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-360-7608e6de181c4f1390ac61456c9e932c__2IZKv {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-408-f09b768c1ccb40a3ab49ea84c39c0aae__2sgnV {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-332-d20db1769c8a4829b1a8b93ffc78c9dd___GeFG {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-361-b12477f9f96849cd88a8502fa433e806__1F0a_ {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-409-a9483ad671ec42b1a15d1edcfd74fe48__2LcV6 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-362-a5ddfeae41da4df4a3a1f820c10004c8__2xpUM {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-410-90c084561d1440a089a050b8f186b9fd__2qK2V {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-323-58f1ae8049a447b0a28a03c7b8c97826__2lJ1H {
  --verticalAxis-58f1ae8049a447b0a28a03c7b8c97826: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--58f1ae8049a447b0a28a03c7b8c97826_orientation);
  --horizontalAxis-58f1ae8049a447b0a28a03c7b8c97826: var(
    --58f1ae8049a447b0a28a03c7b8c97826_horizontalAlignment
  );
}
.derecholaboral_layer-333-95e37c3dc2324906a1337469225fb833__Uivu9 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-363-81c7fbc4c42d44878dd49ae90e2acd42__1iSut {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-401-caaea98296294b418f992140886f21d4__3qx6E {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-364-6dda8ef5f6ef49ac8898d2c272767eeb__2e0W9 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-402-e55ece3960b94ea7b3b5e5b5f3a2f1d9__2uqIN {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-334-17bc6aaa79ce4e4ab9dcde96b35cb5e6__3ntfV {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-365-e3a3cf695ee242f78a4b4e52620a6e4a__cxOwl {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-390-d637af9ad06e4362bbbf45c790f93a25__3B6pd {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-366-28f40a5e17374c6db0d96e7c23a45090__2y30e {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-389-5561b14ec64f4462bf77d54959a46b58__1eeHV {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-551-79e4ff4c227949d58301920cd94f2998__1iZ_b {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-367-aabda1705cfb492aae7f0d87662ce363__4-IkF {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-388-ef8541973c3643ca8fb6e67d9cb7ecf0__1LOGO {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-368-5b28f4145856469595031d38d9d38a19__mreZE {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-387-d6fa501e8eb244df835b251aad8c2e8f__UAC_k {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0__cIT8o {
  --verticalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--5c8fd1d39d3949b7a4e1ece7bb0b1db0_orientation);
  --horizontalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0: var(
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_horizontalAlignment
  );
}
.derecholaboral_layer-336-277515f5819d4e868aa5410102ef10d2__KHHJ8 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-369-57ab494830454c5e8378d017239c552c__2FIZM {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-386-58f5db29869e47e68c0d70b03c0179eb__1QDJF {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-370-e1db84f8416a472eab2d8ea6432962e8__3zUpn {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-385-96d93fdb19784f9db1a6de42965923b5__KN_Fl {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-337-dec8ac18262240439b3f9402d4fd55bf__2fpUh {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-371-244c72fa8bfc4f508f810acbd8be3b06__1NyP- {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-384-1ff7c8c3c0bb403a974232746d2c3f46__GpkAr {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-372-5f8729e4c43b4786b8054f6c83af1347__2Zz9Y {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-383-44f690d2b3b24e3bbcdc2e54863f7f93__2gDq6 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-338-c6ab728bd9be4dfe9b39930bb1af93c4__2DFrX {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-373-d32404e5708f47c098661b15537de7b8__31w8c {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-382-9b931890734d44c791ca1edb0bf9e391__iS3Dg {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-374-130a709b86f84a6cbdf72e5adaefb2bf__2YVEk {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-381-e93365f1037d4a7895a4e8f1dc3b5178__wfFWK {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-734-262f1cbfb0154413a569d893aa8e0440__2TPUu {
  --verticalAxis-262f1cbfb0154413a569d893aa8e0440: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--262f1cbfb0154413a569d893aa8e0440_orientation);
  --horizontalAxis-262f1cbfb0154413a569d893aa8e0440: var(
    --262f1cbfb0154413a569d893aa8e0440_horizontalAlignment
  );
}
.derecholaboral_layer-339-08a2940082ce42eeb4f40888a1fbeca6__1U4CV {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-375-a8d22a91c0f54f54b7b778d4488cd874__3s-WV {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-380-3813d7eaa28e4e4d8b80bdb087975b86__1JabE {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-376-dee06bcecd3349a8b7eeb4c2283cd61a__-6esu {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-379-e48061a5d05f43f7933319630b217962__2gl2H {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  margin-top: var(--ParagraphTextSize1);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-340-181a5d0b6d0c4596a22947ba6595e1d9__3sCYZ {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-377-e926b332375f4f1e8f14a3583114d9b8__1S1qu {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-378-a59ff831cb8e448da9541db0633da28a__2VAgS {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-378-7469ad3243074257babc078698b156cf__2yp-y {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-377-3f4657f1ef5141d287aade9721f438dc__1bTCy {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-341-b1f77dccb1bb474787700c1eaf1632e5__1m8L5 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-379-ecc2f8965a86481a82dc228f0e8fa820__MkHcL {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-376-30c5ce3db5594961bc67be2324b1a208__3gjs_ {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-380-28b3a58f305e43aca56261bd9b66eb57__zhzG9 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-375-05c487261def4117bf511c8156609477__3WKk8 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-1791-ff640f2542f24c47b3901ecd700ec332__HgKla {
  --verticalAxis-ff640f2542f24c47b3901ecd700ec332: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--ff640f2542f24c47b3901ecd700ec332_orientation);
  --horizontalAxis-ff640f2542f24c47b3901ecd700ec332: var(
    --ff640f2542f24c47b3901ecd700ec332_horizontalAlignment
  );
}
.derecholaboral_layer-342-d59c736b360f402d92c7bf0baa25339e__OQ3SH {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-381-9e54a17f864c41daa47ff728090d335b__1Si-R {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-374-9dfa0c0e91fb491585ffac5dcd861185__14RVN {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-382-e264b91f9ac74e6284b006467841a47a__1i75E {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-373-ffc2c4e0ce1e41778354ea8005acce10__10H1_ {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-343-5d9f83de04d349c7a6f6aaf301e38434__3Y96K {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-383-cd8fd03927994133b2a62387735ecca4__3d_Kr {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-372-962cd3a8307f4a72b6894eb132772c23__3qWWN {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-384-608ae2db9c16451abfa7ebf82a4e0c8a__2bvZt {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-371-4c59576c77fe450e8167994fdd597c1b__3UOtL {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-344-0bf2c9171a654a78808759c36d4f5be6__3FYdy {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-385-48e7ca6f26d94afd83ee12db749174e3__oxr11 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-370-9dfb712fde3049348085bcc83b9399fa__yKtsg {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-386-97af1600eb22480aaed50be671e912c4__KY0LI {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-369-e60db33ed4374cb982e2b45b136e8d45__1j_0J {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-735-bf039933831148ba9a81d5615b89ecf7__2OVxy {
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  justify-content: var(--bf039933831148ba9a81d5615b89ecf7_verticalAlignment);
}
.derecholaboral_layer-387-6ef1a81001d24ffabd63872ef70751b9__14Gko {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-388-904e00f9a1b94b3db7d9c2d07cc28535__hJjdR {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derecholaboral_text-411-f6ee45a0272b4187bf51b135c3b7f9f5__2wM0r {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derecholaboral_layer-389-7e91472d33cf4f39807247f89fbe3028__3Uxgf {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-412-9d15a7f6125d4538a58b843a56d53efb__vf05Z {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derecholaboral_layer-790-23c655743ef64e43a535e21dfb48f472__35Wrk {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.derecholaboral_layer-917-8c95c39b7c5e43ceb2583e335ffd212b__3Vhbh {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.derecholaboral_layer-1544-aec6bc950c6d450ab11979a266711353__2ATUA {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.derecholaboral_imageview-294-b568529c834347ad940b2dcbdf668c9d__2HgSU {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derecholaboral_imageview-295-a2c158939582425e891828b25738b833__2pin6 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derecholaboral_imageview-296-54ecc07adef74a049d3336066352af70__2dSuA {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derecholaboral_imageview-297-d3b4bc6fd8b94b359b51c86bdb152911__3RyJq {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.derecholaboral_layer-1545-c2fdc9f651fa4ec197e50dccbd1f1342__FI_Jl {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derecholaboral_text-1317-189865232b96486ea4f9dad16932b7cb__3iOWy {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.derecholaboral_text-1318-af3913d5ad1e48ee871b14e314e4a965__1ML4C {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.derecholaboral_layer-728-3174e0a1485f4d09809f1ff14003b383-orientation__1M8OO {
  align-items: var(--verticalAxis-3174e0a1485f4d09809f1ff14003b383);
  justify-content: var(--horizontalAxis-3174e0a1485f4d09809f1ff14003b383);
}
.derecholaboral_layer-729-9ca064655cef41fbbf27dffb442d132a-orientation__3aZdU {
  align-items: var(--verticalAxis-9ca064655cef41fbbf27dffb442d132a);
  justify-content: var(--horizontalAxis-9ca064655cef41fbbf27dffb442d132a);
}
.derecholaboral_layer-732-40cd36593d214e4c8985f8862714d11c-orientation__1JQdk {
  align-items: var(--verticalAxis-40cd36593d214e4c8985f8862714d11c);
  justify-content: var(--horizontalAxis-40cd36593d214e4c8985f8862714d11c);
}
.derecholaboral_layer-323-58f1ae8049a447b0a28a03c7b8c97826-orientation__Oh6TM {
  align-items: var(--verticalAxis-58f1ae8049a447b0a28a03c7b8c97826);
  justify-content: var(--horizontalAxis-58f1ae8049a447b0a28a03c7b8c97826);
}
.derecholaboral_layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0-orientation__1ZVIC {
  align-items: var(--verticalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
  justify-content: var(--horizontalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
}
.derecholaboral_layer-734-262f1cbfb0154413a569d893aa8e0440-orientation__3CAVD {
  align-items: var(--verticalAxis-262f1cbfb0154413a569d893aa8e0440);
  justify-content: var(--horizontalAxis-262f1cbfb0154413a569d893aa8e0440);
}
.derecholaboral_layer-1791-ff640f2542f24c47b3901ecd700ec332-orientation__JSRzZ {
  align-items: var(--verticalAxis-ff640f2542f24c47b3901ecd700ec332);
  justify-content: var(--horizontalAxis-ff640f2542f24c47b3901ecd700ec332);
}
@media (max-width: 480px) {
  .derecholaboral_Derecholaboral__pRVf7 {
    --3487d7cd5edd45fd8204a1a499fc4af8_visible: flex;
    --ce1c0f49f35f43799a6d4abfdef02665_visible: none;
    --f9e00d72224c4ee481a17c53a4b68dcf_visible: none;
    --3174e0a1485f4d09809f1ff14003b383_orientation: column;
    --3174e0a1485f4d09809f1ff14003b383_horizontalAlignment: center;
    --9ca064655cef41fbbf27dffb442d132a_orientation: column;
    --9ca064655cef41fbbf27dffb442d132a_horizontalAlignment: center;
    --40cd36593d214e4c8985f8862714d11c_orientation: column;
    --40cd36593d214e4c8985f8862714d11c_horizontalAlignment: center;
    --58f1ae8049a447b0a28a03c7b8c97826_orientation: column;
    --58f1ae8049a447b0a28a03c7b8c97826_horizontalAlignment: center;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_orientation: column;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_horizontalAlignment: center;
    --262f1cbfb0154413a569d893aa8e0440_orientation: column;
    --262f1cbfb0154413a569d893aa8e0440_horizontalAlignment: center;
    --ff640f2542f24c47b3901ecd700ec332_orientation: column;
    --ff640f2542f24c47b3901ecd700ec332_horizontalAlignment: center;
    --bf039933831148ba9a81d5615b89ecf7_verticalAlignment: center;
  }

  .derecholaboral_layer-728-3174e0a1485f4d09809f1ff14003b383-orientation__1M8OO {
    align-items: var(--horizontalAxis-3174e0a1485f4d09809f1ff14003b383);
    justify-content: var(--verticalAxis-3174e0a1485f4d09809f1ff14003b383);
  }
  .derecholaboral_layer-729-9ca064655cef41fbbf27dffb442d132a-orientation__3aZdU {
    align-items: var(--horizontalAxis-9ca064655cef41fbbf27dffb442d132a);
    justify-content: var(--verticalAxis-9ca064655cef41fbbf27dffb442d132a);
  }
  .derecholaboral_layer-732-40cd36593d214e4c8985f8862714d11c-orientation__1JQdk {
    align-items: var(--horizontalAxis-40cd36593d214e4c8985f8862714d11c);
    justify-content: var(--verticalAxis-40cd36593d214e4c8985f8862714d11c);
  }
  .derecholaboral_layer-323-58f1ae8049a447b0a28a03c7b8c97826-orientation__Oh6TM {
    align-items: var(--horizontalAxis-58f1ae8049a447b0a28a03c7b8c97826);
    justify-content: var(--verticalAxis-58f1ae8049a447b0a28a03c7b8c97826);
  }
  .derecholaboral_layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0-orientation__1ZVIC {
    align-items: var(--horizontalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
    justify-content: var(--verticalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
  }
  .derecholaboral_layer-734-262f1cbfb0154413a569d893aa8e0440-orientation__3CAVD {
    align-items: var(--horizontalAxis-262f1cbfb0154413a569d893aa8e0440);
    justify-content: var(--verticalAxis-262f1cbfb0154413a569d893aa8e0440);
  }
  .derecholaboral_layer-1791-ff640f2542f24c47b3901ecd700ec332-orientation__JSRzZ {
    align-items: var(--horizontalAxis-ff640f2542f24c47b3901ecd700ec332);
    justify-content: var(--verticalAxis-ff640f2542f24c47b3901ecd700ec332);
  }
}
@media (min-width: 1020px) {
  .derecholaboral_Derecholaboral__pRVf7 {
    --3487d7cd5edd45fd8204a1a499fc4af8_visible: none;
    --ce1c0f49f35f43799a6d4abfdef02665_visible: flex;
    --f9e00d72224c4ee481a17c53a4b68dcf_visible: flex;
    --3174e0a1485f4d09809f1ff14003b383_orientation: row;
    --3174e0a1485f4d09809f1ff14003b383_horizontalAlignment: space-between;
    --9ca064655cef41fbbf27dffb442d132a_orientation: row;
    --9ca064655cef41fbbf27dffb442d132a_horizontalAlignment: space-between;
    --40cd36593d214e4c8985f8862714d11c_orientation: row;
    --40cd36593d214e4c8985f8862714d11c_horizontalAlignment: space-between;
    --58f1ae8049a447b0a28a03c7b8c97826_orientation: row;
    --58f1ae8049a447b0a28a03c7b8c97826_horizontalAlignment: space-between;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_orientation: row;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_horizontalAlignment: space-between;
    --262f1cbfb0154413a569d893aa8e0440_orientation: row;
    --262f1cbfb0154413a569d893aa8e0440_horizontalAlignment: space-between;
    --ff640f2542f24c47b3901ecd700ec332_orientation: row;
    --ff640f2542f24c47b3901ecd700ec332_horizontalAlignment: space-between;
    --bf039933831148ba9a81d5615b89ecf7_verticalAlignment: center;
  }

  .derecholaboral_layer-728-3174e0a1485f4d09809f1ff14003b383-orientation__1M8OO {
    align-items: var(--verticalAxis-3174e0a1485f4d09809f1ff14003b383);
    justify-content: var(--horizontalAxis-3174e0a1485f4d09809f1ff14003b383);
  }
  .derecholaboral_layer-729-9ca064655cef41fbbf27dffb442d132a-orientation__3aZdU {
    align-items: var(--verticalAxis-9ca064655cef41fbbf27dffb442d132a);
    justify-content: var(--horizontalAxis-9ca064655cef41fbbf27dffb442d132a);
  }
  .derecholaboral_layer-732-40cd36593d214e4c8985f8862714d11c-orientation__1JQdk {
    align-items: var(--verticalAxis-40cd36593d214e4c8985f8862714d11c);
    justify-content: var(--horizontalAxis-40cd36593d214e4c8985f8862714d11c);
  }
  .derecholaboral_layer-323-58f1ae8049a447b0a28a03c7b8c97826-orientation__Oh6TM {
    align-items: var(--verticalAxis-58f1ae8049a447b0a28a03c7b8c97826);
    justify-content: var(--horizontalAxis-58f1ae8049a447b0a28a03c7b8c97826);
  }
  .derecholaboral_layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0-orientation__1ZVIC {
    align-items: var(--verticalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
    justify-content: var(--horizontalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
  }
  .derecholaboral_layer-734-262f1cbfb0154413a569d893aa8e0440-orientation__3CAVD {
    align-items: var(--verticalAxis-262f1cbfb0154413a569d893aa8e0440);
    justify-content: var(--horizontalAxis-262f1cbfb0154413a569d893aa8e0440);
  }
  .derecholaboral_layer-1791-ff640f2542f24c47b3901ecd700ec332-orientation__JSRzZ {
    align-items: var(--verticalAxis-ff640f2542f24c47b3901ecd700ec332);
    justify-content: var(--horizontalAxis-ff640f2542f24c47b3901ecd700ec332);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .derecholaboral_Derecholaboral__pRVf7 {
    --3487d7cd5edd45fd8204a1a499fc4af8_visible: flex;
    --ce1c0f49f35f43799a6d4abfdef02665_visible: none;
    --f9e00d72224c4ee481a17c53a4b68dcf_visible: none;
    --3174e0a1485f4d09809f1ff14003b383_orientation: column;
    --3174e0a1485f4d09809f1ff14003b383_horizontalAlignment: center;
    --9ca064655cef41fbbf27dffb442d132a_orientation: column;
    --9ca064655cef41fbbf27dffb442d132a_horizontalAlignment: center;
    --40cd36593d214e4c8985f8862714d11c_orientation: column;
    --40cd36593d214e4c8985f8862714d11c_horizontalAlignment: center;
    --58f1ae8049a447b0a28a03c7b8c97826_orientation: column;
    --58f1ae8049a447b0a28a03c7b8c97826_horizontalAlignment: center;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_orientation: column;
    --5c8fd1d39d3949b7a4e1ece7bb0b1db0_horizontalAlignment: center;
    --262f1cbfb0154413a569d893aa8e0440_orientation: column;
    --262f1cbfb0154413a569d893aa8e0440_horizontalAlignment: center;
    --ff640f2542f24c47b3901ecd700ec332_orientation: column;
    --ff640f2542f24c47b3901ecd700ec332_horizontalAlignment: center;
    --bf039933831148ba9a81d5615b89ecf7_verticalAlignment: center;
  }

  .derecholaboral_layer-728-3174e0a1485f4d09809f1ff14003b383-orientation__1M8OO {
    align-items: var(--horizontalAxis-3174e0a1485f4d09809f1ff14003b383);
    justify-content: var(--verticalAxis-3174e0a1485f4d09809f1ff14003b383);
  }
  .derecholaboral_layer-729-9ca064655cef41fbbf27dffb442d132a-orientation__3aZdU {
    align-items: var(--horizontalAxis-9ca064655cef41fbbf27dffb442d132a);
    justify-content: var(--verticalAxis-9ca064655cef41fbbf27dffb442d132a);
  }
  .derecholaboral_layer-732-40cd36593d214e4c8985f8862714d11c-orientation__1JQdk {
    align-items: var(--horizontalAxis-40cd36593d214e4c8985f8862714d11c);
    justify-content: var(--verticalAxis-40cd36593d214e4c8985f8862714d11c);
  }
  .derecholaboral_layer-323-58f1ae8049a447b0a28a03c7b8c97826-orientation__Oh6TM {
    align-items: var(--horizontalAxis-58f1ae8049a447b0a28a03c7b8c97826);
    justify-content: var(--verticalAxis-58f1ae8049a447b0a28a03c7b8c97826);
  }
  .derecholaboral_layer-733-5c8fd1d39d3949b7a4e1ece7bb0b1db0-orientation__1ZVIC {
    align-items: var(--horizontalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
    justify-content: var(--verticalAxis-5c8fd1d39d3949b7a4e1ece7bb0b1db0);
  }
  .derecholaboral_layer-734-262f1cbfb0154413a569d893aa8e0440-orientation__3CAVD {
    align-items: var(--horizontalAxis-262f1cbfb0154413a569d893aa8e0440);
    justify-content: var(--verticalAxis-262f1cbfb0154413a569d893aa8e0440);
  }
  .derecholaboral_layer-1791-ff640f2542f24c47b3901ecd700ec332-orientation__JSRzZ {
    align-items: var(--horizontalAxis-ff640f2542f24c47b3901ecd700ec332);
    justify-content: var(--verticalAxis-ff640f2542f24c47b3901ecd700ec332);
  }
}

.derechomigratorio_Derechomigratorio__c0l_V {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.derechomigratorio_derechomigratorioheader__-3G1Z {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.derechomigratorio_layer-550-a643fc926e194856b2708bff5c77b884__3PH7v {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechomigratorio_imageview-365-40bddfe92ca54c259a94a71ba1cf68ce__2yb7Q {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.derechomigratorio_layer-551-52961a92d33f4fecb02a129be64e38cd__3d2O4 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--52961a92d33f4fecb02a129be64e38cd_visible);
}
.derechomigratorio_text-491-8149e67896ae48059b8490497d0d99c6__2SPYY {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derechomigratorio_text-492-3d8cec224528416f8351fb5b5e0dcb4c__1V8Wo {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derechomigratorio_text-493-9f4f92869d114263a56723f2cf79c5bb__28YjB {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derechomigratorio_text-494-901bae9a95934e3382591272d6b3980c__1YseR {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.derechomigratorio_layer-552-b0505bd0ad3441a0b5fa9f5820f072a2__1JmlM {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--b0505bd0ad3441a0b5fa9f5820f072a2_visible);
}
.derechomigratorio_text-495-586af7d7511e4e0db72fc0467e54f54a__2msfG {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.derechomigratorio_layer-553-b4e0efbdb6fc41aa92290d58b9a257bf__1pbd2 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--b4e0efbdb6fc41aa92290d58b9a257bf_visible);
}
.derechomigratorio_icon-20-29cb79f09bf84d1c8f6391908e7b5c65__Fsnq6 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.derechomigratorio_derechomigratoriosection1__vemI0 {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Derecho migratorio_square.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.derechomigratorio_text-531-ea488e30006e4bf085edd96529a67bd4__3WxZu {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.derechomigratorio_derechomigratoriosection2__2MClo {
  --verticalAxis-derechomigratoriosection2: center;
  --horizontalAxis-derechomigratoriosection2: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  flex-direction: var(--derechomigratoriosection2_orientation);
  min-height: var(--min-derechomigratoriosection2_valueheight);
  height: var(--derechomigratoriosection2_valueheight);
}
.derechomigratorio_layer-371-5428df8aee6b48d596744c8d06b3698d__m9ZCI {
  --verticalAxis-5428df8aee6b48d596744c8d06b3698d: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--5428df8aee6b48d596744c8d06b3698d_orientation);
  --horizontalAxis-5428df8aee6b48d596744c8d06b3698d: var(
    --5428df8aee6b48d596744c8d06b3698d_horizontalAlignment
  );
}
.derechomigratorio_layer-649-83806fe25a7e4397a69e82635c68ec87__22bFZ {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechomigratorio_layer-652-f8816cf22bb74341a6c50bbb5b4cb1d6__2X6kx {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechomigratorio_text-532-c4b6d478f66646a7b521b98db1c51407__2e52b {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechomigratorio_layer-653-b9f444621f144ad780e037b649f79e4d__2LaXh {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechomigratorio_text-533-6513a6a5c301484f9b119ca291c21717__1CR3E {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechomigratorio_layer-650-c67564b0ef724e7daa115197b4f7b0d4__kCmJ3 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechomigratorio_layer-654-e71ddb15a90b432f9b153f4310dccac5__1XnP_ {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechomigratorio_text-534-b8d6e81f634342aab89edfd7cc13577c__3_05L {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechomigratorio_layer-655-dadd02af35754d1692128c3d73cbae71___CAFt {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechomigratorio_text-535-bd4db502a46141608239f7a18e465373__9UZqn {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechomigratorio_layer-651-52ce67d0d34f4051a35ac29dfe5388ac__2StXL {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechomigratorio_layer-656-286e899173c8418da31bb81c219e82ea__3vGYa {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechomigratorio_text-536-9df851b15163487c8f60a155cc8885a5__20JZQ {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechomigratorio_layer-657-dfb52a25652b4e2686d8021c8d3037ef__2Brab {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechomigratorio_text-537-24a3033d07454d99aabd5908b7512801__1e6Oi {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechomigratorio_layer-648-68d20573e02a4ceb93f420e140ef567b__2wZFN {
  --verticalAxis-68d20573e02a4ceb93f420e140ef567b: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--68d20573e02a4ceb93f420e140ef567b_orientation);
  --horizontalAxis-68d20573e02a4ceb93f420e140ef567b: var(
    --68d20573e02a4ceb93f420e140ef567b_horizontalAlignment
  );
}
.derechomigratorio_layer-658-65e65b1b592d48959c3250bb1b836fe2__hpaXW {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechomigratorio_layer-665-7c78862ad30b44d1a6decd836b54b54e__1Jvd4 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechomigratorio_text-538-6ea098651a9c4962b8ca4b97e92b7349__3GB7c {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechomigratorio_layer-666-15eb0ef26662425098a19f501189566c__3-RDY {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechomigratorio_text-539-ec439e0dfdeb4edb9589c3daefb3d010__10XJW {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechomigratorio_layer-659-b1a725ac905e4dd487a1740199d5c95e__ILLcg {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechomigratorio_layer-663-841746c7cf0c4082ba7f8d35ea166c8f__3iq8k {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechomigratorio_text-540-c087a3305f4047109ad6f97177f7d3c3__cY5Dr {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechomigratorio_layer-664-2b5f0128f09e40c69eb08c52036ca9b9__3xXax {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechomigratorio_text-541-13c6aa86ee384d25bdd976b4ac4b9291__1thja {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechomigratorio_layer-660-8a54567e830f42cd90637ae9b19f9dd8__1sKNd {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechomigratorio_layer-661-86e45902613e45d5b29f968339d332bb__DwswQ {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechomigratorio_text-542-891c5e140fe341c89fa0c340c0c8ef23__2cT-w {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechomigratorio_layer-662-21e516fa56cd48c39dcd79767702dce9__1Z_Z1 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechomigratorio_text-543-0040f6d5f88c4b03bf339009be387fef__2xPIW {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechomigratorio_layer-821-e4672b4541dd4c11a2d1f4dc7b3e5b20__3bL1y {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.derechomigratorio_layer-948-276ca5f55c234f01b9b498a8725c5f68__1B2zx {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.derechomigratorio_layer-971-f08dd6871d31412cbe18b2c460aacdf2__2h_G6 {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.derechomigratorio_imageview-303-2ce9f008d218437b87760dcc00f38b56__1HczE {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derechomigratorio_imageview-304-b57e7ec332734da1984ef87f68175be4__3yFCY {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derechomigratorio_imageview-305-ebea9ba3637f4f38baf72ca357449d1d__LBsHz {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derechomigratorio_imageview-306-49dc7bad0a8b4add9b6449db576cc6f2__24jm4 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.derechomigratorio_layer-972-de21e149839142458e52f2926a9c6bd2__1d9Rr {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechomigratorio_text-835-9a9db24532bb4f0aa63cb4ad1cb91add__2ntUo {
  font-family: var(--FontBold);
  font-size: var(--FooterTextSize);
}
.derechomigratorio_text-836-e94b03f7e4c64ecfadcda8c65e3a5dcb__2rKiQ {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.derechomigratorio_derechomigratoriosection2-orientation__10C70 {
  align-items: var(--horizontalAxis-derechomigratoriosection2);
  justify-content: var(--verticalAxis-derechomigratoriosection2);
}
.derechomigratorio_layer-371-5428df8aee6b48d596744c8d06b3698d-orientation__3I6zx {
  align-items: var(--verticalAxis-5428df8aee6b48d596744c8d06b3698d);
  justify-content: var(--horizontalAxis-5428df8aee6b48d596744c8d06b3698d);
}
.derechomigratorio_layer-648-68d20573e02a4ceb93f420e140ef567b-orientation__287Nv {
  align-items: var(--verticalAxis-68d20573e02a4ceb93f420e140ef567b);
  justify-content: var(--horizontalAxis-68d20573e02a4ceb93f420e140ef567b);
}
@media (max-width: 480px) {
  .derechomigratorio_Derechomigratorio__c0l_V {
    --b4e0efbdb6fc41aa92290d58b9a257bf_visible: flex;
    --b0505bd0ad3441a0b5fa9f5820f072a2_visible: none;
    --52961a92d33f4fecb02a129be64e38cd_visible: none;
    --5428df8aee6b48d596744c8d06b3698d_orientation: column;
    --68d20573e02a4ceb93f420e140ef567b_orientation: column;
    --5428df8aee6b48d596744c8d06b3698d_horizontalAlignment: center;
    --68d20573e02a4ceb93f420e140ef567b_horizontalAlignment: center;
    --derechomigratoriosection2_orientation: column;
    --min-derechomigratoriosection2_valueheight: unset;
    --derechomigratoriosection2_valueheight: fit-content;
  }

  .derechomigratorio_derechomigratoriosection2-orientation__10C70 {
    align-items: var(--horizontalAxis-derechomigratoriosection2);
    justify-content: var(--verticalAxis-derechomigratoriosection2);
  }
  .derechomigratorio_layer-371-5428df8aee6b48d596744c8d06b3698d-orientation__3I6zx {
    align-items: var(--horizontalAxis-5428df8aee6b48d596744c8d06b3698d);
    justify-content: var(--verticalAxis-5428df8aee6b48d596744c8d06b3698d);
  }
  .derechomigratorio_layer-648-68d20573e02a4ceb93f420e140ef567b-orientation__287Nv {
    align-items: var(--horizontalAxis-68d20573e02a4ceb93f420e140ef567b);
    justify-content: var(--verticalAxis-68d20573e02a4ceb93f420e140ef567b);
  }
}
@media (min-width: 1020px) {
  .derechomigratorio_Derechomigratorio__c0l_V {
    --b4e0efbdb6fc41aa92290d58b9a257bf_visible: none;
    --b0505bd0ad3441a0b5fa9f5820f072a2_visible: flex;
    --52961a92d33f4fecb02a129be64e38cd_visible: flex;
    --5428df8aee6b48d596744c8d06b3698d_orientation: row;
    --68d20573e02a4ceb93f420e140ef567b_orientation: row;
    --5428df8aee6b48d596744c8d06b3698d_horizontalAlignment: space-between;
    --68d20573e02a4ceb93f420e140ef567b_horizontalAlignment: space-between;
    --derechomigratoriosection2_orientation: column;
    --min-derechomigratoriosection2_valueheight: 60vh;
    --derechomigratoriosection2_valueheight: 60vh;
  }

  .derechomigratorio_derechomigratoriosection2-orientation__10C70 {
    align-items: var(--horizontalAxis-derechomigratoriosection2);
    justify-content: var(--verticalAxis-derechomigratoriosection2);
  }
  .derechomigratorio_layer-371-5428df8aee6b48d596744c8d06b3698d-orientation__3I6zx {
    align-items: var(--verticalAxis-5428df8aee6b48d596744c8d06b3698d);
    justify-content: var(--horizontalAxis-5428df8aee6b48d596744c8d06b3698d);
  }
  .derechomigratorio_layer-648-68d20573e02a4ceb93f420e140ef567b-orientation__287Nv {
    align-items: var(--verticalAxis-68d20573e02a4ceb93f420e140ef567b);
    justify-content: var(--horizontalAxis-68d20573e02a4ceb93f420e140ef567b);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .derechomigratorio_Derechomigratorio__c0l_V {
    --b4e0efbdb6fc41aa92290d58b9a257bf_visible: flex;
    --b0505bd0ad3441a0b5fa9f5820f072a2_visible: none;
    --52961a92d33f4fecb02a129be64e38cd_visible: none;
    --5428df8aee6b48d596744c8d06b3698d_orientation: column;
    --68d20573e02a4ceb93f420e140ef567b_orientation: column;
    --5428df8aee6b48d596744c8d06b3698d_horizontalAlignment: center;
    --68d20573e02a4ceb93f420e140ef567b_horizontalAlignment: center;
    --derechomigratoriosection2_orientation: column;
    --min-derechomigratoriosection2_valueheight: unset;
    --derechomigratoriosection2_valueheight: fit-content;
  }

  .derechomigratorio_derechomigratoriosection2-orientation__10C70 {
    align-items: var(--horizontalAxis-derechomigratoriosection2);
    justify-content: var(--verticalAxis-derechomigratoriosection2);
  }
  .derechomigratorio_layer-371-5428df8aee6b48d596744c8d06b3698d-orientation__3I6zx {
    align-items: var(--horizontalAxis-5428df8aee6b48d596744c8d06b3698d);
    justify-content: var(--verticalAxis-5428df8aee6b48d596744c8d06b3698d);
  }
  .derechomigratorio_layer-648-68d20573e02a4ceb93f420e140ef567b-orientation__287Nv {
    align-items: var(--horizontalAxis-68d20573e02a4ceb93f420e140ef567b);
    justify-content: var(--verticalAxis-68d20573e02a4ceb93f420e140ef567b);
  }
}

.seguridadysalud_Seguridadysalud__2e4M6 {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.seguridadysalud_seguridadysaludheader__1QPHK {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.seguridadysalud_layer-648-a89a154cc8ca4736b42da2b8af508242__2Cfjb {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.seguridadysalud_imageview-371-ecb22591fcbb42ffb904dc8083e2fc8f__3N9nT {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.seguridadysalud_layer-649-feaef8f5d1184c1d93e556864db82e5a__1I1ii {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--feaef8f5d1184c1d93e556864db82e5a_visible);
}
.seguridadysalud_text-551-3c0b77885128471bbcb40ed8ef4af0ee__12lRX {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.seguridadysalud_text-552-333536eddf814e619193bd31a74500ca__2atri {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.seguridadysalud_text-553-01c143ee9f5e4dd49214da842c0f4a72__23LjE {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.seguridadysalud_text-554-586d02b3c88045e1b48f1d5b71dd2b12__2acIj {
  font-family: var(--FontBold);
  font-size: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.seguridadysalud_layer-650-1fc2f18bfab54595a6d1c220072f682f__37s4l {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--1fc2f18bfab54595a6d1c220072f682f_visible);
}
.seguridadysalud_text-555-5142e1adfa1c4279a039b9fcec3c1526__2qppM {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.seguridadysalud_layer-651-879177f25ca644939ce8c61e36083f99__28Rz1 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--879177f25ca644939ce8c61e36083f99_visible);
}
.seguridadysalud_icon-23-31ec0fdeb6784b11a1f5baeeac75634f__1wz6G {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.seguridadysalud_seguridadysaludsection1__2uCxR {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Seguridad y Salud en el Trabajo_square.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.seguridadysalud_text-549-a6c003f713774254af099102d6b130ae__2YLCF {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.seguridadysalud_seguridadysaludsection2__2Xpbp {
  --verticalAxis-seguridadysaludsection2: center;
  --horizontalAxis-seguridadysaludsection2: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  flex-direction: var(--seguridadysaludsection2_orientation);
  min-height: var(--min-seguridadysaludsection2_valueheight);
  height: var(--seguridadysaludsection2_valueheight);
}
.seguridadysalud_layer-366-7a1fd54a29564db6857595ba516dece5__3IzNr {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.seguridadysalud_layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9__3xh1M {
  --verticalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--14a6bf7dd0b14db7a52cecdb935e1ac9_orientation);
  --horizontalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9: var(
    --14a6bf7dd0b14db7a52cecdb935e1ac9_horizontalAlignment
  );
}
.seguridadysalud_layer-677-e83f0dd71ed34a2a8e93abac67d38cc0__1lc5W {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.seguridadysalud_layer-682-92420c29d7eb48fe8565a7b7c29a395a__1L8sc {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.seguridadysalud_text-557-7c0177875ec4454aa6693277e24a070c__vC3lp {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.seguridadysalud_layer-681-69586c71e8a24b8084710b2813b6e2b7__a25gj {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.seguridadysalud_text-556-beca734d743d48aab5d12dba4ad1abde__2UIYT {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.seguridadysalud_layer-678-b3801fddd61e44019e27dc47f0c6b800__1dm1n {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.seguridadysalud_layer-683-685bf3f5451e40b4bea2efcdf6ce0572__15XX3 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.seguridadysalud_text-555-e0094f4ff34a43f2af02fb251947f03b__1QJEm {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.seguridadysalud_layer-684-57aa3cab63e94a2c9a16e8b1036808ef__3TBoO {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.seguridadysalud_text-554-a892731dc51c4c0d88bdb3b699809f93__3js8k {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.seguridadysalud_layer-679-204f5355a47247d1b8ff58e0010e98a0__3-hb7 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.seguridadysalud_layer-685-5e8925d9913d404480fd7f4ef0af64d4__3Fs2T {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.seguridadysalud_text-553-d185ff09fabd420f88ff8fc2f76a7bbf__Sqh68 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.seguridadysalud_layer-686-1aa1a3f07632479183ca834bc03c5ecd__3oCAt {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.seguridadysalud_text-552-92c43fb035b746fb94fbea922098b02d__i3Stt {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.seguridadysalud_layer-756-90a7ae03186e422b9a373018255acec6__3r2zH {
  --verticalAxis-90a7ae03186e422b9a373018255acec6: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--90a7ae03186e422b9a373018255acec6_orientation);
  --horizontalAxis-90a7ae03186e422b9a373018255acec6: var(
    --90a7ae03186e422b9a373018255acec6_horizontalAlignment
  );
}
.seguridadysalud_layer-680-31b7a027473545f7b13decf439489628__67ZYC {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.seguridadysalud_layer-687-ffc6387f32bc4db69d24f6c874afba11__1yzzm {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.seguridadysalud_text-551-f06b936a42254449a65c1ac3289edf7f__27m7P {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.seguridadysalud_layer-688-7a3d6f22c49a49a0a561f762ea3ec1a5__3pkdj {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.seguridadysalud_text-550-d5a87fd96a85422ca905844b93846e38__isjWg {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.seguridadysalud_layer-689-54475ac3c88446ab89edb87d3ceb7e6a__2n02C {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.seguridadysalud_layer-692-b18ace6a147a43439f482bec847af92c__2i53P {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.seguridadysalud_text-563-40a8c43e4bc2405ba7116ea239ca0a8f__nCvt5 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.seguridadysalud_layer-693-ce1e18bb48064c078d7d9fff95349cec__3kn2L {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.seguridadysalud_text-562-cb0535a830db4aa3b7b863fea1e9142e__3Zw2g {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.seguridadysalud_layer-690-a5a987ab712d49fa91d5e817d57f1652__1B3ml {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.seguridadysalud_layer-694-bc85b49450904f3b8b5e2e1c096dc464__2i36k {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.seguridadysalud_text-561-679062f3a29a47aba036df91c3bf5596__1TplS {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.seguridadysalud_layer-695-98e60de1f80d46c4bd520ecf8a8a958b__1voit {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.seguridadysalud_text-560-b8851a30fc234525b0a450c9eeeedb66__2vm-2 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.seguridadysalud_layer-757-53bed54837e343aba9d269dd1802dd19__slEXR {
  --verticalAxis-53bed54837e343aba9d269dd1802dd19: center;
  --horizontalAxis-53bed54837e343aba9d269dd1802dd19: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--53bed54837e343aba9d269dd1802dd19_orientation);
}
.seguridadysalud_layer-691-3c13aa8593ac4db4a414f80438f158fa__3S1xg {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.seguridadysalud_layer-696-0f85d8eea48b4a15adff54cb428e200e__38r5X {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.seguridadysalud_text-558-a2e0672e09534d9e86700252a21822ee__nxGPO {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.seguridadysalud_layer-697-c0469288876942d49bf7d5c69178ad37__1PWv0 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.seguridadysalud_text-559-74e0e671fe0e45ffb33eac7238096e9f__1nFcK {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.seguridadysalud_layer-857-d74227c239e6450490d222b3070b6baa__1FXET {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.seguridadysalud_layer-984-ec89b7891dc748eaa0229edac40ccaf0__1nEz1 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.seguridadysalud_layer-677-9696947ef7564802ba11b37302674a7e__24xuh {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.seguridadysalud_imageview-312-7b5bb868fd344543ad163c15ef46f951__1Ejm4 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.seguridadysalud_imageview-313-aa62bce87f4a4ca69a084cf66998f40b__Inkje {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.seguridadysalud_imageview-314-f82c4eb2eddb4b269d9dd4f2db1ce46d__3I3dc {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.seguridadysalud_imageview-315-318f403a5b424e4ca8646f4e38ccd1a9__mLWin {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.seguridadysalud_layer-678-a60467f42fae482fb142bb11ba42c6c5__PoOGD {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.seguridadysalud_text-639-ec6b0dc92e5b476fbe2c79db575c64e9__3r-VC {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.seguridadysalud_text-640-405a9fa1baa7424ea6a33abdd949c465__217Aj {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.seguridadysalud_seguridadysaludsection2-orientation__3_oJK {
  align-items: var(--verticalAxis-seguridadysaludsection2);
  justify-content: var(--horizontalAxis-seguridadysaludsection2);
}
.seguridadysalud_layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9-orientation__FeMm2 {
  align-items: var(--verticalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
  justify-content: var(--horizontalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
}
.seguridadysalud_layer-756-90a7ae03186e422b9a373018255acec6-orientation__iqrig {
  align-items: var(--verticalAxis-90a7ae03186e422b9a373018255acec6);
  justify-content: var(--horizontalAxis-90a7ae03186e422b9a373018255acec6);
}
.seguridadysalud_layer-757-53bed54837e343aba9d269dd1802dd19-orientation__1kNDx {
  align-items: var(--verticalAxis-53bed54837e343aba9d269dd1802dd19);
  justify-content: var(--horizontalAxis-53bed54837e343aba9d269dd1802dd19);
}
@media (max-width: 480px) {
  .seguridadysalud_Seguridadysalud__2e4M6 {
    --879177f25ca644939ce8c61e36083f99_visible: flex;
    --1fc2f18bfab54595a6d1c220072f682f_visible: none;
    --feaef8f5d1184c1d93e556864db82e5a_visible: none;
    --seguridadysaludsection2_orientation: column;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_orientation: column;
    --90a7ae03186e422b9a373018255acec6_orientation: column;
    --53bed54837e343aba9d269dd1802dd19_orientation: column;
    --90a7ae03186e422b9a373018255acec6_horizontalAlignment: center;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_horizontalAlignment: center;
    --min-seguridadysaludsection2_valueheight: unset;
    --seguridadysaludsection2_valueheight: fit-content;
  }

  .seguridadysalud_seguridadysaludsection2-orientation__3_oJK {
    align-items: var(--horizontalAxis-seguridadysaludsection2);
    justify-content: var(--verticalAxis-seguridadysaludsection2);
  }
  .seguridadysalud_layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9-orientation__FeMm2 {
    align-items: var(--horizontalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
    justify-content: var(--verticalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
  }
  .seguridadysalud_layer-756-90a7ae03186e422b9a373018255acec6-orientation__iqrig {
    align-items: var(--horizontalAxis-90a7ae03186e422b9a373018255acec6);
    justify-content: var(--verticalAxis-90a7ae03186e422b9a373018255acec6);
  }
  .seguridadysalud_layer-757-53bed54837e343aba9d269dd1802dd19-orientation__1kNDx {
    align-items: var(--horizontalAxis-53bed54837e343aba9d269dd1802dd19);
    justify-content: var(--verticalAxis-53bed54837e343aba9d269dd1802dd19);
  }
}
@media (min-width: 1020px) {
  .seguridadysalud_Seguridadysalud__2e4M6 {
    --879177f25ca644939ce8c61e36083f99_visible: none;
    --1fc2f18bfab54595a6d1c220072f682f_visible: flex;
    --feaef8f5d1184c1d93e556864db82e5a_visible: flex;
    --seguridadysaludsection2_orientation: row;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_orientation: row;
    --90a7ae03186e422b9a373018255acec6_orientation: row;
    --53bed54837e343aba9d269dd1802dd19_orientation: row;
    --90a7ae03186e422b9a373018255acec6_horizontalAlignment: space-between;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_horizontalAlignment: space-between;
    --min-seguridadysaludsection2_valueheight: 60vh;
    --seguridadysaludsection2_valueheight: 60vh;
  }

  .seguridadysalud_seguridadysaludsection2-orientation__3_oJK {
    align-items: var(--verticalAxis-seguridadysaludsection2);
    justify-content: var(--horizontalAxis-seguridadysaludsection2);
  }
  .seguridadysalud_layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9-orientation__FeMm2 {
    align-items: var(--verticalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
    justify-content: var(--horizontalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
  }
  .seguridadysalud_layer-756-90a7ae03186e422b9a373018255acec6-orientation__iqrig {
    align-items: var(--verticalAxis-90a7ae03186e422b9a373018255acec6);
    justify-content: var(--horizontalAxis-90a7ae03186e422b9a373018255acec6);
  }
  .seguridadysalud_layer-757-53bed54837e343aba9d269dd1802dd19-orientation__1kNDx {
    align-items: var(--verticalAxis-53bed54837e343aba9d269dd1802dd19);
    justify-content: var(--horizontalAxis-53bed54837e343aba9d269dd1802dd19);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .seguridadysalud_Seguridadysalud__2e4M6 {
    --879177f25ca644939ce8c61e36083f99_visible: flex;
    --1fc2f18bfab54595a6d1c220072f682f_visible: none;
    --feaef8f5d1184c1d93e556864db82e5a_visible: none;
    --seguridadysaludsection2_orientation: column;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_orientation: column;
    --90a7ae03186e422b9a373018255acec6_orientation: column;
    --53bed54837e343aba9d269dd1802dd19_orientation: column;
    --90a7ae03186e422b9a373018255acec6_horizontalAlignment: center;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_horizontalAlignment: center;
    --min-seguridadysaludsection2_valueheight: unset;
    --seguridadysaludsection2_valueheight: fit-content;
  }

  .seguridadysalud_seguridadysaludsection2-orientation__3_oJK {
    align-items: var(--horizontalAxis-seguridadysaludsection2);
    justify-content: var(--verticalAxis-seguridadysaludsection2);
  }
  .seguridadysalud_layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9-orientation__FeMm2 {
    align-items: var(--horizontalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
    justify-content: var(--verticalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
  }
  .seguridadysalud_layer-756-90a7ae03186e422b9a373018255acec6-orientation__iqrig {
    align-items: var(--horizontalAxis-90a7ae03186e422b9a373018255acec6);
    justify-content: var(--verticalAxis-90a7ae03186e422b9a373018255acec6);
  }
  .seguridadysalud_layer-757-53bed54837e343aba9d269dd1802dd19-orientation__1kNDx {
    align-items: var(--horizontalAxis-53bed54837e343aba9d269dd1802dd19);
    justify-content: var(--verticalAxis-53bed54837e343aba9d269dd1802dd19);
  }
}

.derechotributario_Derechotributario__wtkjm {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.derechotributario_derechotributarioheader__1qP4t {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.derechotributario_layer-358-22d99567c0224953b1accc6574454d6c__1ViB- {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechotributario_imageview-377-efc9957a431c4a1697318d807526807a__aRpjQ {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.derechotributario_layer-359-1c478a790ff54e60bcf6e21950414afd__3HSt0 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--1c478a790ff54e60bcf6e21950414afd_visible);
}
.derechotributario_text-342-e50be2f158544d82a9f482ac70fd5904__1Ki95 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derechotributario_text-343-3f7aca6f9d2049cd953f9debc6192eb2__2zIe9 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derechotributario_text-344-059bc4cdcbca4dbfbed88c082626ed94__1mNPZ {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.derechotributario_text-345-6ca8962a625949c2b4f36ce992b4f753__O3v_W {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.derechotributario_layer-360-be07bf4245d945288fd56b0c894e3945__3yebL {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--be07bf4245d945288fd56b0c894e3945_visible);
}
.derechotributario_text-346-880e6cf2c2b64f6098a8810ddacd8522__25LPU {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.derechotributario_layer-361-74a2af96b7054e4dbbfc2d37a86e451b__3acRc {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--74a2af96b7054e4dbbfc2d37a86e451b_visible);
}
.derechotributario_icon-15-349301617e1c437680dbbc32d37296e6__20Wd8 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.derechotributario_derechotributariosection1__357h6 {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Derecho Tributario_square.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.derechotributario_text-278-dda0d0f19dae4512af636cfdb2ae251b__2VJkq {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.derechotributario_derechotributariosection2__3Urpd {
  --verticalAxis-derechotributariosection2: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  --horizontalAxis-derechotributariosection2: var(
    --derechotributariosection2_horizontalAlignment
  );
  flex-direction: var(--derechotributariosection2_orientation);
  min-height: var(--min-derechotributariosection2_valueheight);
  height: var(--derechotributariosection2_valueheight);
}
.derechotributario_layer-1639-de743383905f49ac8e50df20596755b1__3iby2 {
  --verticalAxis-de743383905f49ac8e50df20596755b1: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--de743383905f49ac8e50df20596755b1_orientation);
  --horizontalAxis-de743383905f49ac8e50df20596755b1: var(
    --de743383905f49ac8e50df20596755b1_horizontalAlignment
  );
}
.derechotributario_layer-234-ef76f0f62ce84b9cb60be5c4a312363d__1chP4 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechotributario_layer-243-01d425d5f90c47b59310c0504d950ed4__3h39t {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechotributario_text-260-3a05dc3165f340759c3a8ee278f30e7a__MoFCy {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechotributario_layer-244-f9bd0cbdbc73429ca3253e39d15527f8__2S9x4 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechotributario_text-261-36e5e99a2dd6452a8955131a1d985416__lauVW {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechotributario_layer-235-e7101809502042468d882ea4153ebd98__3ABZn {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechotributario_layer-245-179e1e2a827d47d28a86dcd507fc8523__2OYbP {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechotributario_text-262-7104603bc2054cbcae9b0124c828a0dd__sZi0N {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechotributario_layer-246-4b933a4595c04b59977cdf76b6d05c35__1FPA3 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechotributario_text-263-589701cdbce44f449358272580f21280__7qN_r {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
}
.derechotributario_layer-236-93193a715b1c424f87b8ff2df462e8cb__xL5pK {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechotributario_layer-247-92cf8d757fe64d288bd6d871f1972ab2__3BoLe {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechotributario_text-264-325184179e5a445c9fc523f82ea945ef__njcwm {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechotributario_layer-248-69ea89c892e749f6abc616181d909bd6__H3PQ7 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechotributario_text-265-c01ea86459474dfaba18548ba7ab601e__237P7 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechotributario_layer-1640-88807b8185bf405a9c62722c705436ed__LrB5D {
  --verticalAxis-88807b8185bf405a9c62722c705436ed: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--88807b8185bf405a9c62722c705436ed_orientation);
  --horizontalAxis-88807b8185bf405a9c62722c705436ed: var(
    --88807b8185bf405a9c62722c705436ed_horizontalAlignment
  );
}
.derechotributario_layer-237-beb94b67b81b45539e6ab651e864165e__1xWYi {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechotributario_layer-249-264f6e68eb6a497284e1a038d36146af__3k9b8 {
  justify-content: center;
  align-items: flex-end;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechotributario_text-266-4b0b274665434cf8a52537432e9404a4__3fcbL {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechotributario_layer-250-978cb54dfe7d473ca3a433f300ae0102__3gwpd {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechotributario_text-267-52c548199c86481894339f6b1bc436eb__1M1Ug {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechotributario_layer-238-fb6524e8e9d649ae99b54d4e5ba1e6b3__1Uxzv {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechotributario_layer-251-1094227617e44b0ea9d48c326bf730d2__jfn_a {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechotributario_text-268-d87ae52002e644568003210381a47305__3CNic {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechotributario_layer-252-77e69c5672194a1bb01e9384bed9c8f1__3Tfha {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechotributario_text-269-eb02358f015d44ff8a05322edf9cceef__3GL-m {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechotributario_layer-680-ead4ce7dabc54aab862a335578d1b8ad__W3dj4 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechotributario_layer-253-8111e1d188cd4b519a69e1414c043e50__3h2nT {
  justify-content: center;
  align-items: flex-end;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechotributario_text-270-b3a320eb1e024d948d1c6453295e4d20__13nO8 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechotributario_layer-254-f53076f8452a4ab3992ba1a09437a675__1guBV {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechotributario_text-271-f658ddf823f248349fb0fe9babc22817__aivNJ {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechotributario_layer-2009-d0a023972f5648ca8e98805e414e1718__1R7KQ {
  --verticalAxis-d0a023972f5648ca8e98805e414e1718: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  --horizontalAxis-d0a023972f5648ca8e98805e414e1718: var(
    --d0a023972f5648ca8e98805e414e1718_horizontalAlignment
  );
  flex-direction: var(--d0a023972f5648ca8e98805e414e1718_orientation);
}
.derechotributario_layer-240-c9b58987915d4b56b9c3a46cf353cb70__3jk36 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechotributario_layer-255-814bbcd440f848808ff65f0ac893cc5d__2Ct_m {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechotributario_text-272-81346a2c52b446b59b763d3fcd55af8a__2t2yp {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechotributario_layer-256-fc458dd4b19e4455b93a03e3b7cd9d81__2iXtl {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechotributario_text-273-301a1c821a8f40fd866c51dc776110a0__2lee3 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechotributario_layer-241-48600d28cd9144b8b7038d18917c1197__3L4gP {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechotributario_layer-257-cc910f729bd142cca478dc9b60c5de49__3qUbL {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechotributario_text-274-3de573b8d2df478e81d9d414f66038b2__dooW- {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechotributario_layer-258-945d078f8f854f8da77219d6f08de642__bUIGF {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechotributario_text-275-a6929c09a12745978e95ad2049a2a9c2__39_aD {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechotributario_layer-242-4527f9a378354bb98abaa7185e5de0d2__3Q6ev {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.derechotributario_layer-259-b85f830ad979477090ebd1194d9bc7e2__1bFzu {
  justify-content: center;
  align-items: flex-end;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.derechotributario_text-276-1c13091b1e324128952c9646023f2b36__3ZrGn {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.derechotributario_layer-260-ca7f5b8fcca74a08aedafcad02a779ba__2lGJ_ {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechotributario_text-277-01613a2e4e374d11a5df29e389de313d__1S9tg {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.derechotributario_layer-898-4154cb0fc6c34ac7ade29ded504fa962__1Lhj4 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.derechotributario_layer-1253-0dcd3bb17c82490690c6097aa3dc9756__iDSoH {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.derechotributario_layer-976-4b6c62e47c3b41dcae5961110aa0e54a__2YQ4O {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.derechotributario_imageview-296-42ae8ecd628141d397a78c433bdfa46f__2qpoT {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derechotributario_imageview-297-71f617992ee44bf5aee67db2506b27a8__Mjbya {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derechotributario_imageview-298-6be85d5e66a842849cb587508d3969b7__cM09T {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.derechotributario_imageview-299-e06083202ea34f0d95228ba56e9d13a9__1M4rk {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.derechotributario_layer-977-5abac821107e4b348add921d78a48887__35Z5E {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.derechotributario_text-884-68297c5b27ea4653ad8d1ff3568cf6e0__3g_uI {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.derechotributario_text-885-8638b72fc71742e09b434907a183377f__2Nsbr {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.derechotributario_derechotributariosection2-orientation__10WG7 {
  align-items: var(--horizontalAxis-derechotributariosection2);
  justify-content: var(--verticalAxis-derechotributariosection2);
}
.derechotributario_layer-1639-de743383905f49ac8e50df20596755b1-orientation__3Kl1b {
  align-items: var(--verticalAxis-de743383905f49ac8e50df20596755b1);
  justify-content: var(--horizontalAxis-de743383905f49ac8e50df20596755b1);
}
.derechotributario_layer-1640-88807b8185bf405a9c62722c705436ed-orientation__3QMNR {
  align-items: var(--verticalAxis-88807b8185bf405a9c62722c705436ed);
  justify-content: var(--horizontalAxis-88807b8185bf405a9c62722c705436ed);
}
.derechotributario_layer-2009-d0a023972f5648ca8e98805e414e1718-orientation__3WHco {
  align-items: var(--verticalAxis-d0a023972f5648ca8e98805e414e1718);
  justify-content: var(--horizontalAxis-d0a023972f5648ca8e98805e414e1718);
}
@media (max-width: 480px) {
  .derechotributario_Derechotributario__wtkjm {
    --74a2af96b7054e4dbbfc2d37a86e451b_visible: flex;
    --be07bf4245d945288fd56b0c894e3945_visible: none;
    --1c478a790ff54e60bcf6e21950414afd_visible: none;
    --derechotributariosection2_horizontalAlignment: center;
    --derechotributariosection2_orientation: column;
    --de743383905f49ac8e50df20596755b1_orientation: column;
    --de743383905f49ac8e50df20596755b1_horizontalAlignment: center;
    --88807b8185bf405a9c62722c705436ed_orientation: column;
    --88807b8185bf405a9c62722c705436ed_horizontalAlignment: center;
    --d0a023972f5648ca8e98805e414e1718_horizontalAlignment: center;
    --d0a023972f5648ca8e98805e414e1718_orientation: column;
    --min-derechotributariosection2_valueheight: unset;
    --derechotributariosection2_valueheight: fit-content;
  }

  .derechotributario_derechotributariosection2-orientation__10WG7 {
    align-items: var(--horizontalAxis-derechotributariosection2);
    justify-content: var(--verticalAxis-derechotributariosection2);
  }
  .derechotributario_layer-1639-de743383905f49ac8e50df20596755b1-orientation__3Kl1b {
    align-items: var(--horizontalAxis-de743383905f49ac8e50df20596755b1);
    justify-content: var(--verticalAxis-de743383905f49ac8e50df20596755b1);
  }
  .derechotributario_layer-1640-88807b8185bf405a9c62722c705436ed-orientation__3QMNR {
    align-items: var(--horizontalAxis-88807b8185bf405a9c62722c705436ed);
    justify-content: var(--verticalAxis-88807b8185bf405a9c62722c705436ed);
  }
  .derechotributario_layer-2009-d0a023972f5648ca8e98805e414e1718-orientation__3WHco {
    align-items: var(--horizontalAxis-d0a023972f5648ca8e98805e414e1718);
    justify-content: var(--verticalAxis-d0a023972f5648ca8e98805e414e1718);
  }
}
@media (min-width: 1020px) {
  .derechotributario_Derechotributario__wtkjm {
    --74a2af96b7054e4dbbfc2d37a86e451b_visible: none;
    --be07bf4245d945288fd56b0c894e3945_visible: flex;
    --1c478a790ff54e60bcf6e21950414afd_visible: flex;
    --derechotributariosection2_horizontalAlignment: center;
    --derechotributariosection2_orientation: column;
    --de743383905f49ac8e50df20596755b1_orientation: row;
    --de743383905f49ac8e50df20596755b1_horizontalAlignment: space-between;
    --88807b8185bf405a9c62722c705436ed_orientation: row;
    --88807b8185bf405a9c62722c705436ed_horizontalAlignment: space-between;
    --d0a023972f5648ca8e98805e414e1718_horizontalAlignment: space-between;
    --d0a023972f5648ca8e98805e414e1718_orientation: row;
    --min-derechotributariosection2_valueheight: 60vh;
    --derechotributariosection2_valueheight: 60vh;
  }

  .derechotributario_derechotributariosection2-orientation__10WG7 {
    align-items: var(--horizontalAxis-derechotributariosection2);
    justify-content: var(--verticalAxis-derechotributariosection2);
  }
  .derechotributario_layer-1639-de743383905f49ac8e50df20596755b1-orientation__3Kl1b {
    align-items: var(--verticalAxis-de743383905f49ac8e50df20596755b1);
    justify-content: var(--horizontalAxis-de743383905f49ac8e50df20596755b1);
  }
  .derechotributario_layer-1640-88807b8185bf405a9c62722c705436ed-orientation__3QMNR {
    align-items: var(--verticalAxis-88807b8185bf405a9c62722c705436ed);
    justify-content: var(--horizontalAxis-88807b8185bf405a9c62722c705436ed);
  }
  .derechotributario_layer-2009-d0a023972f5648ca8e98805e414e1718-orientation__3WHco {
    align-items: var(--verticalAxis-d0a023972f5648ca8e98805e414e1718);
    justify-content: var(--horizontalAxis-d0a023972f5648ca8e98805e414e1718);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .derechotributario_Derechotributario__wtkjm {
    --74a2af96b7054e4dbbfc2d37a86e451b_visible: flex;
    --be07bf4245d945288fd56b0c894e3945_visible: none;
    --1c478a790ff54e60bcf6e21950414afd_visible: none;
    --derechotributariosection2_horizontalAlignment: flex-start;
    --derechotributariosection2_orientation: column;
    --de743383905f49ac8e50df20596755b1_orientation: column;
    --de743383905f49ac8e50df20596755b1_horizontalAlignment: center;
    --88807b8185bf405a9c62722c705436ed_orientation: column;
    --88807b8185bf405a9c62722c705436ed_horizontalAlignment: center;
    --d0a023972f5648ca8e98805e414e1718_horizontalAlignment: center;
    --d0a023972f5648ca8e98805e414e1718_orientation: column;
    --min-derechotributariosection2_valueheight: unset;
    --derechotributariosection2_valueheight: fit-content;
  }

  .derechotributario_derechotributariosection2-orientation__10WG7 {
    align-items: var(--horizontalAxis-derechotributariosection2);
    justify-content: var(--verticalAxis-derechotributariosection2);
  }
  .derechotributario_layer-1639-de743383905f49ac8e50df20596755b1-orientation__3Kl1b {
    align-items: var(--horizontalAxis-de743383905f49ac8e50df20596755b1);
    justify-content: var(--verticalAxis-de743383905f49ac8e50df20596755b1);
  }
  .derechotributario_layer-1640-88807b8185bf405a9c62722c705436ed-orientation__3QMNR {
    align-items: var(--horizontalAxis-88807b8185bf405a9c62722c705436ed);
    justify-content: var(--verticalAxis-88807b8185bf405a9c62722c705436ed);
  }
  .derechotributario_layer-2009-d0a023972f5648ca8e98805e414e1718-orientation__3WHco {
    align-items: var(--horizontalAxis-d0a023972f5648ca8e98805e414e1718);
    justify-content: var(--verticalAxis-d0a023972f5648ca8e98805e414e1718);
  }
}

.juanuribe_Juanuribe__1_Hqh {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.juanuribe_juanuribeheader__1BS7V {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.juanuribe_layer-417-d009988779ca475eb4fc461dd6e77381__36cxA {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.juanuribe_imageview-400-2f842c6045ba42ae995be882b581134d__IMuZK {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.juanuribe_layer-418-eda976f682894247990ce7aee4929118__1BvRu {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--eda976f682894247990ce7aee4929118_visible);
}
.juanuribe_text-493-91215a6a12504d6c82d7e5d2d9909290__3Wmg2 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.juanuribe_text-494-51edc3c53e6d462eb72b1b7488761945__24-UL {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.juanuribe_text-495-db8292533abb401c8a61e9b1ff9f1c3e__3xpX_ {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.juanuribe_text-496-84d7ca59da024322984d6266493e9cc3__1WTpQ {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.juanuribe_layer-419-378d0915e323439ab47ebbc856ba158b__2a0nU {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--378d0915e323439ab47ebbc856ba158b_visible);
}
.juanuribe_text-497-bb4347cb29e94dbea8f1c4d7719b72d2__WPq1u {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.juanuribe_layer-420-d8eea0bbe9bd45e69d4e1bd85808d848__2y3Iq {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--d8eea0bbe9bd45e69d4e1bd85808d848_visible);
}
.juanuribe_icon-28-047b0ca5fbc44e93bc71b40cd09def28__3KmLV {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.juanuribe_juanuribesection1__22dsM {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.juanuribe_layer-416-e80b2a621a5942079d957209576b2fbb__2YsTM {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.juanuribe_text-490-707f7c3f30aa4da7846183bb8e7d935d__-2EJ7 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.juanuribe_layer-538-2884212a797240a9bf79cd8f19376366__dFcEF {
  border-radius: 15px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.juanuribe_imageview-187-b3ccd1dc3dff4652b8360b31417fbd54__3JiA2 {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.juanuribe_text-491-a4aee9f2fd0b4562bb2b45edd91f43bd__FEO9P {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.juanuribe_text-492-1221ce0c43124231b2cac20898da362d__31sFr {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.juanuribe_imageview-230-4965780287c74801a235d3ca3563ca66__2gixh {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.juanuribe_juanuribesection2__1Xia2 {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-juanuribesection2_valueheight);
  height: var(--juanuribesection2_valueheight);
}
.juanuribe_layer-421-754eb401f28e4a5e9a4a3a2b1916419b__2YBcK {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.juanuribe_text-498-8898d8110fb54d1694d2be667050fb42__1yO-3 {
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  margin-bottom: var(--ItemMargin2V);
}
.juanuribe_text-499-7f70943f92a14ba9a0615ceda9992b44__3pCYD {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.juanuribe_text-500-3be6da8393424528ab656f06503f8572__2FRc1 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.juanuribe_text-501-de5bfd88c94c4fe08a7a7437eeb87e70__UR9X6 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
}
.juanuribe_layer-911-244198e60ca04e619062d04e9d7d4825__2sjse {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.juanuribe_layer-1349-a7cceab913fa4545a3df97e70191e510__2dlzq {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.juanuribe_layer-556-02b115e0bfb244908d03ac66bf855fed__3xesZ {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.juanuribe_imageview-479-bcae51498abe4266b0616ed866ec1bb9__1qTdH {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.juanuribe_imageview-480-3eb41e58f0da4a0ead5367495a10ba24__3RQ0v {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.juanuribe_imageview-481-776ba4ac20da493c89e84d1a016257cb__UMUli {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.juanuribe_imageview-482-d59153fb553d4c1ca68f0f244bba1297__s3ioy {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.juanuribe_layer-557-9b9030d42074402fa32511d440225594__374gi {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.juanuribe_text-546-a480eba05fcf46b280c30573b0f0e0fb__18ueZ {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.juanuribe_text-547-0573eccbf03245078608f01710d2eef7__28wNC {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .juanuribe_Juanuribe__1_Hqh {
    --d8eea0bbe9bd45e69d4e1bd85808d848_visible: flex;
    --378d0915e323439ab47ebbc856ba158b_visible: none;
    --eda976f682894247990ce7aee4929118_visible: none;
    --min-juanuribesection2_valueheight: unset;
    --juanuribesection2_valueheight: fit-content;
  }
}
@media (min-width: 1020px) {
  .juanuribe_Juanuribe__1_Hqh {
    --d8eea0bbe9bd45e69d4e1bd85808d848_visible: none;
    --378d0915e323439ab47ebbc856ba158b_visible: flex;
    --eda976f682894247990ce7aee4929118_visible: flex;
    --min-juanuribesection2_valueheight: unset;
    --juanuribesection2_valueheight: fit-content;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .juanuribe_Juanuribe__1_Hqh {
    --d8eea0bbe9bd45e69d4e1bd85808d848_visible: flex;
    --378d0915e323439ab47ebbc856ba158b_visible: none;
    --eda976f682894247990ce7aee4929118_visible: none;
    --min-juanuribesection2_valueheight: unset;
    --juanuribesection2_valueheight: fit-content;
  }
}

.diegoduran_Diegoduran__-ZCmd {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.diegoduran_diegoduranheader__3Ih_H {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.diegoduran_layer-408-5ae1443e137a46d594d0958799c9be97__3mJ3S {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.diegoduran_imageview-408-572e1caaa61c48da94e281ec0d6cb542__1cMYm {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.diegoduran_layer-409-78292fe88fdc48f1856335473767da21__2EWIf {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--78292fe88fdc48f1856335473767da21_visible);
}
.diegoduran_text-485-f12573a2f82e40e3940ea8477e2476e4__5huxu {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.diegoduran_text-486-df482956286e4c3f8cb82d4728e3d4c7__18Cvw {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.diegoduran_text-487-1874364637ed4ed0b7cd98386814ccd6__1v8d7 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.diegoduran_text-488-ea74bebe27fd41b38deee0c4b9cd3ea9__1p9-- {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.diegoduran_layer-410-13358e3df75644fcba840a2ddbc3673b__1n3cs {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--13358e3df75644fcba840a2ddbc3673b_visible);
}
.diegoduran_text-489-d510bd65515d401699acbe142f0a3684__2N8u_ {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.diegoduran_layer-411-69d985ac8907423195b7b3b251269064__3u9Rx {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--69d985ac8907423195b7b3b251269064_visible);
}
.diegoduran_icon-27-8e4087d129c94584af0d01b42be5798b__1cem8 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.diegoduran_diegoduransection1__Pge4q {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.diegoduran_layer-404-6c2c5df5ba364c2b8c61194658885176__tZMtJ {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.diegoduran_text-476-be69d26650844c90972fc5e23245466a__18-8e {
  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.diegoduran_layer-525-abcaf23a75344645add1b6b0b86fffef__3luoJ {
  border-radius: 15px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.diegoduran_imageview-179-8d2806a41b1a4d2999611897646942fa__2frqP {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.diegoduran_text-477-dc0f3843792a41c892ccc986ca50b885__EHv3O {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.diegoduran_text-478-4148cf31d519482183405fdb74ee8f81__2vB3R {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.diegoduran_imageview-223-863db6b5630a4116b8cba29d047ca4b1__2tbfG {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.diegoduran_diegoduransection2__3oLxR {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-diegoduransection2_valueheight);
  height: var(--diegoduransection2_valueheight);
}
.diegoduran_layer-405-87007fae8488423baafe60f20a949db8__20diV {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.diegoduran_text-479-9b9b04b872724cb08043a1650107c03b__3EMtV {
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.diegoduran_text-480-5d5bf0b3c12a4fb5878e3025432628b6__2bD2q {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.diegoduran_text-481-3d56ebc07e5a4592804a5c48ae50bf55__1cnX0 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.diegoduran_text-482-cc4f030200084665b0f8c5c79c1581d4__3wnip {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.diegoduran_layer-924-0a0a75c6bb1c4dfea48f2c9f19eeaced__iE_TT {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.diegoduran_layer-582-c134d5bfa6014a41a9ebc014dde94fa9__KA3JN {
  flex-shrink: 1;

  --margin-bottom: var(--FooterPaddingMobileV);
  margin-bottom: var(--margin-bottom);
  align-items: var(--c134d5bfa6014a41a9ebc014dde94fa9_horizontalAlignment);
  justify-content: var(--c134d5bfa6014a41a9ebc014dde94fa9_verticalAlignment);
  min-height: var(--min-c134d5bfa6014a41a9ebc014dde94fa9_valueheight);
  height: var(--c134d5bfa6014a41a9ebc014dde94fa9_valueheight);
  min-width: var(--min-c134d5bfa6014a41a9ebc014dde94fa9_valuewidth);
  width: var(--c134d5bfa6014a41a9ebc014dde94fa9_valuewidth);
}
.diegoduran_layer-544-fe5e5912daa043f0ba86594b393be852__2t3Ze {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.diegoduran_imageview-467-d4f78f37369042c6bdf2a82b93ddf306__1ExzJ {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.diegoduran_imageview-468-86d1784a2b6f46e19b152fab36635d1d__1KKwv {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.diegoduran_imageview-469-d2d13a56b857463aa4455615b15d3994__3sipM {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.diegoduran_imageview-470-e7588ce0d34b4412bd5946df7b8e9abe__1wjIc {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.diegoduran_layer-545-014d1353b7034183a8a0607965fb448e__37pT7 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.diegoduran_text-532-400ac49e52f0474a8719bcdc1b7e9a27__3qkOV {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.diegoduran_text-533-d702a5784ae44774a20f41463c6ac1e4__3aAIN {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .diegoduran_Diegoduran__-ZCmd {
    --69d985ac8907423195b7b3b251269064_visible: flex;
    --13358e3df75644fcba840a2ddbc3673b_visible: none;
    --78292fe88fdc48f1856335473767da21_visible: none;
    --min-diegoduransection2_valueheight: unset;
    --diegoduransection2_valueheight: fit-content;
    --c134d5bfa6014a41a9ebc014dde94fa9_horizontalAlignment: center;
    --c134d5bfa6014a41a9ebc014dde94fa9_verticalAlignment: center;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valueheight: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valueheight: fit-content;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: fit-content;
  }
}
@media (min-width: 1020px) {
  .diegoduran_Diegoduran__-ZCmd {
    --69d985ac8907423195b7b3b251269064_visible: none;
    --13358e3df75644fcba840a2ddbc3673b_visible: flex;
    --78292fe88fdc48f1856335473767da21_visible: flex;
    --min-diegoduransection2_valueheight: unset;
    --diegoduransection2_valueheight: 100%;
    --c134d5bfa6014a41a9ebc014dde94fa9_horizontalAlignment: center;
    --c134d5bfa6014a41a9ebc014dde94fa9_verticalAlignment: center;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valueheight: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valueheight: fit-content;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: fit-content;
  }

  .diegoduran_diegoduransection2__3oLxR {
    height: var(--diegoduransection2_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .diegoduran_Diegoduran__-ZCmd {
    --69d985ac8907423195b7b3b251269064_visible: flex;
    --13358e3df75644fcba840a2ddbc3673b_visible: none;
    --78292fe88fdc48f1856335473767da21_visible: none;
    --min-diegoduransection2_valueheight: unset;
    --diegoduransection2_valueheight: fit-content;
    --c134d5bfa6014a41a9ebc014dde94fa9_horizontalAlignment: center;
    --c134d5bfa6014a41a9ebc014dde94fa9_verticalAlignment: center;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valueheight: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valueheight: fit-content;
    --min-c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: unset;
    --c134d5bfa6014a41a9ebc014dde94fa9_valuewidth: fit-content;
  }
}

.mariapaulasuarez_Mariapaulasuarez__3jfXC {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.mariapaulasuarez_mariapaulasuarezheader__3cisA {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.mariapaulasuarez_layer-358-ec09b29029be4a7fb9b9da4a4ea044a9__-Sb41 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.mariapaulasuarez_imageview-424-3c45bb3efd7248239ddff07c7d29ee83__Cm_De {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.mariapaulasuarez_layer-359-2046ccbe99434a76ad5e4e18588922ee__1obJO {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--2046ccbe99434a76ad5e4e18588922ee_visible);
}
.mariapaulasuarez_text-430-784dda8b34a44974b629e04c8938cf5b__2u9Fo {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.mariapaulasuarez_text-431-8a3e8a147f774a1eb31a21752c55da6b__2bMlX {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.mariapaulasuarez_text-432-0064ca0802ba475fbba15f0f8de73331__3470j {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.mariapaulasuarez_text-433-f2e7ea5c37d44eb88cbe5857e1654dec__1rj-P {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.mariapaulasuarez_layer-360-243fd8cce0ca48bcab300875b44cbba1__3eqio {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--243fd8cce0ca48bcab300875b44cbba1_visible);
}
.mariapaulasuarez_text-434-648c11cf40674e9fba17540560008c50__znf8D {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.mariapaulasuarez_layer-361-39585b87e5b741e588985d47a03b5cb4__3tKI4 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--39585b87e5b741e588985d47a03b5cb4_visible);
}
.mariapaulasuarez_icon-23-741d58c4c4ef4202831b864da15a99a6__3CjL3 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.mariapaulasuarez_mariapaulasuarezsection1__3lRI5 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.mariapaulasuarez_layer-356-34e22c53a54b453fb130bb8ba7b58783__3gaGa {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.mariapaulasuarez_text-424-c7a242995bfc48eca15d027fb56890b2__E1UtC {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.mariapaulasuarez_layer-718-a6716922ac72435e979f6769f8b11bce__38Rq9 {
  border-radius: 15px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.mariapaulasuarez_imageview-293-3d81e259e2ee44cea9f61e321ac5674c__2uWgZ {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.mariapaulasuarez_text-425-468b383990854b9ea13aec5c4f4c3e51__1h4tr {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.mariapaulasuarez_text-426-93764309ecc74eba882ba3f3a676afde__15b8- {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.mariapaulasuarez_imageview-195-8678ea65eaac4f7096ced3112e152c58__Dveer {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.mariapaulasuarez_mariapaulasuarezsection2__2PlrS {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-mariapaulasuarezsection2_valueheight);
  height: var(--mariapaulasuarezsection2_valueheight);
}
.mariapaulasuarez_layer-357-d0c48b07d8744e4098f76507ebafe684__1_Thn {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.mariapaulasuarez_text-427-4afb2db17c07458d82590022ac7dbb08__373UM {
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.mariapaulasuarez_text-428-d09f80b28bdb4ff0b14e53f8b5d8e4d7__zcBcm {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.mariapaulasuarez_text-429-01a549cb3a3c4f66965eb2d762dcde61__393FS {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.mariapaulasuarez_layer-950-f634eb808053420b83b6f924c08c4bc7__iSelm {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.mariapaulasuarez_layer-1278-ab3954c8a5f94b7cab5fc9ec277d80f1__fLkYl {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.mariapaulasuarez_layer-1279-26fd37d2452444a7a00496ba074650e8__fu91P {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.mariapaulasuarez_imageview-431-290925f44e2d4533acae26e688bf0f15__193kX {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.mariapaulasuarez_imageview-432-3aa247821d0042a4ad033f5bd9659896__2S6YY {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.mariapaulasuarez_imageview-433-14048161c4a9439987226b99aa20467a__1E9K6 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.mariapaulasuarez_imageview-434-6087d8bc63174fefad8d7c708b70da55__2gHkX {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.mariapaulasuarez_layer-521-a4d94179e5874329afb3ddbb650091d4__2YVm_ {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.mariapaulasuarez_text-505-775ef2dfd7a04e14b1d592afe56f0669__2gP8Z {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.mariapaulasuarez_text-506-34f4e1b174154d629a94e6a0f71a6efc__ce3B3 {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .mariapaulasuarez_Mariapaulasuarez__3jfXC {
    --39585b87e5b741e588985d47a03b5cb4_visible: flex;
    --243fd8cce0ca48bcab300875b44cbba1_visible: none;
    --2046ccbe99434a76ad5e4e18588922ee_visible: none;
    --min-mariapaulasuarezsection2_valueheight: unset;
    --mariapaulasuarezsection2_valueheight: fit-content;
  }
}
@media (min-width: 1020px) {
  .mariapaulasuarez_Mariapaulasuarez__3jfXC {
    --39585b87e5b741e588985d47a03b5cb4_visible: none;
    --243fd8cce0ca48bcab300875b44cbba1_visible: flex;
    --2046ccbe99434a76ad5e4e18588922ee_visible: flex;
    --min-mariapaulasuarezsection2_valueheight: unset;
    --mariapaulasuarezsection2_valueheight: 100%;
  }

  .mariapaulasuarez_mariapaulasuarezsection2__2PlrS {
    height: var(--mariapaulasuarezsection2_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .mariapaulasuarez_Mariapaulasuarez__3jfXC {
    --39585b87e5b741e588985d47a03b5cb4_visible: flex;
    --243fd8cce0ca48bcab300875b44cbba1_visible: none;
    --2046ccbe99434a76ad5e4e18588922ee_visible: none;
    --min-mariapaulasuarezsection2_valueheight: unset;
    --mariapaulasuarezsection2_valueheight: fit-content;
  }
}

.hernanpanesso_Hernanpanesso__ieXex {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hernanpanesso_layer-256-69160b77ff7c4afcb6edc914850bd85d__3oLgA {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.hernanpanesso_layer-312-cc1e1afe86624fbc98998cefd0faf46e__axlJY {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.hernanpanesso_imageview-432-708956f1b6ac4a87b769da12ab4d3b3b__3pBUV {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.hernanpanesso_layer-313-4bc0bf400a204438bbde93b73cdecc9d__2FyPk {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--4bc0bf400a204438bbde93b73cdecc9d_visible);
}
.hernanpanesso_text-379-5d09288329174bac929613d6c9259fc8__1vfAr {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.hernanpanesso_text-380-e32bf2f7a69a45b3870b8f1336c30f9f__1TC9X {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.hernanpanesso_text-381-7923e1a88d514a67b287806e6adcad14__1X_zP {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.hernanpanesso_text-382-d568ae08286a4678a19cb8f87f892642__3xXHx {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.hernanpanesso_layer-314-220552110e93443e826ee398a92e6766__LwDUV {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--220552110e93443e826ee398a92e6766_visible);
}
.hernanpanesso_text-383-b6820d9d8552467fa64ed36977c97ef4__3tuo8 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.hernanpanesso_layer-315-66754efb781c466db105083f1545ef88__3DlFX {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--66754efb781c466db105083f1545ef88_visible);
}
.hernanpanesso_icon-19-9dbef0ae1b7940dcbacaa30bb65ed0d0__3qoFN {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.hernanpanesso_layer-257-f763afc56f2c4d81a6de4406a12a3d8f__1iYzD {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.hernanpanesso_layer-260-033360c0d0b744fe95e579f45c2bea0c__l8fgP {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.hernanpanesso_text-317-caab790961844a7cb3a8a2aea8fdedf5__3uOuk {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.hernanpanesso_layer-705-9ce38905915947fc9183a2d3e0e7706a__2RYAO {
  border-radius: 15px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.hernanpanesso_imageview-285-af2c2e3217ca47cd80fbecc12e341b7f__VrG2r {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.hernanpanesso_text-318-1d8b054d66ef40d58987d1f273966ed0__3tp5F {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.hernanpanesso_text-319-51f8df66e9224acc855ced6f925a4c2e__wei_m {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.hernanpanesso_imageview-142-1b114baef3e04ccdbab392e60bd0e82f___a-Sb {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.hernanpanesso_layer-258-07d7191375b6450c9b131e38a9604078__1Qb2W {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-07d7191375b6450c9b131e38a9604078_valueheight);
  height: var(--07d7191375b6450c9b131e38a9604078_valueheight);
}
.hernanpanesso_layer-261-2965aebb37bd419b8165cccfc0d40abd__2OsvY {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.hernanpanesso_text-320-2d4c4356a06546d1baca97e83859c492__3J2qA {
  min-width: unset;
  width: 100%;
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.hernanpanesso_text-321-5d1dd786bead4d9ba58bb74dbe140e98__3LrRE {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.hernanpanesso_text-322-4483735879fe455e98af2d361eecde1f__3kARN {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.hernanpanesso_text-323-5e807978bf504a6e8e898821369b79fe__2Dpex {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.hernanpanesso_layer-987-01e2a6dd33bb4d2c90c7e46bd5e126f3__1Eoqi {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.hernanpanesso_layer-1235-342a37d10bbd4f498c7809595fd81863__2-ut2 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.hernanpanesso_layer-1236-5254a1e8601f4f77a1ca6dbb908bae35__cy7rN {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.hernanpanesso_imageview-406-59c1d92a66b84a88a1b6adb176721a18__1g95K {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.hernanpanesso_imageview-407-3919c7f0548e43d895085982a4cfbbd4__2pp8F {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.hernanpanesso_imageview-408-729d550c0bd047f9ab196e58d8fa95f2__3DfbC {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.hernanpanesso_imageview-409-ddb97bd61a374f9ab22d07dc118d92cb__3La8F {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.hernanpanesso_layer-311-33c6fc8e43074b5d8addc1d24fbd5a4d__2HuO5 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.hernanpanesso_text-426-37124303ac624203b1164b37d76a00ab__3BKhf {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.hernanpanesso_text-427-b66ee6c3531845199d60796cb854a24b__2neG5 {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .hernanpanesso_Hernanpanesso__ieXex {
    --66754efb781c466db105083f1545ef88_visible: flex;
    --220552110e93443e826ee398a92e6766_visible: none;
    --4bc0bf400a204438bbde93b73cdecc9d_visible: none;
    --min-07d7191375b6450c9b131e38a9604078_valueheight: unset;
    --07d7191375b6450c9b131e38a9604078_valueheight: fit-content;
  }
}
@media (min-width: 1020px) {
  .hernanpanesso_Hernanpanesso__ieXex {
    --66754efb781c466db105083f1545ef88_visible: none;
    --220552110e93443e826ee398a92e6766_visible: flex;
    --4bc0bf400a204438bbde93b73cdecc9d_visible: flex;
    --min-07d7191375b6450c9b131e38a9604078_valueheight: unset;
    --07d7191375b6450c9b131e38a9604078_valueheight: 100%;
  }

  .hernanpanesso_layer-258-07d7191375b6450c9b131e38a9604078__1Qb2W {
    height: var(--07d7191375b6450c9b131e38a9604078_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .hernanpanesso_Hernanpanesso__ieXex {
    --66754efb781c466db105083f1545ef88_visible: flex;
    --220552110e93443e826ee398a92e6766_visible: none;
    --4bc0bf400a204438bbde93b73cdecc9d_visible: none;
    --min-07d7191375b6450c9b131e38a9604078_valueheight: unset;
    --07d7191375b6450c9b131e38a9604078_valueheight: fit-content;
  }
}

.williambolivar_Williambolivar__3gTQg {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.williambolivar_williambolivarheader__hZ-lh {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.williambolivar_layer-252-ecf0be0d955f4c53979d6a7e1e83e7fb__1KxKJ {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.williambolivar_imageview-440-6efe52b891f4430c9e94604157458381__2DkAH {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.williambolivar_layer-253-d4af2f1c8759416fbdc606064469bb21__LRrk2 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--d4af2f1c8759416fbdc606064469bb21_visible);
}
.williambolivar_text-312-6a450b41f863499390267bc521eedfa7__2dU1Y {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.williambolivar_text-313-95023d46d4ce4e4dabfe1b160f6d0f9a__1grkt {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.williambolivar_text-314-3f94d077ffbf470c87c02c160deb5eb6__3mAAR {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.williambolivar_text-315-a1300515531940cc9202b158df08c1d1__1f9bx {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.williambolivar_layer-254-f0d67211411e43e4b7a38529f05f01aa__2Nyf0 {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--f0d67211411e43e4b7a38529f05f01aa_visible);
}
.williambolivar_text-316-b60fe2a422dd40df95c9fa2965de7f7b__1ZXlp {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.williambolivar_layer-255-adbb14c54551470bb38d39fb6ffd7d28__20B6z {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--adbb14c54551470bb38d39fb6ffd7d28_visible);
}
.williambolivar_icon-15-442fd3f82e74454a9f29131268b95698__1tCp5 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.williambolivar_williambolivarsection1__1a-aK {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.williambolivar_layer-2233-990dffa17047412390e33fa7dd4181f3__1T-IJ {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.williambolivar_text-490-4104f362ee5c45ad9d95e6dbf11bac6a__1YDs_ {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.williambolivar_layer-666-d368f305cfa14c4f8cfa12c7a6e3ebca__1Cgbm {
  border-radius: 15px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.williambolivar_imageview-263-1caf787755944cb2b13eec1d7822edd8__38npd {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.williambolivar_text-265-8827ff0b010841f1afc0dbc475b3230e__34iUF {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.williambolivar_text-266-d662d9f9be114a38ae8b0829383c63d0__yZBUw {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.williambolivar_imageview-113-a87d171cde4c4c30bf94df54efb9529f__CD6ox {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.williambolivar_williambolivarsection2__2seTw {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-williambolivarsection2_valueheight);
  height: var(--williambolivarsection2_valueheight);
}
.williambolivar_layer-211-5d282100970249b389f3af1def440796__3P7hE {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.williambolivar_text-267-46fe0f5fdbea431db020277f3546faef__3nKgW {
  min-width: unset;
  width: 100%;
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.williambolivar_text-268-f5363facad1a42d4bb17209f76f9f498__3A74U {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.williambolivar_text-269-1d807ad072104b3ba2e0c4fc3e89aa4a__15XXm {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.williambolivar_layer-1000-4f147b1c63dd466fb0ecb7e391415591__H2XBO {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.williambolivar_layer-1192-e5f7a9e4f0ae4926af34465dbb307a82__1KWiO {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.williambolivar_layer-1193-c1c18c20d3ed423791ea86e920750f25__Rkujd {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.williambolivar_imageview-381-0c118f8bc65c43289dec9dc017a419ad__zjORM {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.williambolivar_imageview-382-fe7415176336447f82885a5de5c908f0__t_1OK {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.williambolivar_imageview-383-767c5b58ff6a44f49fbb88ba056a6370__3TEWa {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.williambolivar_imageview-384-86ffe21bf4db400c803e76ef10c68340__3YyaE {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.williambolivar_layer-443-e156d5ed7ef3408f8c09af245d491f04__3Y9go {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.williambolivar_text-416-2dc0a32f493b4792bdf950d232f02093__o3BTr {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.williambolivar_text-417-4f0d52408020472e9a0168441cb075df__1TiYh {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .williambolivar_Williambolivar__3gTQg {
    --adbb14c54551470bb38d39fb6ffd7d28_visible: flex;
    --f0d67211411e43e4b7a38529f05f01aa_visible: none;
    --d4af2f1c8759416fbdc606064469bb21_visible: none;
    --min-williambolivarsection2_valueheight: unset;
    --williambolivarsection2_valueheight: fit-content;
  }
}
@media (min-width: 1020px) {
  .williambolivar_Williambolivar__3gTQg {
    --adbb14c54551470bb38d39fb6ffd7d28_visible: none;
    --f0d67211411e43e4b7a38529f05f01aa_visible: flex;
    --d4af2f1c8759416fbdc606064469bb21_visible: flex;
    --min-williambolivarsection2_valueheight: unset;
    --williambolivarsection2_valueheight: 100%;
  }

  .williambolivar_williambolivarsection2__2seTw {
    height: var(--williambolivarsection2_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .williambolivar_Williambolivar__3gTQg {
    --adbb14c54551470bb38d39fb6ffd7d28_visible: flex;
    --f0d67211411e43e4b7a38529f05f01aa_visible: none;
    --d4af2f1c8759416fbdc606064469bb21_visible: none;
    --min-williambolivarsection2_valueheight: unset;
    --williambolivarsection2_valueheight: fit-content;
  }
}

.victorcabrera_Victorcabrera__naoLp {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.victorcabrera_victorcabreraheader__2LplU {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.victorcabrera_layer-162-f76647e063074c859d764029f8bda2eb__2p9K1 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.victorcabrera_imageview-483-1fae23b2061a4c3a9ef502c2c2e91cdf__2wtId {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.victorcabrera_layer-163-78977617be0b4c4dbdfaea47882608c7__2EzB1 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--78977617be0b4c4dbdfaea47882608c7_visible);
}
.victorcabrera_text-220-c2fea409058a40949073109cdfc9045d__2P9Ke {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.victorcabrera_text-221-f359bd45f2ec4970809b48252909b7e3__2xZic {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.victorcabrera_text-222-873614e629314188aab31a35ca5f4e4b__2-1S_ {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.victorcabrera_text-223-348655e8195f49ffbb5ea4e7da5a2153__3lD-r {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.victorcabrera_layer-164-e5bb97ca510148a18390de967d6497eb__1whzj {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--e5bb97ca510148a18390de967d6497eb_visible);
}
.victorcabrera_text-224-d5fe0b909b204e96a49e243b4880de6d__26Q1J {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.victorcabrera_layer-165-2ca61d14d8d540c5968f202e8d796c9e__3EUde {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  display: var(--2ca61d14d8d540c5968f202e8d796c9e_visible);
}
.victorcabrera_icon-9-7cf9e14c766448118f4edcd6ac12a905__27LHh {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.victorcabrera_victorcabrerasection1__KVXRt {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.victorcabrera_layer-83-7d9fa6cbe9b54d56b758f9f3a8a81ef5__7Ayot {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.victorcabrera_text-64-19bae4fb3d5a42b7be4c364981e4f025__3-L4O {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.victorcabrera_layer-653-273072f67a5541b5b0c0f1f9fa19a699__28e4J {
  border-radius: 15px;
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.victorcabrera_imageview-255-357d0e52d18e4538a15ea8036571be1e__1ZbXF {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.victorcabrera_text-65-b10316973c2b4f51ab72e1f85f86f739__5YVFf {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.victorcabrera_text-66-653e3e4b2269463a8402af8771efe881__3SVh7 {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.victorcabrera_imageview-37-511362cd79b7487fbb8ebf02544bba6c__17Vi0 {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.victorcabrera_victorcabrerasection2__3qSIX {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-victorcabrerasection2_valueheight);
  height: var(--victorcabrerasection2_valueheight);
}
.victorcabrera_layer-84-b66c3e530a484cf8b460bfb697558d0d__1eeov {
  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: calc(100% - calc(0vw + 0vw));
  flex-shrink: 1;
}
.victorcabrera_text-466-4426ab34186b4a93acd15d9c7790bbb5__AD3Q7 {
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.victorcabrera_text-467-e8db500f5a2048c48b8fa20d67f7565d__3TkUw {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.victorcabrera_text-69-95c8e765e8cc4df69e9e4d334fa9dcd2__2Ww7b {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.victorcabrera_layer-1013-1c754184cdcd419a95cb593d5d8129a2__3MrRF {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.victorcabrera_layer-1124-1c9d39f2ae2a4f82823eda8da9493e0e__36dB7 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.victorcabrera_layer-160-53ba65cbbba24b4aba8db6fd8c2b78fe__2F1Fz {
  flex-direction: row;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.victorcabrera_imageview-342-30ce53719c1542a5b3868cea6505a8df__Ns7Mx {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.victorcabrera_imageview-343-6e04a094f0fd41da8cecbf26f498d034__myc_m {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.victorcabrera_imageview-344-662ff4aae3d84ce8976c996528a85441__1wIj4 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.victorcabrera_imageview-345-cb55caffd4c84f71ad41f3f007e6992e__1WarH {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
}
.victorcabrera_layer-161-0c01ec5f527d461baa40f63857a6c346__2c9Qt {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: -moz-fit-content;
  height: fit-content;
  min-width: unset;
  width: -moz-fit-content;
  width: fit-content;
  flex-shrink: 1;
}
.victorcabrera_text-218-fa4bc7aaf53e49c183a011c42e68a645__2xCjE {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.victorcabrera_text-219-8af7f286e30746b08eb6729ca903addc__1Rigo {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .victorcabrera_Victorcabrera__naoLp {
    --2ca61d14d8d540c5968f202e8d796c9e_visible: flex;
    --e5bb97ca510148a18390de967d6497eb_visible: none;
    --78977617be0b4c4dbdfaea47882608c7_visible: none;
    --min-victorcabrerasection2_valueheight: unset;
    --victorcabrerasection2_valueheight: fit-content;
  }
}
@media (min-width: 1020px) {
  .victorcabrera_Victorcabrera__naoLp {
    --2ca61d14d8d540c5968f202e8d796c9e_visible: none;
    --e5bb97ca510148a18390de967d6497eb_visible: flex;
    --78977617be0b4c4dbdfaea47882608c7_visible: flex;
    --min-victorcabrerasection2_valueheight: unset;
    --victorcabrerasection2_valueheight: 100%;
  }

  .victorcabrera_victorcabrerasection2__3qSIX {
    height: var(--victorcabrerasection2_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .victorcabrera_Victorcabrera__naoLp {
    --2ca61d14d8d540c5968f202e8d796c9e_visible: flex;
    --e5bb97ca510148a18390de967d6497eb_visible: none;
    --78977617be0b4c4dbdfaea47882608c7_visible: none;
    --min-victorcabrerasection2_valueheight: unset;
    --victorcabrerasection2_valueheight: fit-content;
  }
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("/assets/Poppins-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Mulish-ExtraBold";
  src: url("/assets/Mulish-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Mulish-Medium";
  src: url("/assets/Mulish-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Mulish-Bold";
  src: url("/assets/Mulish-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Mulish-Regular";
  src: url("/assets/Mulish-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Light";
  src: url("/assets/Poppins-Light.ttf");
  font-display: swap;
}

body,
html,
#root {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Noto Sans",
    Helvetica,
    Roboto,
    Oxygen,
    Arial,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

section,
div,
nav,
header,
footer,
article,
aside {
  display: flex;
  position: static;
  box-sizing: border-box;
  contain: paint;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100px;
  min-height: 100px;
  width: 300px;
  min-width: 300px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
ol,
ul {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Roboto",
    sans-serif;
  font-size: 18px;
  background: #000000;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-clip: text;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

img {
  box-sizing: border-box;
  object-fit: contain;
  height: 90px;
  min-height: 90px;
  width: 115px;
  min-width: 115px;
}

span {
  box-sizing: border-box;
  font-family: "Material Icons";
  background: #000000;
  background-clip: text;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 30px;
}

:root {
  --FontExtraBold: Mulish-ExtraBold;
  --FontBold: Mulish-Bold;
  --FontMedium: Mulish-Medium;
  --FontRegular: Mulish-Regular;
  --FontLight: Poppins-Light;
  --HeaderLogo: /assets/bavieraLogo.png;
  --HeaderText1: Nosotros;
  --HeaderText2: Equipo;
  --HeaderText3: Áreas de Práctica;
  --HeaderText4: Actualidad;
  --HeadertText5: Contacto;
  --HeaderText6: Agenda tu Capacitación;
  --HeaderIconMobile: ;
  --FooterText1: Política de tratamiento de datos;
  --FooterText2: Copyrights 2023 © Baviera Legal;
  --SMediaLinkedIngAzul: /assets/linkedin-Blue.png;
  --SMediaLinkedIngBlack: /assets/linkedin-Black.png;
  --SMediaMail: /assets/email.png;
  --SMediaPhone: /assets/llamada-telefonica.png;
  --SMediaInstagram: /assets/instagram.png;
  --min-ImageSize1: 175px;
  --ImageSize1: 175px;
  --min-ImageSize2: 150px;
  --ImageSize2: 150px;
  --min-ImageSize3: 125px;
  --ImageSize3: 125px;
  --min-ImageSize4: 100px;
  --ImageSize4: 100px;
  --min-ItemMargin1H: 12vw;
  --ItemMargin1H: 12vw;
  --min-ItemMargin2H: 6vw;
  --ItemMargin2H: 6vw;
  --min-ItemMargin3H: 3vw;
  --ItemMargin3H: 3vw;
  --min-SMediaSize1: 25px;
  --SMediaSize1: 25px;
  --min-SMediaSize2: 20px;
  --SMediaSize2: 20px;
  --min-SMediaSize3: 15px;
  --SMediaSize3: 15px;
  --min-ItemMargin4H: 1vw;
  --ItemMargin4H: 1vw;
}

@media (max-width: 480px) {
  :root {
    --min-HeaderPaddingH: 6vw;
    --HeaderPaddingH: 6vw;
    --min-HeaderPaddingV: 0vh;
    --HeaderPaddingV: 0vh;
    --min-HeaderLogoSize: 200px;
    --HeaderLogoSize: 200px;
    --min-HeaderTextSize: 16px;
    --HeaderTextSize: 16px;
    --min-HeaderTextMarginH: 4vw;
    --HeaderTextMarginH: 4vw;
    --min-ButtonPaddingV: 6px;
    --ButtonPaddingV: 6px;
    --min-ButtonPaddingH: 16px;
    --ButtonPaddingH: 16px;
    --min-ButtonRadius: 18px;
    --ButtonRadius: 18px;
    --min-FooterSMediaSize: 28px;
    --FooterSMediaSize: 28px;
    --min-FooterTextSize: 14px;
    --FooterTextSize: 14px;
    --min-TittleTextSize1: 35px;
    --TittleTextSize1: 35px;
    --min-TittleTextSize2: 30px;
    --TittleTextSize2: 30px;
    --min-TittleTextSize3: 20px;
    --TittleTextSize3: 20px;
    --min-TittleTextSize4: 18px;
    --TittleTextSize4: 18px;
    --min-ParagraphTextSize1: 16px;
    --ParagraphTextSize1: 16px;
    --min-ParagraphTextSize2: 14px;
    --ParagraphTextSize2: 14px;
    --min-ParagraphTextSize3: 12px;
    --ParagraphTextSize3: 12px;
    --min-ParagraphTextSize4: 10px;
    --ParagraphTextSize4: 10px;
    --min-ItemMargin1V: 5vh;
    --ItemMargin1V: 5vh;
    --min-ItemMargin2V: 3vh;
    --ItemMargin2V: 3vh;
    --min-ItemMargin3V: 1vh;
    --ItemMargin3V: 1vh;
    --MarcaPrimaryColor: #beac84;
    --MarcaSecondaryColor: #26374d;
    --MarcaTertiaryColor: #eeeff0;
    --MarcaAcentoColor: #528cb0;
    --PrimaryColor: #ffffff;
    --SecondaryColor: #000000;
    --TertiaryColor: #6a6a6a;
    --min-FooterSizeV: 21vh;
    --FooterSizeV: 21vh;
    --min-FooterItemMarginH: 10vw;
    --FooterItemMarginH: 10vw;
    --min-FooterPaddingMobileV: 6vh;
    --FooterPaddingMobileV: 6vh;
    --min-HeaderSizeV: 10vh;
    --HeaderSizeV: 10vh;
  }
}
@media (min-width: 1020px) {
  :root {
    --MarcaPrimaryColor: #beac84;
    --MarcaSecondaryColor: #26374d;
    --MarcaTertiaryColor: #eeeff0;
    --MarcaAcentoColor: #528cb0;
    --PrimaryColor: #ffffff;
    --SecondaryColor: #000000;
    --TertiaryColor: #e0e0e0;
    --min-HeaderSizeV: 10vh;
    --HeaderSizeV: 10vh;
    --min-HeaderPaddingH: 6vw;
    --HeaderPaddingH: 6vw;
    --min-HeaderPaddingV: 0vh;
    --HeaderPaddingV: 0vh;
    --min-HeaderLogoSize: 200px;
    --HeaderLogoSize: 200px;
    --min-HeaderTextSize: 16px;
    --HeaderTextSize: 16px;
    --min-HeaderTextMarginH: 4vw;
    --HeaderTextMarginH: 4vw;
    --min-ButtonPaddingV: 6px;
    --ButtonPaddingV: 6px;
    --min-ButtonPaddingH: 16px;
    --ButtonPaddingH: 16px;
    --min-ButtonRadius: 18px;
    --ButtonRadius: 18px;
    --min-FooterSMediaSize: 20px;
    --FooterSMediaSize: 20px;
    --min-FooterTextSize: 14px;
    --FooterTextSize: 14px;
    --min-TittleTextSize1: 45px;
    --TittleTextSize1: 45px;
    --min-TittleTextSize2: 30px;
    --TittleTextSize2: 30px;
    --min-TittleTextSize3: 25px;
    --TittleTextSize3: 25px;
    --min-TittleTextSize4: 20px;
    --TittleTextSize4: 20px;
    --min-ParagraphTextSize1: 18px;
    --ParagraphTextSize1: 18px;
    --min-ParagraphTextSize2: 16px;
    --ParagraphTextSize2: 16px;
    --min-ParagraphTextSize3: 14px;
    --ParagraphTextSize3: 14px;
    --min-ParagraphTextSize4: 12px;
    --ParagraphTextSize4: 12px;
    --min-ItemMargin1V: 6vh;
    --ItemMargin1V: 6vh;
    --min-ItemMargin2V: 3vh;
    --ItemMargin2V: 3vh;
    --min-ItemMargin3V: 1vh;
    --ItemMargin3V: 1vh;
    --min-FooterSizeV: 12vh;
    --FooterSizeV: 12vh;
    --min-FooterItemMarginH: 2vw;
    --FooterItemMarginH: 2vw;
    --min-FooterPaddingMobileV: 0vh;
    --FooterPaddingMobileV: 0vh;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  :root {
    --MarcaSecondaryColor: #26374d;
    --MarcaAcentoColor: #528cb0;
    --PrimaryColor: #ffffff;
    --TertiaryColor: #6a6a6a;
    --min-FooterItemMarginH: 4vw;
    --FooterItemMarginH: 4vw;
    --min-FooterSMediaSize: 24px;
    --FooterSMediaSize: 24px;
    --MarcaPrimaryColor: #beac84;
    --MarcaTertiaryColor: #eeeff0;
    --SecondaryColor: #000000;
    --min-HeaderSizeV: 10vh;
    --HeaderSizeV: 10vh;
    --min-HeaderPaddingH: 6vw;
    --HeaderPaddingH: 6vw;
    --min-HeaderPaddingV: 0vh;
    --HeaderPaddingV: 0vh;
    --min-HeaderLogoSize: 180px;
    --HeaderLogoSize: 180px;
    --min-HeaderTextSize: 16px;
    --HeaderTextSize: 16px;
    --min-HeaderTextMarginH: 4vw;
    --HeaderTextMarginH: 4vw;
    --min-ButtonPaddingV: 6px;
    --ButtonPaddingV: 6px;
    --min-ButtonPaddingH: 16px;
    --ButtonPaddingH: 16px;
    --min-ButtonRadius: 18px;
    --ButtonRadius: 18px;
    --min-TittleTextSize1: 45px;
    --TittleTextSize1: 45px;
    --min-FooterTextSize: 14px;
    --FooterTextSize: 14px;
    --min-TittleTextSize2: 30px;
    --TittleTextSize2: 30px;
    --min-TittleTextSize3: 25px;
    --TittleTextSize3: 25px;
    --min-TittleTextSize4: 20px;
    --TittleTextSize4: 20px;
    --min-ParagraphTextSize1: 18px;
    --ParagraphTextSize1: 18px;
    --min-ParagraphTextSize2: 16px;
    --ParagraphTextSize2: 16px;
    --min-ParagraphTextSize3: 14px;
    --ParagraphTextSize3: 14px;
    --min-ParagraphTextSize4: 12px;
    --ParagraphTextSize4: 12px;
    --min-ItemMargin2V: 3vh;
    --ItemMargin2V: 3vh;
    --min-ItemMargin1V: 6vh;
    --ItemMargin1V: 6vh;
    --min-ItemMargin3V: 1vh;
    --ItemMargin3V: 1vh;
    --min-FooterSizeV: 12vh;
    --FooterSizeV: 12vh;
    --min-FooterPaddingMobileV: 0vh;
    --FooterPaddingMobileV: 0vh;
  }
}

