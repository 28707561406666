.Juanuribe {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.juanuribeheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.layer-417-d009988779ca475eb4fc461dd6e77381 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-400-2f842c6045ba42ae995be882b581134d {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-418-eda976f682894247990ce7aee4929118 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--eda976f682894247990ce7aee4929118_visible);
}
.text-493-91215a6a12504d6c82d7e5d2d9909290 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-494-51edc3c53e6d462eb72b1b7488761945 {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-495-db8292533abb401c8a61e9b1ff9f1c3e {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-496-84d7ca59da024322984d6266493e9cc3 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-419-378d0915e323439ab47ebbc856ba158b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--378d0915e323439ab47ebbc856ba158b_visible);
}
.text-497-bb4347cb29e94dbea8f1c4d7719b72d2 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-420-d8eea0bbe9bd45e69d4e1bd85808d848 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--d8eea0bbe9bd45e69d4e1bd85808d848_visible);
}
.icon-28-047b0ca5fbc44e93bc71b40cd09def28 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.juanuribesection1 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.layer-416-e80b2a621a5942079d957209576b2fbb {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-490-707f7c3f30aa4da7846183bb8e7d935d {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.layer-538-2884212a797240a9bf79cd8f19376366 {
  border-radius: 15px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-187-b3ccd1dc3dff4652b8360b31417fbd54 {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.text-491-a4aee9f2fd0b4562bb2b45edd91f43bd {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.text-492-1221ce0c43124231b2cac20898da362d {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-230-4965780287c74801a235d3ca3563ca66 {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.juanuribesection2 {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-juanuribesection2_valueheight);
  height: var(--juanuribesection2_valueheight);
}
.layer-421-754eb401f28e4a5e9a4a3a2b1916419b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.text-498-8898d8110fb54d1694d2be667050fb42 {
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  margin-bottom: var(--ItemMargin2V);
}
.text-499-7f70943f92a14ba9a0615ceda9992b44 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.text-500-3be6da8393424528ab656f06503f8572 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.text-501-de5bfd88c94c4fe08a7a7437eeb87e70 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
}
.layer-911-244198e60ca04e619062d04e9d7d4825 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-1349-a7cceab913fa4545a3df97e70191e510 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-556-02b115e0bfb244908d03ac66bf855fed {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-479-bcae51498abe4266b0616ed866ec1bb9 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-480-3eb41e58f0da4a0ead5367495a10ba24 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-481-776ba4ac20da493c89e84d1a016257cb {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-482-d59153fb553d4c1ca68f0f244bba1297 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-557-9b9030d42074402fa32511d440225594 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-546-a480eba05fcf46b280c30573b0f0e0fb {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-547-0573eccbf03245078608f01710d2eef7 {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .Juanuribe {
    --d8eea0bbe9bd45e69d4e1bd85808d848_visible: flex;
    --378d0915e323439ab47ebbc856ba158b_visible: none;
    --eda976f682894247990ce7aee4929118_visible: none;
    --min-juanuribesection2_valueheight: unset;
    --juanuribesection2_valueheight: fit-content;
  }
}
@media (min-width: 1020px) {
  .Juanuribe {
    --d8eea0bbe9bd45e69d4e1bd85808d848_visible: none;
    --378d0915e323439ab47ebbc856ba158b_visible: flex;
    --eda976f682894247990ce7aee4929118_visible: flex;
    --min-juanuribesection2_valueheight: unset;
    --juanuribesection2_valueheight: fit-content;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Juanuribe {
    --d8eea0bbe9bd45e69d4e1bd85808d848_visible: flex;
    --378d0915e323439ab47ebbc856ba158b_visible: none;
    --eda976f682894247990ce7aee4929118_visible: none;
    --min-juanuribesection2_valueheight: unset;
    --juanuribesection2_valueheight: fit-content;
  }
}
