.Seguridadysalud {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.seguridadysaludheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.layer-648-a89a154cc8ca4736b42da2b8af508242 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-371-ecb22591fcbb42ffb904dc8083e2fc8f {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-649-feaef8f5d1184c1d93e556864db82e5a {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--feaef8f5d1184c1d93e556864db82e5a_visible);
}
.text-551-3c0b77885128471bbcb40ed8ef4af0ee {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-552-333536eddf814e619193bd31a74500ca {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-553-01c143ee9f5e4dd49214da842c0f4a72 {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-554-586d02b3c88045e1b48f1d5b71dd2b12 {
  font-family: var(--FontBold);
  font-size: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.layer-650-1fc2f18bfab54595a6d1c220072f682f {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--1fc2f18bfab54595a6d1c220072f682f_visible);
}
.text-555-5142e1adfa1c4279a039b9fcec3c1526 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-651-879177f25ca644939ce8c61e36083f99 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--879177f25ca644939ce8c61e36083f99_visible);
}
.icon-23-31ec0fdeb6784b11a1f5baeeac75634f {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.seguridadysaludsection1 {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Seguridad y Salud en el Trabajo_square.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.text-549-a6c003f713774254af099102d6b130ae {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.seguridadysaludsection2 {
  --verticalAxis-seguridadysaludsection2: center;
  --horizontalAxis-seguridadysaludsection2: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  flex-direction: var(--seguridadysaludsection2_orientation);
  min-height: var(--min-seguridadysaludsection2_valueheight);
  height: var(--seguridadysaludsection2_valueheight);
}
.layer-366-7a1fd54a29564db6857595ba516dece5 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9 {
  --verticalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--14a6bf7dd0b14db7a52cecdb935e1ac9_orientation);
  --horizontalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9: var(
    --14a6bf7dd0b14db7a52cecdb935e1ac9_horizontalAlignment
  );
}
.layer-677-e83f0dd71ed34a2a8e93abac67d38cc0 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-682-92420c29d7eb48fe8565a7b7c29a395a {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-557-7c0177875ec4454aa6693277e24a070c {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-681-69586c71e8a24b8084710b2813b6e2b7 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-556-beca734d743d48aab5d12dba4ad1abde {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-678-b3801fddd61e44019e27dc47f0c6b800 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-683-685bf3f5451e40b4bea2efcdf6ce0572 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-555-e0094f4ff34a43f2af02fb251947f03b {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-684-57aa3cab63e94a2c9a16e8b1036808ef {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-554-a892731dc51c4c0d88bdb3b699809f93 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-679-204f5355a47247d1b8ff58e0010e98a0 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-685-5e8925d9913d404480fd7f4ef0af64d4 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-553-d185ff09fabd420f88ff8fc2f76a7bbf {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-686-1aa1a3f07632479183ca834bc03c5ecd {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-552-92c43fb035b746fb94fbea922098b02d {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-756-90a7ae03186e422b9a373018255acec6 {
  --verticalAxis-90a7ae03186e422b9a373018255acec6: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--90a7ae03186e422b9a373018255acec6_orientation);
  --horizontalAxis-90a7ae03186e422b9a373018255acec6: var(
    --90a7ae03186e422b9a373018255acec6_horizontalAlignment
  );
}
.layer-680-31b7a027473545f7b13decf439489628 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-687-ffc6387f32bc4db69d24f6c874afba11 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-551-f06b936a42254449a65c1ac3289edf7f {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-688-7a3d6f22c49a49a0a561f762ea3ec1a5 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-550-d5a87fd96a85422ca905844b93846e38 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-689-54475ac3c88446ab89edb87d3ceb7e6a {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-692-b18ace6a147a43439f482bec847af92c {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-563-40a8c43e4bc2405ba7116ea239ca0a8f {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-693-ce1e18bb48064c078d7d9fff95349cec {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-562-cb0535a830db4aa3b7b863fea1e9142e {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-690-a5a987ab712d49fa91d5e817d57f1652 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-694-bc85b49450904f3b8b5e2e1c096dc464 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-561-679062f3a29a47aba036df91c3bf5596 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-695-98e60de1f80d46c4bd520ecf8a8a958b {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-560-b8851a30fc234525b0a450c9eeeedb66 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-757-53bed54837e343aba9d269dd1802dd19 {
  --verticalAxis-53bed54837e343aba9d269dd1802dd19: center;
  --horizontalAxis-53bed54837e343aba9d269dd1802dd19: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--53bed54837e343aba9d269dd1802dd19_orientation);
}
.layer-691-3c13aa8593ac4db4a414f80438f158fa {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-696-0f85d8eea48b4a15adff54cb428e200e {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-558-a2e0672e09534d9e86700252a21822ee {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-697-c0469288876942d49bf7d5c69178ad37 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-559-74e0e671fe0e45ffb33eac7238096e9f {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-857-d74227c239e6450490d222b3070b6baa {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-984-ec89b7891dc748eaa0229edac40ccaf0 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-677-9696947ef7564802ba11b37302674a7e {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-312-7b5bb868fd344543ad163c15ef46f951 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-313-aa62bce87f4a4ca69a084cf66998f40b {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-314-f82c4eb2eddb4b269d9dd4f2db1ce46d {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-315-318f403a5b424e4ca8646f4e38ccd1a9 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-678-a60467f42fae482fb142bb11ba42c6c5 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-639-ec6b0dc92e5b476fbe2c79db575c64e9 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-640-405a9fa1baa7424ea6a33abdd949c465 {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.seguridadysaludsection2-orientation {
  align-items: var(--verticalAxis-seguridadysaludsection2);
  justify-content: var(--horizontalAxis-seguridadysaludsection2);
}
.layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9-orientation {
  align-items: var(--verticalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
  justify-content: var(--horizontalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
}
.layer-756-90a7ae03186e422b9a373018255acec6-orientation {
  align-items: var(--verticalAxis-90a7ae03186e422b9a373018255acec6);
  justify-content: var(--horizontalAxis-90a7ae03186e422b9a373018255acec6);
}
.layer-757-53bed54837e343aba9d269dd1802dd19-orientation {
  align-items: var(--verticalAxis-53bed54837e343aba9d269dd1802dd19);
  justify-content: var(--horizontalAxis-53bed54837e343aba9d269dd1802dd19);
}
@media (max-width: 480px) {
  .Seguridadysalud {
    --879177f25ca644939ce8c61e36083f99_visible: flex;
    --1fc2f18bfab54595a6d1c220072f682f_visible: none;
    --feaef8f5d1184c1d93e556864db82e5a_visible: none;
    --seguridadysaludsection2_orientation: column;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_orientation: column;
    --90a7ae03186e422b9a373018255acec6_orientation: column;
    --53bed54837e343aba9d269dd1802dd19_orientation: column;
    --90a7ae03186e422b9a373018255acec6_horizontalAlignment: center;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_horizontalAlignment: center;
    --min-seguridadysaludsection2_valueheight: unset;
    --seguridadysaludsection2_valueheight: fit-content;
  }

  .seguridadysaludsection2-orientation {
    align-items: var(--horizontalAxis-seguridadysaludsection2);
    justify-content: var(--verticalAxis-seguridadysaludsection2);
  }
  .layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9-orientation {
    align-items: var(--horizontalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
    justify-content: var(--verticalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
  }
  .layer-756-90a7ae03186e422b9a373018255acec6-orientation {
    align-items: var(--horizontalAxis-90a7ae03186e422b9a373018255acec6);
    justify-content: var(--verticalAxis-90a7ae03186e422b9a373018255acec6);
  }
  .layer-757-53bed54837e343aba9d269dd1802dd19-orientation {
    align-items: var(--horizontalAxis-53bed54837e343aba9d269dd1802dd19);
    justify-content: var(--verticalAxis-53bed54837e343aba9d269dd1802dd19);
  }
}
@media (min-width: 1020px) {
  .Seguridadysalud {
    --879177f25ca644939ce8c61e36083f99_visible: none;
    --1fc2f18bfab54595a6d1c220072f682f_visible: flex;
    --feaef8f5d1184c1d93e556864db82e5a_visible: flex;
    --seguridadysaludsection2_orientation: row;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_orientation: row;
    --90a7ae03186e422b9a373018255acec6_orientation: row;
    --53bed54837e343aba9d269dd1802dd19_orientation: row;
    --90a7ae03186e422b9a373018255acec6_horizontalAlignment: space-between;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_horizontalAlignment: space-between;
    --min-seguridadysaludsection2_valueheight: 60vh;
    --seguridadysaludsection2_valueheight: 60vh;
  }

  .seguridadysaludsection2-orientation {
    align-items: var(--verticalAxis-seguridadysaludsection2);
    justify-content: var(--horizontalAxis-seguridadysaludsection2);
  }
  .layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9-orientation {
    align-items: var(--verticalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
    justify-content: var(--horizontalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
  }
  .layer-756-90a7ae03186e422b9a373018255acec6-orientation {
    align-items: var(--verticalAxis-90a7ae03186e422b9a373018255acec6);
    justify-content: var(--horizontalAxis-90a7ae03186e422b9a373018255acec6);
  }
  .layer-757-53bed54837e343aba9d269dd1802dd19-orientation {
    align-items: var(--verticalAxis-53bed54837e343aba9d269dd1802dd19);
    justify-content: var(--horizontalAxis-53bed54837e343aba9d269dd1802dd19);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Seguridadysalud {
    --879177f25ca644939ce8c61e36083f99_visible: flex;
    --1fc2f18bfab54595a6d1c220072f682f_visible: none;
    --feaef8f5d1184c1d93e556864db82e5a_visible: none;
    --seguridadysaludsection2_orientation: column;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_orientation: column;
    --90a7ae03186e422b9a373018255acec6_orientation: column;
    --53bed54837e343aba9d269dd1802dd19_orientation: column;
    --90a7ae03186e422b9a373018255acec6_horizontalAlignment: center;
    --14a6bf7dd0b14db7a52cecdb935e1ac9_horizontalAlignment: center;
    --min-seguridadysaludsection2_valueheight: unset;
    --seguridadysaludsection2_valueheight: fit-content;
  }

  .seguridadysaludsection2-orientation {
    align-items: var(--horizontalAxis-seguridadysaludsection2);
    justify-content: var(--verticalAxis-seguridadysaludsection2);
  }
  .layer-755-14a6bf7dd0b14db7a52cecdb935e1ac9-orientation {
    align-items: var(--horizontalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
    justify-content: var(--verticalAxis-14a6bf7dd0b14db7a52cecdb935e1ac9);
  }
  .layer-756-90a7ae03186e422b9a373018255acec6-orientation {
    align-items: var(--horizontalAxis-90a7ae03186e422b9a373018255acec6);
    justify-content: var(--verticalAxis-90a7ae03186e422b9a373018255acec6);
  }
  .layer-757-53bed54837e343aba9d269dd1802dd19-orientation {
    align-items: var(--horizontalAxis-53bed54837e343aba9d269dd1802dd19);
    justify-content: var(--verticalAxis-53bed54837e343aba9d269dd1802dd19);
  }
}
