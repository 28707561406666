.Derechomigratorio {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.derechomigratorioheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.layer-550-a643fc926e194856b2708bff5c77b884 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-365-40bddfe92ca54c259a94a71ba1cf68ce {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-551-52961a92d33f4fecb02a129be64e38cd {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--52961a92d33f4fecb02a129be64e38cd_visible);
}
.text-491-8149e67896ae48059b8490497d0d99c6 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-492-3d8cec224528416f8351fb5b5e0dcb4c {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-493-9f4f92869d114263a56723f2cf79c5bb {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-494-901bae9a95934e3382591272d6b3980c {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-552-b0505bd0ad3441a0b5fa9f5820f072a2 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--b0505bd0ad3441a0b5fa9f5820f072a2_visible);
}
.text-495-586af7d7511e4e0db72fc0467e54f54a {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-553-b4e0efbdb6fc41aa92290d58b9a257bf {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--b4e0efbdb6fc41aa92290d58b9a257bf_visible);
}
.icon-20-29cb79f09bf84d1c8f6391908e7b5c65 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.derechomigratoriosection1 {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Derecho migratorio_square.png");
  height: 30vh;
  min-height: 30vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.text-531-ea488e30006e4bf085edd96529a67bd4 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.derechomigratoriosection2 {
  --verticalAxis-derechomigratoriosection2: center;
  --horizontalAxis-derechomigratoriosection2: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  flex-direction: var(--derechomigratoriosection2_orientation);
  min-height: var(--min-derechomigratoriosection2_valueheight);
  height: var(--derechomigratoriosection2_valueheight);
}
.layer-371-5428df8aee6b48d596744c8d06b3698d {
  --verticalAxis-5428df8aee6b48d596744c8d06b3698d: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--5428df8aee6b48d596744c8d06b3698d_orientation);
  --horizontalAxis-5428df8aee6b48d596744c8d06b3698d: var(
    --5428df8aee6b48d596744c8d06b3698d_horizontalAlignment
  );
}
.layer-649-83806fe25a7e4397a69e82635c68ec87 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-652-f8816cf22bb74341a6c50bbb5b4cb1d6 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-532-c4b6d478f66646a7b521b98db1c51407 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-653-b9f444621f144ad780e037b649f79e4d {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-533-6513a6a5c301484f9b119ca291c21717 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-650-c67564b0ef724e7daa115197b4f7b0d4 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-654-e71ddb15a90b432f9b153f4310dccac5 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-534-b8d6e81f634342aab89edfd7cc13577c {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-655-dadd02af35754d1692128c3d73cbae71 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-535-bd4db502a46141608239f7a18e465373 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-651-52ce67d0d34f4051a35ac29dfe5388ac {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-656-286e899173c8418da31bb81c219e82ea {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-536-9df851b15163487c8f60a155cc8885a5 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-657-dfb52a25652b4e2686d8021c8d3037ef {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-537-24a3033d07454d99aabd5908b7512801 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-648-68d20573e02a4ceb93f420e140ef567b {
  --verticalAxis-68d20573e02a4ceb93f420e140ef567b: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--68d20573e02a4ceb93f420e140ef567b_orientation);
  --horizontalAxis-68d20573e02a4ceb93f420e140ef567b: var(
    --68d20573e02a4ceb93f420e140ef567b_horizontalAlignment
  );
}
.layer-658-65e65b1b592d48959c3250bb1b836fe2 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-665-7c78862ad30b44d1a6decd836b54b54e {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-538-6ea098651a9c4962b8ca4b97e92b7349 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-666-15eb0ef26662425098a19f501189566c {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-539-ec439e0dfdeb4edb9589c3daefb3d010 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-659-b1a725ac905e4dd487a1740199d5c95e {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-663-841746c7cf0c4082ba7f8d35ea166c8f {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-540-c087a3305f4047109ad6f97177f7d3c3 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-664-2b5f0128f09e40c69eb08c52036ca9b9 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-541-13c6aa86ee384d25bdd976b4ac4b9291 {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-660-8a54567e830f42cd90637ae9b19f9dd8 {
  flex-direction: row;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 265px;
  min-width: 265px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin2V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-661-86e45902613e45d5b29f968339d332bb {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-right: var(--ItemMargin4H);
}
.text-542-891c5e140fe341c89fa0c340c0c8ef23 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-662-21e516fa56cd48c39dcd79767702dce9 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-543-0040f6d5f88c4b03bf339009be387fef {
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-821-e4672b4541dd4c11a2d1f4dc7b3e5b20 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-948-276ca5f55c234f01b9b498a8725c5f68 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-971-f08dd6871d31412cbe18b2c460aacdf2 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-303-2ce9f008d218437b87760dcc00f38b56 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-304-b57e7ec332734da1984ef87f68175be4 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-305-ebea9ba3637f4f38baf72ca357449d1d {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-306-49dc7bad0a8b4add9b6449db576cc6f2 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-972-de21e149839142458e52f2926a9c6bd2 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-835-9a9db24532bb4f0aa63cb4ad1cb91add {
  font-family: var(--FontBold);
  font-size: var(--FooterTextSize);
}
.text-836-e94b03f7e4c64ecfadcda8c65e3a5dcb {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.derechomigratoriosection2-orientation {
  align-items: var(--horizontalAxis-derechomigratoriosection2);
  justify-content: var(--verticalAxis-derechomigratoriosection2);
}
.layer-371-5428df8aee6b48d596744c8d06b3698d-orientation {
  align-items: var(--verticalAxis-5428df8aee6b48d596744c8d06b3698d);
  justify-content: var(--horizontalAxis-5428df8aee6b48d596744c8d06b3698d);
}
.layer-648-68d20573e02a4ceb93f420e140ef567b-orientation {
  align-items: var(--verticalAxis-68d20573e02a4ceb93f420e140ef567b);
  justify-content: var(--horizontalAxis-68d20573e02a4ceb93f420e140ef567b);
}
@media (max-width: 480px) {
  .Derechomigratorio {
    --b4e0efbdb6fc41aa92290d58b9a257bf_visible: flex;
    --b0505bd0ad3441a0b5fa9f5820f072a2_visible: none;
    --52961a92d33f4fecb02a129be64e38cd_visible: none;
    --5428df8aee6b48d596744c8d06b3698d_orientation: column;
    --68d20573e02a4ceb93f420e140ef567b_orientation: column;
    --5428df8aee6b48d596744c8d06b3698d_horizontalAlignment: center;
    --68d20573e02a4ceb93f420e140ef567b_horizontalAlignment: center;
    --derechomigratoriosection2_orientation: column;
    --min-derechomigratoriosection2_valueheight: unset;
    --derechomigratoriosection2_valueheight: fit-content;
  }

  .derechomigratoriosection2-orientation {
    align-items: var(--horizontalAxis-derechomigratoriosection2);
    justify-content: var(--verticalAxis-derechomigratoriosection2);
  }
  .layer-371-5428df8aee6b48d596744c8d06b3698d-orientation {
    align-items: var(--horizontalAxis-5428df8aee6b48d596744c8d06b3698d);
    justify-content: var(--verticalAxis-5428df8aee6b48d596744c8d06b3698d);
  }
  .layer-648-68d20573e02a4ceb93f420e140ef567b-orientation {
    align-items: var(--horizontalAxis-68d20573e02a4ceb93f420e140ef567b);
    justify-content: var(--verticalAxis-68d20573e02a4ceb93f420e140ef567b);
  }
}
@media (min-width: 1020px) {
  .Derechomigratorio {
    --b4e0efbdb6fc41aa92290d58b9a257bf_visible: none;
    --b0505bd0ad3441a0b5fa9f5820f072a2_visible: flex;
    --52961a92d33f4fecb02a129be64e38cd_visible: flex;
    --5428df8aee6b48d596744c8d06b3698d_orientation: row;
    --68d20573e02a4ceb93f420e140ef567b_orientation: row;
    --5428df8aee6b48d596744c8d06b3698d_horizontalAlignment: space-between;
    --68d20573e02a4ceb93f420e140ef567b_horizontalAlignment: space-between;
    --derechomigratoriosection2_orientation: column;
    --min-derechomigratoriosection2_valueheight: 60vh;
    --derechomigratoriosection2_valueheight: 60vh;
  }

  .derechomigratoriosection2-orientation {
    align-items: var(--horizontalAxis-derechomigratoriosection2);
    justify-content: var(--verticalAxis-derechomigratoriosection2);
  }
  .layer-371-5428df8aee6b48d596744c8d06b3698d-orientation {
    align-items: var(--verticalAxis-5428df8aee6b48d596744c8d06b3698d);
    justify-content: var(--horizontalAxis-5428df8aee6b48d596744c8d06b3698d);
  }
  .layer-648-68d20573e02a4ceb93f420e140ef567b-orientation {
    align-items: var(--verticalAxis-68d20573e02a4ceb93f420e140ef567b);
    justify-content: var(--horizontalAxis-68d20573e02a4ceb93f420e140ef567b);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Derechomigratorio {
    --b4e0efbdb6fc41aa92290d58b9a257bf_visible: flex;
    --b0505bd0ad3441a0b5fa9f5820f072a2_visible: none;
    --52961a92d33f4fecb02a129be64e38cd_visible: none;
    --5428df8aee6b48d596744c8d06b3698d_orientation: column;
    --68d20573e02a4ceb93f420e140ef567b_orientation: column;
    --5428df8aee6b48d596744c8d06b3698d_horizontalAlignment: center;
    --68d20573e02a4ceb93f420e140ef567b_horizontalAlignment: center;
    --derechomigratoriosection2_orientation: column;
    --min-derechomigratoriosection2_valueheight: unset;
    --derechomigratoriosection2_valueheight: fit-content;
  }

  .derechomigratoriosection2-orientation {
    align-items: var(--horizontalAxis-derechomigratoriosection2);
    justify-content: var(--verticalAxis-derechomigratoriosection2);
  }
  .layer-371-5428df8aee6b48d596744c8d06b3698d-orientation {
    align-items: var(--horizontalAxis-5428df8aee6b48d596744c8d06b3698d);
    justify-content: var(--verticalAxis-5428df8aee6b48d596744c8d06b3698d);
  }
  .layer-648-68d20573e02a4ceb93f420e140ef567b-orientation {
    align-items: var(--horizontalAxis-68d20573e02a4ceb93f420e140ef567b);
    justify-content: var(--verticalAxis-68d20573e02a4ceb93f420e140ef567b);
  }
}
