.Victorcabrera {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.victorcabreraheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.layer-162-f76647e063074c859d764029f8bda2eb {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-483-1fae23b2061a4c3a9ef502c2c2e91cdf {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-163-78977617be0b4c4dbdfaea47882608c7 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--78977617be0b4c4dbdfaea47882608c7_visible);
}
.text-220-c2fea409058a40949073109cdfc9045d {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-221-f359bd45f2ec4970809b48252909b7e3 {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-222-873614e629314188aab31a35ca5f4e4b {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-223-348655e8195f49ffbb5ea4e7da5a2153 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-164-e5bb97ca510148a18390de967d6497eb {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--e5bb97ca510148a18390de967d6497eb_visible);
}
.text-224-d5fe0b909b204e96a49e243b4880de6d {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-165-2ca61d14d8d540c5968f202e8d796c9e {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--2ca61d14d8d540c5968f202e8d796c9e_visible);
}
.icon-9-7cf9e14c766448118f4edcd6ac12a905 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.victorcabrerasection1 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.layer-83-7d9fa6cbe9b54d56b758f9f3a8a81ef5 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-64-19bae4fb3d5a42b7be4c364981e4f025 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.layer-653-273072f67a5541b5b0c0f1f9fa19a699 {
  border-radius: 15px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-255-357d0e52d18e4538a15ea8036571be1e {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.text-65-b10316973c2b4f51ab72e1f85f86f739 {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.text-66-653e3e4b2269463a8402af8771efe881 {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-37-511362cd79b7487fbb8ebf02544bba6c {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.victorcabrerasection2 {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-victorcabrerasection2_valueheight);
  height: var(--victorcabrerasection2_valueheight);
}
.layer-84-b66c3e530a484cf8b460bfb697558d0d {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: calc(100% - calc(0vw + 0vw));
  flex-shrink: 1;
}
.text-466-4426ab34186b4a93acd15d9c7790bbb5 {
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.text-467-e8db500f5a2048c48b8fa20d67f7565d {
  font-family: var(--FontMedium);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.text-69-95c8e765e8cc4df69e9e4d334fa9dcd2 {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-1013-1c754184cdcd419a95cb593d5d8129a2 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-1124-1c9d39f2ae2a4f82823eda8da9493e0e {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-160-53ba65cbbba24b4aba8db6fd8c2b78fe {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-342-30ce53719c1542a5b3868cea6505a8df {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-343-6e04a094f0fd41da8cecbf26f498d034 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-344-662ff4aae3d84ce8976c996528a85441 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-345-cb55caffd4c84f71ad41f3f007e6992e {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
}
.layer-161-0c01ec5f527d461baa40f63857a6c346 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-218-fa4bc7aaf53e49c183a011c42e68a645 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-219-8af7f286e30746b08eb6729ca903addc {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .Victorcabrera {
    --2ca61d14d8d540c5968f202e8d796c9e_visible: flex;
    --e5bb97ca510148a18390de967d6497eb_visible: none;
    --78977617be0b4c4dbdfaea47882608c7_visible: none;
    --min-victorcabrerasection2_valueheight: unset;
    --victorcabrerasection2_valueheight: fit-content;
  }
}
@media (min-width: 1020px) {
  .Victorcabrera {
    --2ca61d14d8d540c5968f202e8d796c9e_visible: none;
    --e5bb97ca510148a18390de967d6497eb_visible: flex;
    --78977617be0b4c4dbdfaea47882608c7_visible: flex;
    --min-victorcabrerasection2_valueheight: unset;
    --victorcabrerasection2_valueheight: 100%;
  }

  .victorcabrerasection2 {
    height: var(--victorcabrerasection2_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Victorcabrera {
    --2ca61d14d8d540c5968f202e8d796c9e_visible: flex;
    --e5bb97ca510148a18390de967d6497eb_visible: none;
    --78977617be0b4c4dbdfaea47882608c7_visible: none;
    --min-victorcabrerasection2_valueheight: unset;
    --victorcabrerasection2_valueheight: fit-content;
  }
}
