.Mobilemenu {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.mobilemenuheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.layer-70-877b56a3b9a04fadb5465069d3263fd2 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-202-7184976d1c664e8dabfcbfe6cf10a1f0 {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-71-88da2b854d2f4e57bac0120f564dab4e {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.icon-3-7bd413645027488dbbc8ee6093395b0f {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.mobilemenusection1 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/High-light Towers Munich.png");
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.mobilemenusections {
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  height: 50vh;
  min-height: 50vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.layer-72-3f049c0123b541d8a4f7e310332cfd98 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-59-88b328d2847c43d6a918ee18b36030cc {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin2V);
}
.text-60-19f7f8b675284670b6c30d2a8d2fcf41 {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin2V);
}
.text-61-1f11c897ae804526a9f6554f1dcfbaf3 {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin2V);
}
.text-62-2ed4b81989d941688f6a5dbc7dc0f8bc {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
  margin-bottom: var(--ItemMargin2V);
}
.layer-74-49f97056fd894b4d84460b167ae61355 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-bottom: var(--ButtonPaddingV);
  padding-top: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
}
.text-63-26ef4549af7748be9fd7ac1e017f384e {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
