.Home {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.homeheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-right: var(--HeaderPaddingH);
  padding-left: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.layer-2-3d08f9ba4b6d44e38b52a6eea671db40 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.bavieralogo {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-3-5e6ce39d6d51493a8669ba30f486bc31 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--5e6ce39d6d51493a8669ba30f486bc31_visible);
}
.text-2-61d7d9db557943988ee7cbf6c7a87f19 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-3-5a304adcd3564e858c39be3eddb21239 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-4-8fcc6e377d604e30a70030835dc3dfa1 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-5-af6cbc7d5d3d4c83b1261c529fc185b7 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-4-753cd528892f4768a0ac5e207115f7a6 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--753cd528892f4768a0ac5e207115f7a6_visible);
}
.layer-6-e5e47c53dbc444899e1ace36ef78a685 {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
}
.text-1-55678b833689471cab623acf2f26ea0d {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-5-11f9ece7ab4b46a88674e64e8c7a7ce2 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--11f9ece7ab4b46a88674e64e8c7a7ce2_visible);
}
.icon-1-c7c1bcc9531d4248a041d32a759fcd23 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.homesection1 {
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/High-light Towers Munich.png");
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.layer-461-2e55c1429e5b47f39764cbab51cf03d5 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.text-73-be9b44557e13492faadcc957037911c3 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.text-74-6eec02498f594789be456d4430358849 {
  text-align: center;

  font-family: var(--FontMedium);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
  margin-left: var(--ItemMargin1H);
  margin-right: var(--ItemMargin1H);
}
.text-75-35e2d7494ef24839ba839307d19d9b4a {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--ParagraphTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.layer-88-2d54f9ce2a4141bcb83086f6a796c529 {
  border-radius: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
}
.text-76-dfb9d1c386e04375b44eb1f148694a46 {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.homesection2 {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--PrimaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-homesection2_valueheight);
  height: var(--homesection2_valueheight);
}
.layer-460-41f4ea6174ea4e2cbdf539db1c80ff23 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
}
.text-412-7d170979998248e29e5d343ef7fa5da3 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-10-b5b2031d9d95455da1ee29c59104c389 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--b5b2031d9d95455da1ee29c59104c389_orientation);
  --horizontalAxis-b5b2031d9d95455da1ee29c59104c389: var(
    --b5b2031d9d95455da1ee29c59104c389_horizontalAlignment
  );
}
.layer-12-617745cc5f5a439fafbe093b5d77b90b {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
}
.layer-547-9f0d99d76dbe4ad880577e7253117e71 {
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-198-09246f266ec04f5d94553b60f47a0e9c {
  min-width: var(--min-ImageSize4);
  width: var(--ImageSize4);
  min-height: var(--min-ImageSize4);
  height: var(--ImageSize4);
}
.text-7-a7ac179f78fc4b6194c754482f8ba3a0 {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-13-375e0f56ba91470dbf8498e41aa04564 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
}
.layer-548-56e92fe039794446a2535372370edabf {
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-199-8235e661781e47aab37b57a78772b172 {
  min-height: var(--min-ImageSize4);
  height: var(--ImageSize4);
  min-width: var(--min-ImageSize4);
  width: var(--ImageSize4);
}
.text-8-e17bf5425694494a919c8a9185b3b203 {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-14-677393b17ba0472a9774252111d7e1ae {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
}
.layer-549-9fe93dd87a814651aaec4a8f866ddacc {
  border-radius: 10px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-200-943d490e929e49d18002877202cd039e {
  min-height: var(--min-ImageSize4);
  height: var(--ImageSize4);
  min-width: var(--min-ImageSize4);
  width: var(--ImageSize4);
}
.text-9-f7e726d5d02545c2821fd206d8dd11af {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-15-d9bb8bd34c7d466daa9000ffa20449ee {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  min-width: var(--min-d9bb8bd34c7d466daa9000ffa20449ee_valuewidth);
  width: var(--d9bb8bd34c7d466daa9000ffa20449ee_valuewidth);
}
.layer-550-736b9152a4db43708377401c5b4611a9 {
  border-radius: 10px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-201-1afee32b0e7d4cadbf0207185760f4f7 {
  min-height: var(--min-ImageSize4);
  height: var(--ImageSize4);
  min-width: var(--min-ImageSize4);
  width: var(--ImageSize4);
}
.text-10-b5f74ed610464260a65c68fc529c7d7d {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-16-02c9afd51dc940ac8cc71d1ecda2c342 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  min-width: var(--min-02c9afd51dc940ac8cc71d1ecda2c342_valuewidth);
  width: var(--02c9afd51dc940ac8cc71d1ecda2c342_valuewidth);
}
.layer-551-f9e26648344b491d8b41aca519c1fc30 {
  border-radius: 10px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-202-8029b3f9f7f5493aa13a9028379a3d48 {
  min-width: var(--min-ImageSize4);
  width: var(--ImageSize4);
  min-height: var(--min-ImageSize4);
  height: var(--ImageSize4);
}
.text-11-54678da62af048c9a48c289ce046d471 {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.homesection3 {
  --horizontalAxis-homesection3: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  flex-direction: var(--homesection3_orientation);
  min-height: var(--min-homesection3_valueheight);
  height: var(--homesection3_valueheight);
}
.layer-463-fd5f7689f3de4b8caf6cc004c7ac10e5 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
}
.text-413-8907e6bd3afd4633892247b99a4114ad {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-462-005da6786f074e03b60ff6ce70a6093e {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--005da6786f074e03b60ff6ce70a6093e_orientation);
  --horizontalAxis-005da6786f074e03b60ff6ce70a6093e: var(
    --005da6786f074e03b60ff6ce70a6093e_horizontalAlignment
  );
}
.layer-47-b340f6e15b6243878f7770afc59489b3 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  margin-right: var(--ItemMargin2H);
  margin-left: var(--ItemMargin2H);
}
.layer-905-a11a01210c7e47aaa9aa9383c96bbbc5 {
  border-radius: 10px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-284-7ec07b741a8d490eaaebe57ab0cc478e {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-30-d2ca6f1e7c294abf98da5c5e4d58ce5d {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.layer-51-79e347ccf3104a498b25e704559487e3 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.layer-909-971eb83f20734f30b38a476b1dab4610 {
  border-radius: 10px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-288-999a499f799b4216abc09ec1bc8bcfa1 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-33-22a2d02845bb4d0897a61ba5d7bbf191 {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.layer-49-eed115637b6d48c894db29338fc01e4d {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  margin-right: var(--ItemMargin2H);
  margin-left: var(--ItemMargin2H);
}
.layer-907-3f6aea514aa2478d96c146c5528a006d {
  border-radius: 10px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-286-36f8e54109804ee297de89c828cf29ac {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-31-4ef663560d984ff2b64b9441063997be {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.layer-911-0c007086943c4da381594c0dd9140c1d {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.layer-915-7de783995fe5403ebc5190e896b84761 {
  border-radius: 10px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-290-636a2808d7fb4118a2721946df42a87c {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-737-4a23114c1d434b88badd0d96318ced17 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.layer-910-09075171494d4fe8b95fde1bd80e72a6 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.layer-914-d545e576c9ff4b73bf29e271c41b830d {
  border-radius: 10px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-289-34d8b82f1bbb4bca976c3af4d5bcc069 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-736-559bcbe524704bf0b39b1b6ca64ed92f {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.layer-50-c8596553f8084f7c8d7c33984f3eb2ca {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  margin-right: var(--ItemMargin2H);
  margin-left: var(--ItemMargin2H);
}
.layer-908-4c9e25658e69423c89d6a2f97dd251ee {
  border-radius: 10px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-287-8bda81d1be7e47aead79e20fee3003c0 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-32-c0ff9bd68fda4420ac2b649434213f28 {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.layer-529-c35806fb7cc344e6acee5becb851237b {
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.layer-533-c77b6d3ee4c940b096b27242f7581cd9 {
  border-radius: 10px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-172-4c356a1dfb594e21b4553de568e3b6ff {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-420-bce4d0ccbcdd4202ae7488794c57fcfe {
  font-weight: bold;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.layer-913-99ba4ff0683c459ab446e25e3a31329d {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.layer-917-3c59f69d8ce4473090fa10f4ca006e6a {
  border-radius: 10px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-292-3c0de2a151d546608a30bc899edb6cb4 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-739-0bd843d194c74a15981f438e1f05e10e {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.layer-48-3249c65d8d004ca4bbf6b5b42410ed38 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin1V);
  margin-top: var(--ItemMargin1V);
  margin-right: var(--ItemMargin2H);
  margin-left: var(--ItemMargin2H);
}
.layer-906-14e159096b18422f8980f85e41a20caf {
  border-radius: 10px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-285-13c68cddd5f54222a46dc5564a563603 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-29-99e8482831ae4fe0876b5208d61d2e80 {
  text-align: center;

  width: 150px;
  min-width: 150px;
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.layer-912-5a38091f55d24642a9331d332b154b07 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.layer-916-c6527fd139834091a97927affb593888 {
  border-radius: 10px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-291-a09853d6e4ad448b95b7b83b226ed3c7 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-738-a4a73fe769cc475eb064a7273c3c5b36 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.layer-530-22a115fd7b4747e6a94b58701a808a80 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  margin-left: var(--ItemMargin2H);
  margin-right: var(--ItemMargin2H);
}
.layer-532-6a6c0aa65da54989aa6b65cda366f7d1 {
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-173-62b0a8e116dc4a42a52fd21a9061023c {
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
}
.text-421-da9bd6f8f03a4932940baf60b8f3b3af {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize4);
}
.homesection4 {
  --verticalAxis-homesection4: center;
  --horizontalAxis-homesection4: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  flex-direction: var(--homesection4_orientation);
  min-height: var(--min-homesection4_valueheight);
  height: var(--homesection4_valueheight);
}
.layer-465-0e48bb8d134647028876f5c8cde732eb {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
}
.text-414-d4172df906634e6d986018dbab71f624 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-500-70b50d7b57e44903ba3f11d67d038022 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--70b50d7b57e44903ba3f11d67d038022_orientation);
  --horizontalAxis-70b50d7b57e44903ba3f11d67d038022: var(
    --70b50d7b57e44903ba3f11d67d038022_horizontalAlignment
  );
  --verticalAxis-70b50d7b57e44903ba3f11d67d038022: var(
    --70b50d7b57e44903ba3f11d67d038022_verticalAlignment
  );
}
.layer-572-4d457ed652c44e91a41ef6198816c00f {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  width: 250px;
  min-width: 250px;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
}
.layer-464-afa2a5cde3864fe88ba599abc6067850 {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 1px 10px #e0e0e0;
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin2V);
}
.imageview-164-3854a3065ac34686863a563a3d3741ff {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.layer-573-f2a163794c2f424a89a2e31635df2100 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-34-d1c8c28dc3dd4ee48b3102715f049ab4 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.text-35-ac987fe3aedf4e0a82603430b1d2ea48 {
  text-align: center;

  width: 100px;
  min-width: 100px;
  font-family: var(--FontMedium);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.text-38-62dbffd0adbc4123ae7d512d71e34ca3 {
  text-align: center;

  margin-left: 15px;
  box-sizing: border-box;

  min-width: unset;
  width: calc(100% - calc(15px + 0px));
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.layer-54-0978b6d59d64488db55e7c5c8215035a {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  min-width: var(--min-0978b6d59d64488db55e7c5c8215035a_valuewidth);
  width: var(--0978b6d59d64488db55e7c5c8215035a_valuewidth);
}
.layer-545-13de03b1d2f94b559674539a1bca3202 {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin2V);
}
.imageview-196-2a970db8d9c44706806f2711bc2fbb63 {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.text-40-8c2b570dedda4e39bfe5a990ff9f5b94 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.text-41-340af16347db4b00b99909e19981ace7 {
  text-align: center;

  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.text-42-a6e3d9fa016f4a81b8ca897e4a7f34b2 {
  text-align: center;

  margin-left: 15px;
  box-sizing: border-box;

  min-width: unset;
  width: calc(100% - calc(15px + 0px));
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.layer-53-0508c285e0364557aa843a3003f85a12 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin1V);
  min-width: var(--min-0508c285e0364557aa843a3003f85a12_valuewidth);
  width: var(--0508c285e0364557aa843a3003f85a12_valuewidth);
}
.layer-546-070167c8d5ef4c6d91542a7e0dcadfe2 {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin2V);
}
.imageview-197-6606eeeb479d45b1b0047eee77339248 {
  min-width: var(--min-ImageSize2);
  width: var(--ImageSize2);
  min-height: var(--min-ImageSize2);
  height: var(--ImageSize2);
}
.text-36-894be80911dd4819ad1ba7f50c98f8fc {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.text-37-5c162c7952b14e509f8bff8ed5f2283b {
  text-align: center;

  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.text-39-1de9c3c58cf6404c82353a08d7125c29 {
  text-align: center;

  margin-left: 15px;
  box-sizing: border-box;

  min-width: unset;
  width: calc(100% - calc(15px + 0px));
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.homesection5 {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--PrimaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-homesection5_valueheight);
  height: var(--homesection5_valueheight);
}
.layer-464-e44e86c9a03a44c598b7967f7fb0ee8c {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
}
.text-412-f65b8e716df84f809599a24c660e98f4 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize2);
}
.layer-3108-feca6ee1f9534104ac857129a21a7d1f {
  min-height: unset;
  height: calc(100% - calc(var(--ItemMargin1V) + var(--ItemMargin1V)));

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
}
.homefooter {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-465-ae4a5b0dbc9e4006bc0572d4154f66c7 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
  align-items: var(--ae4a5b0dbc9e4006bc0572d4154f66c7_horizontalAlignment);
  justify-content: var(--ae4a5b0dbc9e4006bc0572d4154f66c7_verticalAlignment);
}
.layer-481-41ee69d9b1374da9ae51cd716a8e6dfe {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-164-00d2003f8801484abf7064d16b4c4f1b {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-165-47bd3700c7a3465e9acfd5860ff7c506 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-166-ed46fa2d855b4bc3bf6aace80db974a2 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-167-d37a205ae13644a7970ad3d8d26561a7 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-467-df3802d767a345d4bca59e0b3f7f49ca {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-43-3b6d765ee7624927a76a7302e1e10daa {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-106-81287af6f601496ab2ed4c44b05488d6 {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.layer-10-b5b2031d9d95455da1ee29c59104c389-orientation {
  align-items: var(--horizontalAxis-b5b2031d9d95455da1ee29c59104c389);
  justify-content: var(--verticalAxis-b5b2031d9d95455da1ee29c59104c389);
}
.homesection3-orientation {
  align-items: var(--horizontalAxis-homesection3);
  justify-content: var(--verticalAxis-homesection3);
}
.layer-462-005da6786f074e03b60ff6ce70a6093e-orientation {
  align-items: var(--horizontalAxis-005da6786f074e03b60ff6ce70a6093e);
  justify-content: var(--verticalAxis-005da6786f074e03b60ff6ce70a6093e);
}
.homesection4-orientation {
  align-items: var(--horizontalAxis-homesection4);
  justify-content: var(--verticalAxis-homesection4);
}
.layer-500-70b50d7b57e44903ba3f11d67d038022-orientation {
  align-items: var(--horizontalAxis-70b50d7b57e44903ba3f11d67d038022);
  justify-content: var(--verticalAxis-70b50d7b57e44903ba3f11d67d038022);
}
@media (max-width: 480px) {
  .Home {
    --5e6ce39d6d51493a8669ba30f486bc31_visible: none;
    --753cd528892f4768a0ac5e207115f7a6_visible: none;
    --11f9ece7ab4b46a88674e64e8c7a7ce2_visible: flex;
    --b5b2031d9d95455da1ee29c59104c389_orientation: column;
    --homesection3_orientation: column;
    --homesection4_orientation: column;
    --b5b2031d9d95455da1ee29c59104c389_horizontalAlignment: center;
    --min-homesection2_valueheight: unset;
    --homesection2_valueheight: fit-content;
    --min-d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: unset;
    --d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: fit-content;
    --min-02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: unset;
    --02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: fit-content;
    --min-homesection3_valueheight: unset;
    --homesection3_valueheight: fit-content;
    --min-homesection4_valueheight: unset;
    --homesection4_valueheight: fit-content;
    --70b50d7b57e44903ba3f11d67d038022_orientation: column;
    --70b50d7b57e44903ba3f11d67d038022_horizontalAlignment: center;
    --70b50d7b57e44903ba3f11d67d038022_verticalAlignment: center;
    --min-0978b6d59d64488db55e7c5c8215035a_valuewidth: unset;
    --0978b6d59d64488db55e7c5c8215035a_valuewidth: 100%;
    --min-0508c285e0364557aa843a3003f85a12_valuewidth: unset;
    --0508c285e0364557aa843a3003f85a12_valuewidth: 100%;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_horizontalAlignment: center;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_verticalAlignment: center;
    --005da6786f074e03b60ff6ce70a6093e_orientation: column;
    --005da6786f074e03b60ff6ce70a6093e_horizontalAlignment: center;
    --min-homesection5_valueheight: 75vh;
    --homesection5_valueheight: 75vh;
  }

  .layer-10-b5b2031d9d95455da1ee29c59104c389-orientation {
    align-items: var(--horizontalAxis-b5b2031d9d95455da1ee29c59104c389);
    justify-content: var(--verticalAxis-b5b2031d9d95455da1ee29c59104c389);
  }
  .homesection3-orientation {
    align-items: var(--horizontalAxis-homesection3);
    justify-content: var(--verticalAxis-homesection3);
  }
  .layer-462-005da6786f074e03b60ff6ce70a6093e-orientation {
    align-items: var(--horizontalAxis-005da6786f074e03b60ff6ce70a6093e);
    justify-content: var(--verticalAxis-005da6786f074e03b60ff6ce70a6093e);
  }
  .homesection4-orientation {
    align-items: var(--horizontalAxis-homesection4);
    justify-content: var(--verticalAxis-homesection4);
  }
  .layer-500-70b50d7b57e44903ba3f11d67d038022-orientation {
    align-items: var(--horizontalAxis-70b50d7b57e44903ba3f11d67d038022);
    justify-content: var(--verticalAxis-70b50d7b57e44903ba3f11d67d038022);
  }
  .layer-54-0978b6d59d64488db55e7c5c8215035a {
    width: var(--0978b6d59d64488db55e7c5c8215035a_valuewidth);
  }
  .layer-53-0508c285e0364557aa843a3003f85a12 {
    width: var(--0508c285e0364557aa843a3003f85a12_valuewidth);
  }
}
@media (min-width: 1020px) {
  .Home {
    --5e6ce39d6d51493a8669ba30f486bc31_visible: flex;
    --753cd528892f4768a0ac5e207115f7a6_visible: flex;
    --11f9ece7ab4b46a88674e64e8c7a7ce2_visible: none;
    --b5b2031d9d95455da1ee29c59104c389_orientation: row;
    --homesection3_orientation: column;
    --homesection4_orientation: column;
    --b5b2031d9d95455da1ee29c59104c389_horizontalAlignment: space-between;
    --min-homesection2_valueheight: unset;
    --homesection2_valueheight: fit-content;
    --min-d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: unset;
    --d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: fit-content;
    --min-02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: unset;
    --02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: fit-content;
    --min-homesection3_valueheight: unset;
    --homesection3_valueheight: fit-content;
    --min-homesection4_valueheight: unset;
    --homesection4_valueheight: fit-content;
    --70b50d7b57e44903ba3f11d67d038022_orientation: row;
    --70b50d7b57e44903ba3f11d67d038022_horizontalAlignment: space-between;
    --70b50d7b57e44903ba3f11d67d038022_verticalAlignment: flex-start;
    --min-0978b6d59d64488db55e7c5c8215035a_valuewidth: 250px;
    --0978b6d59d64488db55e7c5c8215035a_valuewidth: 250px;
    --min-0508c285e0364557aa843a3003f85a12_valuewidth: 250px;
    --0508c285e0364557aa843a3003f85a12_valuewidth: 250px;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_horizontalAlignment: center;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_verticalAlignment: center;
    --005da6786f074e03b60ff6ce70a6093e_orientation: row;
    --005da6786f074e03b60ff6ce70a6093e_horizontalAlignment: flex-start;
    --min-homesection5_valueheight: 90vh;
    --homesection5_valueheight: 90vh;
  }

  .layer-10-b5b2031d9d95455da1ee29c59104c389-orientation {
    align-items: var(--verticalAxis-b5b2031d9d95455da1ee29c59104c389);
    justify-content: var(--horizontalAxis-b5b2031d9d95455da1ee29c59104c389);
  }
  .homesection3-orientation {
    align-items: var(--horizontalAxis-homesection3);
    justify-content: var(--verticalAxis-homesection3);
  }
  .layer-462-005da6786f074e03b60ff6ce70a6093e-orientation {
    align-items: var(--verticalAxis-005da6786f074e03b60ff6ce70a6093e);
    justify-content: var(--horizontalAxis-005da6786f074e03b60ff6ce70a6093e);
  }
  .homesection4-orientation {
    align-items: var(--horizontalAxis-homesection4);
    justify-content: var(--verticalAxis-homesection4);
  }
  .layer-500-70b50d7b57e44903ba3f11d67d038022-orientation {
    align-items: var(--verticalAxis-70b50d7b57e44903ba3f11d67d038022);
    justify-content: var(--horizontalAxis-70b50d7b57e44903ba3f11d67d038022);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Home {
    --5e6ce39d6d51493a8669ba30f486bc31_visible: none;
    --753cd528892f4768a0ac5e207115f7a6_visible: none;
    --11f9ece7ab4b46a88674e64e8c7a7ce2_visible: flex;
    --b5b2031d9d95455da1ee29c59104c389_orientation: column;
    --homesection3_orientation: column;
    --homesection4_orientation: column;
    --b5b2031d9d95455da1ee29c59104c389_horizontalAlignment: center;
    --min-homesection2_valueheight: unset;
    --homesection2_valueheight: fit-content;
    --min-d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: unset;
    --d9bb8bd34c7d466daa9000ffa20449ee_valuewidth: fit-content;
    --min-02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: unset;
    --02c9afd51dc940ac8cc71d1ecda2c342_valuewidth: fit-content;
    --min-homesection3_valueheight: unset;
    --homesection3_valueheight: fit-content;
    --min-homesection4_valueheight: unset;
    --homesection4_valueheight: fit-content;
    --70b50d7b57e44903ba3f11d67d038022_orientation: column;
    --70b50d7b57e44903ba3f11d67d038022_horizontalAlignment: center;
    --70b50d7b57e44903ba3f11d67d038022_verticalAlignment: flex-start;
    --min-0978b6d59d64488db55e7c5c8215035a_valuewidth: 290px;
    --0978b6d59d64488db55e7c5c8215035a_valuewidth: 290px;
    --min-0508c285e0364557aa843a3003f85a12_valuewidth: 290px;
    --0508c285e0364557aa843a3003f85a12_valuewidth: 290px;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_horizontalAlignment: center;
    --ae4a5b0dbc9e4006bc0572d4154f66c7_verticalAlignment: center;
    --005da6786f074e03b60ff6ce70a6093e_orientation: column;
    --005da6786f074e03b60ff6ce70a6093e_horizontalAlignment: center;
    --min-homesection5_valueheight: 90vh;
    --homesection5_valueheight: 90vh;
  }

  .layer-10-b5b2031d9d95455da1ee29c59104c389-orientation {
    align-items: var(--horizontalAxis-b5b2031d9d95455da1ee29c59104c389);
    justify-content: var(--verticalAxis-b5b2031d9d95455da1ee29c59104c389);
  }
  .homesection3-orientation {
    align-items: var(--horizontalAxis-homesection3);
    justify-content: var(--verticalAxis-homesection3);
  }
  .layer-462-005da6786f074e03b60ff6ce70a6093e-orientation {
    align-items: var(--horizontalAxis-005da6786f074e03b60ff6ce70a6093e);
    justify-content: var(--verticalAxis-005da6786f074e03b60ff6ce70a6093e);
  }
  .homesection4-orientation {
    align-items: var(--horizontalAxis-homesection4);
    justify-content: var(--verticalAxis-homesection4);
  }
  .layer-500-70b50d7b57e44903ba3f11d67d038022-orientation {
    align-items: var(--horizontalAxis-70b50d7b57e44903ba3f11d67d038022);
    justify-content: var(--verticalAxis-70b50d7b57e44903ba3f11d67d038022);
  }
}
