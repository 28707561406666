.Williambolivar {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.williambolivarheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.layer-252-ecf0be0d955f4c53979d6a7e1e83e7fb {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-440-6efe52b891f4430c9e94604157458381 {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-253-d4af2f1c8759416fbdc606064469bb21 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--d4af2f1c8759416fbdc606064469bb21_visible);
}
.text-312-6a450b41f863499390267bc521eedfa7 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-313-95023d46d4ce4e4dabfe1b160f6d0f9a {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-314-3f94d077ffbf470c87c02c160deb5eb6 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-315-a1300515531940cc9202b158df08c1d1 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-254-f0d67211411e43e4b7a38529f05f01aa {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-right: var(--ButtonPaddingH);
  padding-left: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--f0d67211411e43e4b7a38529f05f01aa_visible);
}
.text-316-b60fe2a422dd40df95c9fa2965de7f7b {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-255-adbb14c54551470bb38d39fb6ffd7d28 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--adbb14c54551470bb38d39fb6ffd7d28_visible);
}
.icon-15-442fd3f82e74454a9f29131268b95698 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.williambolivarsection1 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.layer-2233-990dffa17047412390e33fa7dd4181f3 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-490-4104f362ee5c45ad9d95e6dbf11bac6a {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.layer-666-d368f305cfa14c4f8cfa12c7a6e3ebca {
  border-radius: 15px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-263-1caf787755944cb2b13eec1d7822edd8 {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.text-265-8827ff0b010841f1afc0dbc475b3230e {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.text-266-d662d9f9be114a38ae8b0829383c63d0 {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-113-a87d171cde4c4c30bf94df54efb9529f {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.williambolivarsection2 {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-williambolivarsection2_valueheight);
  height: var(--williambolivarsection2_valueheight);
}
.layer-211-5d282100970249b389f3af1def440796 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.text-267-46fe0f5fdbea431db020277f3546faef {
  min-width: unset;
  width: 100%;
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.text-268-f5363facad1a42d4bb17209f76f9f498 {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.text-269-1d807ad072104b3ba2e0c4fc3e89aa4a {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-1000-4f147b1c63dd466fb0ecb7e391415591 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-1192-e5f7a9e4f0ae4926af34465dbb307a82 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-1193-c1c18c20d3ed423791ea86e920750f25 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-381-0c118f8bc65c43289dec9dc017a419ad {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-382-fe7415176336447f82885a5de5c908f0 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-383-767c5b58ff6a44f49fbb88ba056a6370 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-384-86ffe21bf4db400c803e76ef10c68340 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-443-e156d5ed7ef3408f8c09af245d491f04 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-416-2dc0a32f493b4792bdf950d232f02093 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-417-4f0d52408020472e9a0168441cb075df {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .Williambolivar {
    --adbb14c54551470bb38d39fb6ffd7d28_visible: flex;
    --f0d67211411e43e4b7a38529f05f01aa_visible: none;
    --d4af2f1c8759416fbdc606064469bb21_visible: none;
    --min-williambolivarsection2_valueheight: unset;
    --williambolivarsection2_valueheight: fit-content;
  }
}
@media (min-width: 1020px) {
  .Williambolivar {
    --adbb14c54551470bb38d39fb6ffd7d28_visible: none;
    --f0d67211411e43e4b7a38529f05f01aa_visible: flex;
    --d4af2f1c8759416fbdc606064469bb21_visible: flex;
    --min-williambolivarsection2_valueheight: unset;
    --williambolivarsection2_valueheight: 100%;
  }

  .williambolivarsection2 {
    height: var(--williambolivarsection2_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Williambolivar {
    --adbb14c54551470bb38d39fb6ffd7d28_visible: flex;
    --f0d67211411e43e4b7a38529f05f01aa_visible: none;
    --d4af2f1c8759416fbdc606064469bb21_visible: none;
    --min-williambolivarsection2_valueheight: unset;
    --williambolivarsection2_valueheight: fit-content;
  }
}
