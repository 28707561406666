.Contacto {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.contactoheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.layer-490-6b3dbfb499bd47f8a59679df18d04bdd {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-271-2eb5ba27b5b949d180ab2f8a8931905f {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-491-a30e03c7fc224982b42317997406c92a {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--a30e03c7fc224982b42317997406c92a_visible);
}
.text-473-21d0a301c4f64562bc4391a191424903 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-474-99bb5c2144d944bdb8ae76b791d217a6 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-475-d52175800d624b0295c2081c47cf08f9 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-476-5f608a4db7e24a0cb3ab6488c07c6a61 {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-492-d2060ba68a624221adefed806eec35b9 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--d2060ba68a624221adefed806eec35b9_visible);
}
.text-477-810bcbf98f4b42b7972d2fa648264f89 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-493-fbde1345fcf246cbb9ee7416287319cf {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--fbde1345fcf246cbb9ee7416287319cf_visible);
}
.icon-29-8896af37a1974abdbd485cfd223b1fe9 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.contactosection1 {
  --verticalAxis-contactosection1: center;
  --horizontalAxis-contactosection1: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets/Contactanos.png");
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--contactosection1_orientation);
  min-height: var(--min-contactosection1_valueheight);
  height: var(--contactosection1_valueheight);
}
.layer-2816-800a009efabf411fa6d39c2418f0daa9 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-2189-8fff9490f6a148159eca3d56983a6d68 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--TittleTextSize1);
}
.contactosection2 {
  height: 90vh;
  min-height: 90vh;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  flex-direction: var(--contactosection2_orientation);
  --horizontalAxis-contactosection2: var(
    --contactosection2_horizontalAlignment
  );
  --verticalAxis-contactosection2: var(--contactosection2_verticalAlignment);
}
.layer-2467-d494000be63046a99715d0f87c6cc5c7 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--PrimaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
}
.contactofooter {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-574-6ff6732a49e04d69846ded0f69b05b0b {
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-453-cc430189b294462fa60cf28b3187edde {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-146-2e5bfdcff25a44f185532f2daab9f65f {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-147-3fd4bd9036f94b069b7d08ecb80f6e94 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-148-ab664a7a1f594b20a8f37d0fb3d21903 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-149-8bdfb38be237401ea2fea9c08cf52806 {
  cursor: pointer;
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);

  --margin-right: var(--8bdfb38be237401ea2fea9c08cf52806_margin-right);
  margin-right: var(--margin-right);
}
.layer-454-7c985bbeb2524bdebf0c9019c7d42858 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-412-800c11aa0eef4ed0a5318c5a6fd648e4 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-413-dbfb0bee793d46c9bb26572ccfe45487 {
  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

.contactosection1-orientation {
  align-items: var(--horizontalAxis-contactosection1);
  justify-content: var(--verticalAxis-contactosection1);
}
.contactosection2-orientation {
  align-items: var(--horizontalAxis-contactosection2);
  justify-content: var(--verticalAxis-contactosection2);
}
@media (max-width: 480px) {
  .Contacto {
    --fbde1345fcf246cbb9ee7416287319cf_visible: flex;
    --d2060ba68a624221adefed806eec35b9_visible: none;
    --a30e03c7fc224982b42317997406c92a_visible: none;
    --min-8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --contactosection2_orientation: column;
    --contactosection2_horizontalAlignment: center;
    --contactosection2_verticalAlignment: center;
    --contactosection1_orientation: column;
    --min-contactosection1_valueheight: 30vh;
    --contactosection1_valueheight: 30vh;
  }

  .contactosection1-orientation {
    align-items: var(--horizontalAxis-contactosection1);
    justify-content: var(--verticalAxis-contactosection1);
  }
  .contactosection2-orientation {
    align-items: var(--horizontalAxis-contactosection2);
    justify-content: var(--verticalAxis-contactosection2);
  }
}
@media (min-width: 1020px) {
  .Contacto {
    --fbde1345fcf246cbb9ee7416287319cf_visible: none;
    --d2060ba68a624221adefed806eec35b9_visible: flex;
    --a30e03c7fc224982b42317997406c92a_visible: flex;
    --min-8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --contactosection2_orientation: column;
    --contactosection2_horizontalAlignment: center;
    --contactosection2_verticalAlignment: center;
    --contactosection1_orientation: column;
    --min-contactosection1_valueheight: 15vh;
    --contactosection1_valueheight: 15vh;
  }

  .contactosection1-orientation {
    align-items: var(--horizontalAxis-contactosection1);
    justify-content: var(--verticalAxis-contactosection1);
  }
  .contactosection2-orientation {
    align-items: var(--horizontalAxis-contactosection2);
    justify-content: var(--verticalAxis-contactosection2);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Contacto {
    --fbde1345fcf246cbb9ee7416287319cf_visible: flex;
    --d2060ba68a624221adefed806eec35b9_visible: none;
    --a30e03c7fc224982b42317997406c92a_visible: none;
    --min-8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --8bdfb38be237401ea2fea9c08cf52806_margin-right: 0px;
    --contactosection2_orientation: column;
    --contactosection2_horizontalAlignment: center;
    --contactosection2_verticalAlignment: center;
    --contactosection1_orientation: row;
    --min-contactosection1_valueheight: 30vh;
    --contactosection1_valueheight: 30vh;
  }

  .contactosection1-orientation {
    align-items: var(--verticalAxis-contactosection1);
    justify-content: var(--horizontalAxis-contactosection1);
  }
  .contactosection2-orientation {
    align-items: var(--horizontalAxis-contactosection2);
    justify-content: var(--verticalAxis-contactosection2);
  }
}
