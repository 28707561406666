.Equipo {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.equipoheader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-bottom: var(--HeaderPaddingV);
  padding-top: var(--HeaderPaddingV);
}
.layer-130-e88bd466c4a64733aacf1b663338bf80 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-238-c5593d147b4e415096b98f570041c122 {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-131-df0931a01d5147da833f2f82d2e281a8 {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--df0931a01d5147da833f2f82d2e281a8_visible);
}
.text-124-7ff37d8633a141e4b59cbd2aa11b1360 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-125-2f9a85278af1463882fcf6efab937b4a {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-126-c87a96e5263e41ae8b5621bda905da34 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-127-ff5a492558244a8eae6bf5b22e9257b5 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-132-44dc677b353e4c91888a8f186ad3f6f6 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--44dc677b353e4c91888a8f186ad3f6f6_visible);
}
.text-128-6b4f740993e14878a0e7fe5376fa8e7a {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-133-697d9d9bb40b46ada5838c25256f3581 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--697d9d9bb40b46ada5838c25256f3581_visible);
}
.icon-6-9b006cc311a447189c89ff552bb8c285 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.equiposection1 {
  justify-content: center;
  align-items: center;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding: 2rem var(--ItemMargin1H);
  min-height: var(--min-equiposection1_valueheight);
  height: var(--equiposection1_valueheight);
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.layer-119-7bc330e88c2341b88b3352beac7356a2 {
  --verticalAxis-7bc330e88c2341b88b3352beac7356a2: center;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  display: flex;
  gap: 2rem;
  flex-direction: var(--7bc330e88c2341b88b3352beac7356a2_orientation);
  --horizontalAxis-7bc330e88c2341b88b3352beac7356a2: var(
    --7bc330e88c2341b88b3352beac7356a2_horizontalAlignment
  );
}
.layer-121-cd604044518a4a50814d4eab570e7199 {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.layer-613-97ddd4409ceb41e993a0811e30df1e67 {
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  margin-top: 6px;
  box-sizing: border-box;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-57-6f148ea86d024bf998c0f0b26a7322d7 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-108-7349b2207bd849558a7815d17bb9a99c {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.text-109-4d9491eb9323491e802e42f0d8776423 {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.imageview-183-5f50fe5a5a544ec09dcb406ba063ee01 {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.layer-122-c86f5fa73da74a9c9d3a1f2079a97a94 {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.layer-614-bd467e738bb74b579d658132a2800269 {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-59-bdfedbb077ef48ab8e556bc6ea004fcb {
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
}
.text-110-29d426a2a21a462d845c9c9388f0e96b {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.text-111-5e7c0ea1371d438199ec3fc9ce10477c {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.imageview-60-205d069735ac44cf8853a6d3ae8e5b44 {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.layer-124-dfa630de779540679c45118eb31e1a07 {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.layer-615-afa9e4ba2403402a82f4343e7cfb2823 {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-63-2e0611094b95479ab118519f321a4cb0 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-114-60a5249ad3ea4d4798f1d4d62a5ab129 {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.text-115-10a29eb9410d48f0952633e3651cdf92 {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.imageview-64-201a5b2a8b9c4a3394adbe33a85bf7b6 {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.layer-123-f090316cf9a44f4ca5e5149f0da7c535 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-top: var(--ItemMargin1V);
  margin-bottom: var(--ItemMargin2V);
}
.layer-620-5107e29a44d1471397a8fba6c77ed97b {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-61-f5061a2725034ac1a6fa0e8c361d4ee3 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-112-1864002942f140869fbbd517659e6f97 {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.text-113-14040472f0204dca9d1e65bd0fa02132 {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.imageview-62-7b705905361947d6822d1d1d834e498a {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.layer-120-686902dff360450ea740c2e4277b068b {
  --verticalAxis-686902dff360450ea740c2e4277b068b: center;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  display: flex;
  gap: 2rem;
  flex-direction: var(--686902dff360450ea740c2e4277b068b_orientation);
  --horizontalAxis-686902dff360450ea740c2e4277b068b: var(
    --686902dff360450ea740c2e4277b068b_horizontalAlignment
  );
  padding: 0;
}
.layer-125-d31b0b64c59f4e3fbed5c1d60797cbf7 {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.layer-617-390b98faf31f41fdaffddc26b3416c44 {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-65-504f17405fd4460ca6ddd02269cf7149 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-116-f1b00d4a725a4bd0854526d804f44a28 {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.text-117-2704d262e1f34476abbccf22ebca6910 {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.imageview-66-64bc6664425c4f36a1ab77cf5a5c3f4a {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.layer-126-b949a8af2d26485b83198f98045011a0 {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.layer-618-f7332bd51d514f288ac797124953192c {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-67-20f417fc569d4aa297bf8ea9eb1b54ad {
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
}
.text-118-25a55263ea1b441ea5e3687a3ee0e745 {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.text-119-ef5e4b1f28ac4b438e79df59c2877ad5 {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.imageview-68-959e427782be489e8e46a9843581e00e {
  cursor: pointer;
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
}
.layer-127-9da32d77ccef448bbe96945f508b799d {
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin: var(--ItemMargin2V) 0;
}
.layer-619-295c4dbf786b4c589e3759d1344cac70 {
  border-radius: 15px;
  box-shadow: 0px 1px 10px var(--TertiaryColor);
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--TertiaryColor);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-69-68fff1e56cb04bbf88dfe9f3cb642561 {
  min-width: var(--min-ImageSize3);
  width: var(--ImageSize3);
  min-height: var(--min-ImageSize3);
  height: var(--ImageSize3);
}
.text-120-5f047a0f154e453992fe275d0c8bf839 {
  text-align: center;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--TittleTextSize4);
}
.text-121-d20b53dc6b2a4722bdf6b7440a2a1eba {
  text-align: center;
  margin-bottom: 1vh;
  box-sizing: border-box;
  width: 225px;
  min-width: 225px;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize3);
}
.imageview-70-a6a7d6b4f6984b91a51d8978cd6cfa02 {
  cursor: pointer;
  min-width: var(--min-SMediaSize2);
  width: var(--SMediaSize2);
  min-height: var(--min-SMediaSize2);
  height: var(--SMediaSize2);
}
.equipofooter {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-500-8f3575fe37ad486486476de31da2fb0f {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-128-c6697fd6d2c44327a96e8bb3a45e11a9 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-71-21ae404e7dc9498193fe1a6eca7b3fda {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-72-f81a1dc4ce5f43d3bcd7038fb8b62db4 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-73-d6b829ffb04b4f528e8dec9626df5839 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-74-a42e629dbc7e4c7daa62f1af292425c1 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-129-57922db7ecfe4b1994e8884c0f9297cf {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-122-6fee9b6acd1d4a3a9a4c62b941344745 {
  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-123-a086dfb462094a5499f8e5913f53817c {
  font-family: var(--FontRegular);
  font-size: var(--FooterTextSize);
}

.layer-119-7bc330e88c2341b88b3352beac7356a2-orientation {
  align-items: var(--verticalAxis-7bc330e88c2341b88b3352beac7356a2);
  justify-content: var(--horizontalAxis-7bc330e88c2341b88b3352beac7356a2);
}
.layer-120-686902dff360450ea740c2e4277b068b-orientation {
  align-items: var(--verticalAxis-686902dff360450ea740c2e4277b068b);
  justify-content: var(--horizontalAxis-686902dff360450ea740c2e4277b068b);
}
@media (max-width: 480px) {
  .Equipo {
    --7bc330e88c2341b88b3352beac7356a2_orientation: column;
    --686902dff360450ea740c2e4277b068b_orientation: column;
    --697d9d9bb40b46ada5838c25256f3581_visible: flex;
    --44dc677b353e4c91888a8f186ad3f6f6_visible: none;
    --df0931a01d5147da833f2f82d2e281a8_visible: none;
    --min-equiposection1_valueheight: unset;
    --equiposection1_valueheight: fit-content;
    --7bc330e88c2341b88b3352beac7356a2_horizontalAlignment: center;
    --686902dff360450ea740c2e4277b068b_horizontalAlignment: center;
  }
  
  .layer-119-7bc330e88c2341b88b3352beac7356a2,
  .layer-120-686902dff360450ea740c2e4277b068b {
    gap: 3rem;
  }
}
@media (min-width: 1020px) {
  .Equipo {
    --7bc330e88c2341b88b3352beac7356a2_orientation: row;
    --686902dff360450ea740c2e4277b068b_orientation: row;
    --697d9d9bb40b46ada5838c25256f3581_visible: none;
    --44dc677b353e4c91888a8f186ad3f6f6_visible: flex;
    --df0931a01d5147da833f2f82d2e281a8_visible: flex;
    --min-equiposection1_valueheight: 90vh;
    --equiposection1_valueheight: 90vh;
    --7bc330e88c2341b88b3352beac7356a2_horizontalAlignment: center;
    --686902dff360450ea740c2e4277b068b_horizontalAlignment: center;
  }

  .layer-119-7bc330e88c2341b88b3352beac7356a2,
  .layer-120-686902dff360450ea740c2e4277b068b {
    gap: 4rem;
    justify-content: center;
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Equipo {
    --7bc330e88c2341b88b3352beac7356a2_orientation: column;
    --686902dff360450ea740c2e4277b068b_orientation: column;
    --697d9d9bb40b46ada5838c25256f3581_visible: flex;
    --44dc677b353e4c91888a8f186ad3f6f6_visible: none;
    --df0931a01d5147da833f2f82d2e281a8_visible: none;
    --min-equiposection1_valueheight: unset;
    --equiposection1_valueheight: fit-content;
    --7bc330e88c2341b88b3352beac7356a2_horizontalAlignment: center;
    --686902dff360450ea740c2e4277b068b_horizontalAlignment: center;
  }

  .layer-119-7bc330e88c2341b88b3352beac7356a2,
  .layer-120-686902dff360450ea740c2e4277b068b {
    gap: 3.5rem;
  }
}
