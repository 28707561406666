.Hernanpanesso {
  background-color: #ffffff;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.layer-256-69160b77ff7c4afcb6edc914850bd85d {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  background: var(--MarcaTertiaryColor);
  min-height: var(--min-HeaderSizeV);
  height: var(--HeaderSizeV);
  padding-left: var(--HeaderPaddingH);
  padding-right: var(--HeaderPaddingH);
  padding-top: var(--HeaderPaddingV);
  padding-bottom: var(--HeaderPaddingV);
}
.layer-312-cc1e1afe86624fbc98998cefd0faf46e {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.imageview-432-708956f1b6ac4a87b769da12ab4d3b3b {
  min-height: unset;
  height: 100%;
  min-width: var(--min-HeaderLogoSize);
  width: var(--HeaderLogoSize);
}
.layer-313-4bc0bf400a204438bbde93b73cdecc9d {
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: unset;
  height: 100%;

  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--4bc0bf400a204438bbde93b73cdecc9d_visible);
}
.text-379-5d09288329174bac929613d6c9259fc8 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-380-e32bf2f7a69a45b3870b8f1336c30f9f {
  font-family: var(--FontBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-381-7923e1a88d514a67b287806e6adcad14 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
  margin-right: var(--HeaderTextMarginH);
}
.text-382-d568ae08286a4678a19cb8f87f892642 {
  font-family: var(--FontBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-314-220552110e93443e826ee398a92e6766 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ButtonPaddingV);
  padding-bottom: var(--ButtonPaddingV);
  padding-left: var(--ButtonPaddingH);
  padding-right: var(--ButtonPaddingH);
  border-radius: var(--ButtonRadius);
  display: var(--220552110e93443e826ee398a92e6766_visible);
}
.text-383-b6820d9d8552467fa64ed36977c97ef4 {
  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--HeaderTextSize);
}
.layer-315-66754efb781c466db105083f1545ef88 {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  display: var(--66754efb781c466db105083f1545ef88_visible);
}
.icon-19-9dbef0ae1b7940dcbacaa30bb65ed0d0 {
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 24px;
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
}
.layer-257-f763afc56f2c4d81a6de4406a12a3d8f {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaSecondaryColor);
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
}
.layer-260-033360c0d0b744fe95e579f45c2bea0c {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-317-caab790961844a7cb3a8a2aea8fdedf5 {
  text-align: center;

  font-family: var(--FontExtraBold);
  background: var(--MarcaPrimaryColor);
  background: var(--MarcaPrimaryColor);
  font-size: var(--TittleTextSize3);
  margin-bottom: var(--ItemMargin2V);
}
.layer-705-9ce38905915947fc9183a2d3e0e7706a {
  border-radius: 15px;
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin2V);
}
.imageview-285-af2c2e3217ca47cd80fbecc12e341b7f {
  min-width: var(--min-ImageSize1);
  width: var(--ImageSize1);
  min-height: var(--min-ImageSize1);
  height: var(--ImageSize1);
}
.text-318-1d8b054d66ef40d58987d1f273966ed0 {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize1);
}
.text-319-51f8df66e9224acc855ced6f925a4c2e {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--MarcaTertiaryColor);
  background: var(--MarcaTertiaryColor);
  font-size: var(--ParagraphTextSize2);
  margin-bottom: var(--ItemMargin3V);
}
.imageview-142-1b114baef3e04ccdbab392e60bd0e82f {
  cursor: pointer;
  min-width: var(--min-SMediaSize1);
  width: var(--SMediaSize1);
  min-height: var(--min-SMediaSize1);
  height: var(--SMediaSize1);
}
.layer-258-07d7191375b6450c9b131e38a9604078 {
  justify-content: center;
  align-items: center;

  min-width: unset;
  width: 100%;
  flex-shrink: 1;
  padding-top: var(--ItemMargin1V);
  padding-bottom: var(--ItemMargin1V);
  padding-left: var(--ItemMargin1H);
  padding-right: var(--ItemMargin1H);
  min-height: var(--min-07d7191375b6450c9b131e38a9604078_valueheight);
  height: var(--07d7191375b6450c9b131e38a9604078_valueheight);
}
.layer-261-2965aebb37bd419b8165cccfc0d40abd {
  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: 100%;
  flex-shrink: 1;
}
.text-320-2d4c4356a06546d1baca97e83859c492 {
  min-width: unset;
  width: 100%;
  font-family: var(--FontExtraBold);
  background: var(--MarcaSecondaryColor);
  background: var(--MarcaSecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin2V);
}
.text-321-5d1dd786bead4d9ba58bb74dbe140e98 {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.text-322-4483735879fe455e98af2d361eecde1f {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
  margin-bottom: var(--ItemMargin3V);
}
.text-323-5e807978bf504a6e8e898821369b79fe {
  min-width: unset;
  width: 100%;
  font-family: var(--FontMedium);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--ParagraphTextSize1);
}
.layer-987-01e2a6dd33bb4d2c90c7e46bd5e126f3 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  flex-shrink: 0;

  min-width: unset;
  width: 100%;
  background: var(--MarcaTertiaryColor);
  padding-top: var(--ItemMargin2V);
  padding-bottom: var(--ItemMargin2V);
}
.layer-1235-342a37d10bbd4f498c7809595fd81863 {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--FooterPaddingMobileV);
}
.layer-1236-5254a1e8601f4f77a1ca6dbb908bae35 {
  flex-direction: row;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
  margin-bottom: var(--ItemMargin3V);
}
.imageview-406-59c1d92a66b84a88a1b6adb176721a18 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-407-3919c7f0548e43d895085982a4cfbbd4 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-408-729d550c0bd047f9ab196e58d8fa95f2 {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);

  --margin-right: var(--FooterItemMarginH);
  margin-right: var(--margin-right);
}
.imageview-409-ddb97bd61a374f9ab22d07dc118d92cb {
  cursor: pointer;
  min-width: var(--min-FooterSMediaSize);
  width: var(--FooterSMediaSize);
  min-height: var(--min-FooterSMediaSize);
  height: var(--FooterSMediaSize);
}
.layer-311-33c6fc8e43074b5d8addc1d24fbd5a4d {
  justify-content: center;
  align-items: center;

  min-height: unset;
  height: fit-content;
  min-width: unset;
  width: fit-content;
  flex-shrink: 1;
}
.text-426-37124303ac624203b1164b37d76a00ab {
  text-align: center;

  font-family: var(--FontBold);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}
.text-427-b66ee6c3531845199d60796cb854a24b {
  text-align: center;

  font-family: var(--FontRegular);
  background: var(--SecondaryColor);
  background: var(--SecondaryColor);
  font-size: var(--FooterTextSize);
}

@media (max-width: 480px) {
  .Hernanpanesso {
    --66754efb781c466db105083f1545ef88_visible: flex;
    --220552110e93443e826ee398a92e6766_visible: none;
    --4bc0bf400a204438bbde93b73cdecc9d_visible: none;
    --min-07d7191375b6450c9b131e38a9604078_valueheight: unset;
    --07d7191375b6450c9b131e38a9604078_valueheight: fit-content;
  }
}
@media (min-width: 1020px) {
  .Hernanpanesso {
    --66754efb781c466db105083f1545ef88_visible: none;
    --220552110e93443e826ee398a92e6766_visible: flex;
    --4bc0bf400a204438bbde93b73cdecc9d_visible: flex;
    --min-07d7191375b6450c9b131e38a9604078_valueheight: unset;
    --07d7191375b6450c9b131e38a9604078_valueheight: 100%;
  }

  .layer-258-07d7191375b6450c9b131e38a9604078 {
    height: var(--07d7191375b6450c9b131e38a9604078_valueheight);
  }
}
@media (max-width: 1019px) and (min-width: 481px) {
  .Hernanpanesso {
    --66754efb781c466db105083f1545ef88_visible: flex;
    --220552110e93443e826ee398a92e6766_visible: none;
    --4bc0bf400a204438bbde93b73cdecc9d_visible: none;
    --min-07d7191375b6450c9b131e38a9604078_valueheight: unset;
    --07d7191375b6450c9b131e38a9604078_valueheight: fit-content;
  }
}
